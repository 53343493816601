import React, { useEffect, useRef, useState } from "react";

import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../Components/Loader";
import { editProfile, getProfile } from "redux/Events/actions";

function Profile() {
  const route = process.env.REACT_APP_IMAGE_URI;
  const dispatch = useDispatch();
  const { loader, profile } = useSelector((state) => state.events);

  const [Profile, setProfile] = useState({
    name: "",
    email: "",
    mobileno: "",
    avatar: "",
    password: "",
  });

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    if (profile) {
      setProfile({
        ...Profile,
        name: profile.name,
        email: profile.email,
        mobileno: profile.mobileno,
        id: profile.id,
      });
    }
  }, [profile]);
  console.log(profile);
  const updateProfile = () => {
    let form = new FormData();
    for (var key in Profile) {
      if (Profile[key] == null || Profile[key] == " " || !Profile[key]) {
      } else {
        form.append(key, Profile[key]);
      }
    }
    dispatch(editProfile(form));
  };
  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"Profile"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader title={"Profile"}></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>
                <div
                  style={{
                    margin: "0",
                  }}
                >
                  <div className="avatar"></div>
                  <div
                    className="row"
                    style={{
                      margin: "1rem 0",
                    }}
                  >
                    <div className="col-6">
                      {" "}
                      <input
                        // style={
                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                        // }
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="name"
                        placeholder="First Name"
                        value={Profile?.name}
                        onChange={(e) =>
                          setProfile({
                            ...Profile,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-6">
                      <input
                        // style={
                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                        // }
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder="Email"
                        value={Profile?.email}
                        onChange={(e) =>
                          setProfile({
                            ...Profile,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      margin: "1rem 0",
                    }}
                  >
                    <div className="col-6">
                      <input
                        // style={
                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                        // }
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="mobileno"
                        placeholder="Mobile"
                        value={Profile?.mobileno}
                        onChange={(e) =>
                          setProfile({
                            ...Profile,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-6">
                      <input
                        // style={
                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                        // }
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="password"
                        placeholder="Password"
                        value={Profile?.password}
                        onChange={(e) =>
                          setProfile({
                            ...Profile,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      margin: "1rem",
                    }}
                  >
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={updateProfile}
                    >
                      <span>Save Changes</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
