import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import TextField from "@mui/material/TextField";
import Loader from "../../Components/Loader";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateTicketType,
  DeleteTicketType,
  EditTicketType,
  getTicketTypes,
  getTicketTypesConst,
} from "redux/TicketTypes/actions";
import {
  CreateCode,
  DeleteCode,
  EditCode,
  getCodes,
} from "redux/Codes/actions";
import { useParams } from "react-router";
import { CreateBoothsTeam, DeletedBoothsTeam, EditBoothsTeam, getBoothsTeam } from "redux/Booths/actions";



const initalTeam = {
    avatar:'',
    name:'',
    chaturl:''
  }
function SponsorTeam() {
  const dispatch = useDispatch();
  const { booths, loader, total, number_of_pages,team } = useSelector(
    (state) => state.booths
  );
  
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newSponsor, setNewSponsor] = useState(initalTeam);
const route = process.env.REACT_APP_IMAGE_URI;

  const params = useParams();
  
  useEffect(() => {
    if (params.uuid) {
    dispatch(getBoothsTeam({id:params.uuid,params:''}))
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if(newSponsor.avatar==''|| newSponsor.chaturl=='' || newSponsor.name=='')
    {
      setError(true)
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newSponsor };
      form.id = parseInt(updateFlag);
      form.booth_id = parseInt(params.uuid);
      const body = new FormData();
      if(typeof form.avatar=='string')
      {
        delete form.avatar
      }
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(EditBoothsTeam({ body, selectedEvent:params.uuid }));
      setEventModal(false);
    } else {
      let form = { ...newSponsor };
      form.booth_id = parseInt(params.uuid);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreateBoothsTeam({ body, selectedEvent:params.uuid }));
      setEventModal(false);
    }
    setNewSponsor(initalTeam);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  console.log(team, "newticket");
  return (
    <>
     <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewSponsor(initalTeam);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
         
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Teams
            </h1>

          
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
            <label
                  style={{ color: "black" }}
                  className="d-flex align-items-center fs-7 mb-2"
                >
                  Avatar
                </label>
                <div>
                {newSponsor.avatar ? (
                  <>
                    <img
                      style={{ maxWidth: "35%", height: "7rem" }}
                      src={
                        newSponsor.avatar
                          ? newSponsor.avatar?.name
                            ? URL.createObjectURL(newSponsor.avatar)
                            : `${route}${newSponsor.avatar}`
                          : ""
                      }
                    ></img>
                    <div className="d-flex" style={{ margin: "1rem 0" }}>
                      <label className="circles">
                        <img src={edit} style={{ width: "1rem" }}></img>
                        <input
                          name="logo"
                          value={""}
                          onChange={(e) =>
                            setNewSponsor({
                              ...newSponsor,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          style={{ display: "none", cursor: "pointer" }}
                          accept=".png, .jpg, .jpeg, .svg"
                        />
                      </label>
                      <div className="separtor"></div>
                      <div className="circles">
                        <img
                          src={del}
                          style={{ width: "0.8rem", cursor: "pointer" }}
                          onClick={() => {
                            setNewSponsor({ ...newSponsor, avatar: null });
                          }}
                        ></img>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="upload-btn">
                    <input
                      style={
                        error && !newSponsor.avatar
                          ? { border: "1px solid red" }
                          : {}
                      }
                      type="file"
                      style={{ display: "none" }}
                      name="avatar"
                      value={""}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(e) => {
                        setNewSponsor((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.files[0],
                        }));
                      }}
                    />
                    Upload avatar
                  </label>
                )}
              </div>
            </div>
            <div className="row">
                <label>
                  Name
                </label>
                <input
                  style={
                    error && !newSponsor.name ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="name"
                  placeholder=""
                  value={newSponsor?.name}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
            </div>
            <div className="row">
                <label>
                  Chat URL
                </label>
                <input
                  style={
                    error && !newSponsor.chaturl ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="chaturl"
                  placeholder=""
                  value={newSponsor?.chaturl}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
        </div>
      </Modal>
     

      <div className="aside-div">
        <AsideMenu selected={"Sponsors"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Sponsor Team"}
            // setSearchText={setSearchText}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "10%", color: "white" }}
                        >
                          #
                        </th>
                        
                        <th style={{ width: "10%", color: "white" }}>
                          Name
                        </th>
                        <th style={{ width: "10%", color: "white" }}>
                          Chat Url
                        </th>
                        
                        <th
                          style={{ width: "5%", color: "white" }}
                          className="tlast"
                        >
                          Actions
                        </th>
                      </tr>

                      {team?.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th style={{ color: "black" }}>{code.id}</th>
                            <th style={{ color: "black" }}>{code.name}</th>
                            
                            <th style={{ color: "black" }}>
                              {code.chaturl}
                            </th>
                            <th>
                              <span
                                onClick={() => {
                                  setUpdateFlag(code.id);
                                  setNewSponsor({
                                    name: code.name,
                                    avatar:code.avatar,
                                    chaturl:code.chaturl
                                  });
                                  setEventModal(true);
                                }}
                              >
                                <img
                                  src={edit}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                  }}
                                ></img>
                              </span>
                              <span
                               onClick={() => {
                                dispatch(
                                DeletedBoothsTeam({
                                    id: code.id,
                                    selectedEvent:params.uuid,
                                  })
                                );
                             }}
                              >
                                <img
                                  src={del}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Member</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SponsorTeam;
