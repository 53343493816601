import { createReducer } from "@reduxjs/toolkit";
import {
  getEventsDetails,
  getEventsAgenda,
  getAllEvent,
  createEvent,
  getCompanies,
  getSponsors,
  getPartners,
  getSpeakers,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  speakers: [],
  loader: false,
  total: 0,
};

export const speakerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSpeakers.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getSpeakers.fulfilled, (state, { payload }) => {
      state.speakers = payload.list;
      state.loader = false;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getSpeakers.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default speakerReducer;
