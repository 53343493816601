import * as Yup from "yup";

const includeDigRegExp = /([0-9]+)/;
const includeCharRegExp = /([A-z]+)/;

export default Yup.object().shape({
  password: Yup.string()
    .required("Password is Required")
    .min(6, "password must be at least 6 characters"),
  confirm_password: Yup.string()
    .required("Confirm password is Required")
    .oneOf([Yup.ref("password"), null], "The passwords do not match."),
});
