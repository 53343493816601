import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import ApexCharts from "apexcharts";
import { getTypesReport } from "redux/Reports/actions";
const route = process.env.REACT_APP_IMAGE_URI;

function Types() {
  const dispatch = useDispatch();
  const { loader, types } = useSelector((state) => state.reports);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { selectedEvent } = useSelector((state) => state.events);
  useEffect(() => {
    if (selectedEvent) {
      dispatch(getTypesReport({ id: selectedEvent }));
    }
  }, []);
  console.log(loader, "attendence");
  const getSeriesData = (arr, type) => {
    let values = [];
    if (type == "name") {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].name);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].value);
      }
    }

    return values;
  };

  useEffect(() => {
    if (types) {
      var options = {
        chart: {
          type: "donut",
        },
        series: getSeriesData(types, "value"),
        labels: getSeriesData(types, "name"),
      };
    }

    var chart = new ApexCharts(document.querySelector("#chart"), options);

    if (loader == false) {
      chart.render();
    }
    //}
  }, [loader]);

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"ReportTypes"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Ticket Types Report"}
            showUrl={true}
            // setSearchText={setSearchText}
            EventName={selectedEvent}
          ></PageHeader>
          <div className="event-page" style={{ marginInlineEnd: "0" }}>
            {!loader ? (
              <>
                <div style={{ width: "50%", margin: "5rem auto" }}>
                  <div id="chart"></div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Types;
