import { createReducer } from "@reduxjs/toolkit";
import { getBooths, getBoothsTeam } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  booths: [],
  team:[],
  loader: false,
  total: 0,
  number_of_pages: 0,
};

export const boothReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBooths.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getBooths.fulfilled, (state, { payload }) => {
      state.booths = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getBoothsTeam.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getBoothsTeam.fulfilled, (state, { payload }) => {
      state.team = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getBoothsTeam.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getBooths.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default boothReducer;
