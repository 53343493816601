import { createReducer } from "@reduxjs/toolkit";
import {
  getEventsDetails,
  getEventsAgenda,
  getAllEvent,
  createEvent,
  getCompanies,
  getSponsors,
  getTicketTypes,
  getTicketTypesConst,
  getTicketType,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  ticketTypes: [],
  ticketType: {},
  ticketTypesConst: [],
  loader: false,
  total: 0,
};

export const ticketTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTicketTypes.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getTicketTypes.fulfilled, (state, { payload }) => {
      state.ticketTypes = payload.list;
      state.loader = false;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getTicketTypes.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getTicketType.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getTicketType.fulfilled, (state, { payload }) => {
      state.ticketType = payload;
      state.loader = false;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getTicketType.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getTicketTypesConst.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getTicketTypesConst.fulfilled, (state, { payload }) => {
      state.ticketTypesConst = payload.list;
      state.loader = false;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getTicketTypesConst.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default ticketTypeReducer;
