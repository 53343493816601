import React, { useEffect, useState } from "react";
import AsideMenu from "../../../Components/Asidemenu2";
import PageHeader from "../../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/delete.svg";
import Loader from "../../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from "redux/Companies/actions";
import {
  getAdmins,
  CreateAdmin,
  EditAdmin,
  getBoothsAdmins,
  EditBoothAdmin,
  CreateBoothAdmin,
  deleteBoothsAdmins,
  getBoothsSales,
  EditBoothSales,
  CreateBoothSales,
  deleteBoothsSales,
  EditTimeline,
  DeleteTimeline,
} from "redux/UserRoles/actions";
import Pagintation from "../../../Components/Pagintation";
import { useParams } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

const initalAdmin = {
  name: "",
  email: "",
  password: "",
  mobileno: "",
  timelines: [],
  type: 102,
};

function BoothSalesTeam() {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);
  const { boothssales, loader, total } = useSelector(
    (state) => state.userRoles
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newAdmin, setNewAdmin] = useState(initalAdmin);
  const [slotLength, setSlotLength] = useState(-1);
  const [slotEdit, setSlotEdit] = useState({ index: -1, id: -1 });
  const params = useParams();

  useEffect(() => {
    if (params.uuid) {
      dispatch(getBoothsSales({ id: params.uuid }));
    }
  }, [params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newAdmin.name == "" ||
      newAdmin.email == "" ||
      newAdmin.password == "" ||
      newAdmin.mobileno == "" ||
      newAdmin.type == null
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newAdmin };

      if (!form.password) {
        delete form.password;
      }
      form.timelines.splice(0, slotLength);
      dispatch(
        EditBoothSales({
          body: form,
          id: updateFlag,
          selectedEvent: params.uuid,
        })
      );
      setEventModal(false);
    } else {
      let form = { ...newAdmin };
      form.booth_id = params.uuid;

      dispatch(CreateBoothSales({ body: form, selectedEvent: params.uuid }));
      setEventModal(false);
    }
    setNewAdmin(initalAdmin);
    setSlotEdit({ index: -1, id: -1 });
    setSlotLength(-1);
    setError(false);
    setUpdateFlag(-1);
  };
  console.log(newAdmin);
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewAdmin(initalAdmin);
          setError(false);
          setUpdateFlag(-1);
          setSlotEdit({ index: -1, id: -1 });
          setSlotLength(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create Admin
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Admin
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Name
                  </span>
                  <input
                    style={
                      error && !newAdmin.name ? { border: "1px solid red" } : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    placeholder=""
                    value={newAdmin?.name}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Password
                  </span>
                  <input
                    style={
                      error && !newAdmin.password
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="password"
                    placeholder=""
                    value={newAdmin?.password}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Email
                  </span>
                  <input
                    style={
                      error && !newAdmin.email
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="email"
                    placeholder=""
                    value={newAdmin?.email}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Mobile Number
                  </span>
                  <input
                    style={
                      error && !newAdmin.mobileno
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="mobileno"
                    placeholder=""
                    value={newAdmin?.mobileno}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {newAdmin?.timelines && (
                <>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <h2>Time Lines</h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="16"
                      viewBox="0 0 512 512"
                      onClick={() => {
                        let time = [...newAdmin.timelines];
                        time.push({ timeline: "" });
                        setNewAdmin({ ...newAdmin, timelines: time });
                      }}
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                    </svg>
                  </div>
                </>
              )}
              {newAdmin?.timelines?.map((time, index) => (
                <div className="row" key={index} style={{ display: "flex" }}>
                  <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <label
                        style={{ color: "black" }}
                        className="d-flex align-items-center fs-7 mb-2"
                      >
                        <span>Time Line</span>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TextField
                        id="date"
                        type="datetime-local"
                        sx={{
                          width: "100%",
                          backgroundColor: "#eaf4fa",
                          borderRadius: "16px",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="Sloat"
                        value={time?.timeline}
                        disabled={
                          slotEdit.index == index || index + 1 > slotLength
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          let date = new Date(e.target.value);
                          let isCurrentYear = (date) =>
                            date.getUTCFullYear() < new Date().getUTCFullYear();
                          if (isCurrentYear(date)) {
                            toast.error("Please pick a date from this year");
                          } else {
                            let t = [...newAdmin.timelines];

                            t[index] = { timeline: e.target.value };

                            setNewAdmin({ ...newAdmin, timelines: t });
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {index == slotEdit.index && (
                    <div
                      className="col-6 fv-row mb-5 fv-plugins-icon-container"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        let body = {
                          timeline: time.timeline,
                          user_id: updateFlag,
                        };
                        console.log(body, time);
                        dispatch(
                          EditTimeline({
                            id: slotEdit.id,
                            body,
                            selectedEvent: params.uuid,
                          })
                        );
                        setSlotEdit({ index: -1, id: -1 });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="14"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </div>
                  )}
                  {updateFlag != -1 &&
                    index < slotLength &&
                    index != slotEdit.index && (
                      <div
                        className="col-6 fv-row mb-5 fv-plugins-icon-container"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="16"
                          viewBox="0 0 512 512"
                          style={{ margin: "0 0.5rem" }}
                          onClick={() =>
                            setSlotEdit({
                              ...slotEdit,
                              index: index,
                              id: time.id,
                            })
                          }
                        >
                          <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="14"
                          viewBox="0 0 448 512"
                          onClick={() => {
                            dispatch(
                              DeleteTimeline({
                                id: time.id,
                                selectedEvent: params.uuid,
                              })
                            );
                            setEventModal(false);
                            setUpdateFlag(-1);
                            setSlotEdit({ id: -1, index: -1 });
                            setSlotLength(-1);
                          }}
                        >
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                      </div>
                    )}
                </div>
              ))}
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Bs"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Booths Sales"}
            setSearchText={setSearchText}
            EventName={""}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6  border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "25%", color: "white" }}
                        >
                          #
                        </th>
                        <th style={{ width: "25%", color: "white" }}>Name</th>
                        <th style={{ width: "25%", color: "white" }}>Email</th>
                        <th style={{ width: "25%", color: "white" }}>
                          Mobile Number
                        </th>
                        <th
                          className="tlast"
                          style={{ width: "25%", color: "white" }}
                        >
                          Actions
                        </th>
                      </tr>

                      {boothssales?.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th style={{ color: "black" }}>{code.id}</th>
                            <th style={{ color: "black" }}>{code.name}</th>
                            <th style={{ color: "black" }}>{code.email}</th>
                            <th style={{ color: "black" }}>{code.mobileno}</th>

                            <th style={{ display: "flex" }}>
                              <span
                                onClick={() => {
                                  setUpdateFlag(code.id);

                                  setNewAdmin({
                                    name: code.name,
                                    email: code.email,
                                    mobileno: code.mobileno,
                                    password: code.password,
                                    type: code.type,
                                    timelines: code.timelines,
                                  });
                                  setSlotLength(code.timelines?.length);
                                  setEventModal(true);
                                }}
                              >
                                <img
                                  src={edit}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                  }}
                                ></img>
                              </span>

                              <span
                                onClick={() => {
                                  dispatch(
                                    deleteBoothsSales({
                                      id: code.id,
                                      selectedEvent: params.id,
                                    })
                                  );
                                }}
                              >
                                <img
                                  src={del}
                                  style={{ cursor: "pointer" }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Sale</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BoothSalesTeam;
