import React from "react";
import { Title } from "./styles";
import { ErrorMessage, Formik, Form, Field } from "formik";
import Schema from "./Schema";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { doForgetPassword } from "redux/Auth/thunks";
import { useLoadingSelector } from "redux/selectors";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";

const ForgetPassword = () => {
  const dispatch = useAppDispatch();
  const isLoading = useLoadingSelector(doForgetPassword);
  const nav = useNavigate();
  return (
    <div className="login-background">
      <div className="login">
        <div
          className="card shadow align-items-center mb-10 py-30"
          style={{ padding: "2.5rem" }}
        >
          <Title>Forgot Password?</Title>
          <Formik
            onSubmit={(values) => {
              dispatch(doForgetPassword(values))
                .then(unwrapResult)
                .then((res) => {
                  if (!res) {
                    toast.error("The EMAIL you entered is not exist");
                    return;
                  }
                  nav("/check-otp", { state: { email: values.email } });
                });
            }}
            initialValues={{ email: "" }}
            validationSchema={Schema}
          >
            {(props) => (
              <Form
                style={{
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    className="d-block input-label"
                    style={{ marginBottom: "5px" }}
                  >
                    Email Address
                  </label>
                  <Field {...props} className="user-inputs" name="email" />
                  <div
                    style={{
                      color: "#FF0000",
                      fontSize: 13,
                      marginLeft: "5px",
                    }}
                  >
                    <ErrorMessage name="email" />
                  </div>
                  <div
                    style={{
                      marginTop: "42px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <button className="login-btn" type="submit">
                      {!isLoading ? (
                        "PROCEED"
                      ) : (
                        <CircularProgress size={20} color="inherit" />
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
