import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getCompanies = createAsyncThunk(
  "get/companies",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "company/list",
        params: params,
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getCompaniesSmtp = createAsyncThunk(
  "get/companiessmtp",
  async (id) => {
    try {
      const response = await axios({
        method: "get",
        url: "company/" + id + "/smtp-configs",
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateCompanysmtp = createAsyncThunk(
  "create/companysmtp",
  async ({ body }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "company/smtp-configs",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Company smtp Created successfully");
        dispatch(getCompaniesSmtp(body.company_id));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditCompanysmtp = createAsyncThunk(
  "create/companysmtp",
  async ({ body, id }, { dispatch }) => {
    try {
      const response = await axios({
        method: "put",
        url: "company/smtp-configs/" + id + "",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Company smtp Created successfully");
        dispatch(getCompaniesSmtp(body.company_id));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateCompany = createAsyncThunk(
  "create/company",
  async ({ body }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "company/create",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Company Created successfully");
        dispatch(getCompanies({ name: "" }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditCompany = createAsyncThunk(
  "edit/company",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "company/update",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Company Edited successfully");
        dispatch(getCompanies({ name: "" }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteCompany = createAsyncThunk(
  "delete/company",
  async ({ updateFlag }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/company/${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Company Deleted successfully");
        dispatch(getCompanies({ name: "" }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
