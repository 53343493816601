import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getSpeakers = createAsyncThunk("get/speaker", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "event/speakers/list",
      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateSpeakers = createAsyncThunk(
  "create/speaker",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/speakers/create",
        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Speaker Created successfully");
        dispatch(getSpeakers({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditSpeaker = createAsyncThunk(
  "edit/speaker",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/speakers/update",
        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Speaker Edited successfully");
        dispatch(getSpeakers({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteSpeaker = createAsyncThunk(
  "delete/speaker",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/speakers/delete?id=${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Speaker Deleted successfully");
        dispatch(getSpeakers({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
