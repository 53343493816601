import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
const route1 = process.env.REACT_APP_API2;

export const getSessions = createAsyncThunk("get/sessions", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "event/sessions/list",
      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getSessionsConst = createAsyncThunk(
  "get/sessions-const",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "event/sessions/listnopagination",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateSessions = createAsyncThunk(
  "create/sessions",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/sessions/create",
        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Session Created successfully");
        dispatch(getSessions({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditSession = createAsyncThunk(
  "edit/session",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/sessions/update",
        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Session Edited successfully");
        dispatch(getSessions({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteSession = createAsyncThunk(
  "delete/session",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/sessions/delete?id=${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Session Deleted successfully");
        dispatch(getSessions({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
