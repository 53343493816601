import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getAttendee, getUsherAttendees } from "redux/Attendee/actions";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import searchLogo from "../../assets/images/search.svg";
import Pagintation from "Components/Pagintation";

const UsherSearch = ({
    searchBy,
    searchText,
    number_of_pages,
    setSearchBy,
    setSearchText,
}) => {
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if (number_of_pages) {
            dispatch(
                getUsherAttendees({
                    pagenumber: 0,
                    search: searchText,
                    searchby: searchBy,
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
    }, [pageNumber]);
    const params = useParams();
    const inputs = [
        {
            type: "qrcode",
            label: "search By Qr code",
        },
        {
            type: "name",
            label: "search By name",
        },
        {
            type: "email",
            label: "search By Email",
        },
        {
            type: "mobileno",
            label: "search By Phone",
        },
    ];
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                margin: "auto",
                maxWidth: "1620px",
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                        getUsherAttendees({
                            pagenumber: 0,
                            search: searchText,
                            searchby: searchBy,
                        })
                    );
                    dispatch(getTicketTypesConst({ event_id: params.id }));
                }}
                className="row"
                style={{
                    padding: "10px",
                    width: "100%",
                }}
            >
                {inputs.map((item, index) => (
                    <div
                        className="col-md-6 col-12 "
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            padding: "10px",
                        }}
                    >
                        <span
                            style={{
                                minWidth: "fit-content",
                                fontSize: "16px",
                                display: "block",
                            }}
                        >
                            {item.label}
                        </span>
                        <input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            placeholder={item.label}
                            value={searchBy === item.type ? searchText : ""}
                            disabled={
                                searchBy !== item.type &&
                                searchText.trim() !== ""
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setSearchBy(item.type);
                                setSearchText(e.target.value);
                            }}
                        />
                    </div>
                ))}
                <Pagintation
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    pageCount={number_of_pages}
                ></Pagintation>
                <button
                    className="page-header-search"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                    onClick={() => {}}
                    type="submit"
                >
                    <img
                        style={{
                            width: "1.5rem",
                            cursor: "pointer",
                        }}
                        src={searchLogo}
                    ></img>
                </button>
            </form>
        </div>
    );
};

export default UsherSearch;
