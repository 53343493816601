import { createReducer } from "@reduxjs/toolkit";
import { getSlideShow } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  slideShow: [],
  loader: false,
  total: 0,
  number_of_pages: 0,
};

export const slideReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSlideShow.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getSlideShow.fulfilled, (state, { payload }) => {
      state.slideShow = payload?.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })

    .addCase(getSlideShow.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default slideReducer;
