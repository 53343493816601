import React, { useEffect, useState } from "react";
import {
  createEvent,
  editEvent,
  getAllEvent,
  getEventsDetails,
} from "redux/Events/actions";
import AsideMenu from "../Components/Asidemenu2";
import PageHeader from "../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../assets/images/ticket.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Loader from "../Components/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import send from "../assets/images/send.svg";

import Pagintation from "../Components/Pagintation";
import dayjs from "dayjs";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateTicketType,
  DeleteTicketType,
  EditTicketType,
  getTicketTypes,
} from "redux/TicketTypes/actions";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const initalTicket = {
  name: "",
  name_ar: "",
  description: "",
  description_ar: "",
  type: 2,
  quantity: null,
  isprivate: 0,
  min: 1,
  max: 1,
  event_id: null,
  infocollect_type: 1,
  startduration: null,
  endduration: null,
  price: 0,
  needapproval: 0,
  // customconfirmemail: "",
  // customconfirmsubject: "",
  // customemailsender: "",
  // customreminderemail: "",
  // customremindersubject: "",

  // approval_mail: "",
  // approval_subject: "",
  // rejection_mail: "",
  // rejection_subject: ,
};

function TicketTypesQuestion() {
  const dispatch = useDispatch();
  const { ticketTypes, loader, total } = useSelector(
    (state) => state.ticketTypes
  );
  const { event } = useSelector((state) => state.events);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const nav = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();

  const [newTicket, setNewTicket] = useState(initalTicket);
  useEffect(() => {
    if (params.id) {
      dispatch(getTicketTypes({ event_id: params.id, pagenumber: pageNumber }));
      dispatch(getEventsDetails(params.id));
    }
  }, [pageNumber, params]);
  console.log(ticketTypes, "newTicket");
  const todayAtNoon = dayjs().set("hour", 12).startOf("hour");
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"TicketTypes"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Ticket Types"}
            showUrl={true}
            EventName={params.id}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row main-row" style={{ margin: "0" }}>
                  {ticketTypes.map((event, index) => (
                    <>
                      <div
                        className="event-box-hidden"
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          nav(`questions/${event.id}`);
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="ticket-status">
                              {event.type == 1
                                ? `${event.price} EGP`
                                : "Free Ticket"}
                            </div>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                nav(`emails/${event.id}`);
                              }}
                            >
                              {" "}
                              <img
                                style={{ width: "1.5rem" }}
                                src={`${send}`}
                              ></img>
                            </div>
                          </div>
                          <div>
                            <div className="ticket-title">{event.name}</div>
                          </div>
                          <div className="ticket-about">
                            {event.description}
                          </div>
                          <div className="ticket-date">
                            {new Date(event?.startduration).toLocaleString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                            -
                            {new Date(event?.endduration).toLocaleString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketTypesQuestion;
