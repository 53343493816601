import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import printJS from "print-js";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUnpaid } from "redux/CheckIn/actions";
const route = process.env.REACT_APP_API;

export const getAttendee = createAsyncThunk(
    "get/attendee",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/attendances/list`,
                params: params,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const getUsherAttendees = createAsyncThunk(
    "get/usher/attendee",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/attendances/list/usher`,
                params: params,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const getApproval = createAsyncThunk(
    "get/approval",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/approval/list`,
                params: params,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const getRejectedList = createAsyncThunk(
    "get/rejectedlist",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/rejection/list`,
                params: params,
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const ExportAttendee = createAsyncThunk(
    "export/attendee",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/attendees/export`,

                params: params,
                responseType: "blob",
            });
            if (response.status === 200) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Attendee" + ".xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const ExportPerType = createAsyncThunk(
    "event/attendees/export-per-type",
    async (params) => {
        console.log(params);
        try {
            const response = await axios({
                method: "get",
                url: `event/attendees/export-per-type`,

                params: params,
                responseType: "blob",
            });
            if (response.status === 200) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", params.type + ".xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return response.data;
        } catch (err) {
            console.log("error", err);
            //return rejectWithValue(err.response.data);
        }
    }
);

export const ExportPendingList = createAsyncThunk(
    "event/pending",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/pending/export`,

                params: params,
                responseType: "blob",
            });
            if (response.status === 200) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Attendee" + ".xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const ExportOrgAttendee = createAsyncThunk(
    "export/orgattendee",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: `organization/attendee/list`,

                params: params,
                responseType: "blob",
            });
            if (response.status === 200) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "Organization-attendee" + ".xlsx"
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const ExportOrgAttendeeFreq = createAsyncThunk(
    "export/orgattendeefreq",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: `organization/attendee/listFrequency`,

                params: params,
                responseType: "blob",
            });
            if (response.status === 200) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "Organization-attendee-freq" + ".xlsx"
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const uploadAttendee = createAsyncThunk(
    "upload/attendee",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: `event/attendees/uploadexcel`,

                data: body,
            });

            dispatch(
                getAttendee({
                    event_id: selectedEvent,
                    pagenumber: 0,
                    search: "",
                    tickettype_id: "",
                })
            );
            return response.data;
        } catch (err) {
            throw err.response.data;
        }
    }
);
export const MarkAsPaid = createAsyncThunk(
    "attendee/markAsPaid",
    async (
        {
            paymentorder_id,
            event_id,
            tickettype_id,
            pagenumber,
            search,
            option_id,
            searchText,
        },
        { dispatch }
    ) => {
        try {
            const response = await axios({
                method: "get",
                url: `event/attendances/mark-as-paid?paymentorder_id=${paymentorder_id}`,
            });

            dispatch(
                getUnpaid({
                    event_id,
                    tickettype_id,
                    pagenumber,
                    option_id,
                    search: searchText,
                })
            );
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const exportFile = createAsyncThunk("export/file", async () => {
    try {
        const response = await axios({
            method: "get",
            url: `event/attendees/getexcel`,
            responseType: "blob",
        });
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Attendee" + ".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
        return response.data;
    } catch (err) {
        //return rejectWithValue(err.response.data);
    }
});
export const CreateAttende = createAsyncThunk(
    "create/newattendee",
    async ({ body, event_id }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/attendances/add-attendee",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Attendee Created successfully");
                dispatch(getAttendee({ event_id }));
            }
            return response.data;
        } catch (e) {
            //return rejectWithValue(err.response.data);
            toast.error(e.response.data.message);
        }
    }
);
export const DeleteAttende = createAsyncThunk(
    "delete/attendee",
    async (
        { body, event_id, tickettype_id, pagenumber, search, option_id },
        { dispatch }
    ) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/attendances/delete-attendee/${body}`,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Attendee Deleted successfully");
                dispatch(
                    getAttendee({
                        event_id,
                        tickettype_id,
                        pagenumber,
                        search,
                        option_id,
                    })
                );
            }
            return response.data;
        } catch (e) {
            //return rejectWithValue(err.response.data);
            toast.error(e.response.data.message);
        }
    }
);
export const DeletePending = createAsyncThunk(
    "delete/attendee",
    async (
        { body, event_id, tickettype_id, pagenumber, search, option_id },
        { dispatch }
    ) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/attendances/delete-attendee/${body}`,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Attendee Deleted successfully");
                dispatch(
                    getApproval({
                        event_id,
                        tickettype_id,
                        pagenumber,
                        search,
                        option_id,
                    })
                );
            }
            return response.data;
        } catch (e) {
            //return rejectWithValue(err.response.data);
            toast.error(e.response.data.message);
        }
    }
);
export const DeleteUnpaid = createAsyncThunk(
    "delete/attendee",
    async (
        { body, event_id, tickettype_id, pagenumber, search, option_id },
        { dispatch }
    ) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/attendances/delete-attendee/${body}`,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Attendee Deleted successfully");
                dispatch(
                    getUnpaid({
                        event_id,
                        tickettype_id,
                        pagenumber,
                        search,
                        option_id,
                    })
                );
            }
            return response.data;
        } catch (e) {
            //return rejectWithValue(err.response.data);
            toast.error(e.response.data.message);
        }
    }
);
export const CreateCompany = createAsyncThunk(
    "create/company",
    async ({ body }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "company/create",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Company Created successfully");
                //dispatch(getCompanies());
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const adminApprove = createAsyncThunk(
    "create/adminapprove",
    async ({ body, event_id, tickettype_id, pagenumber }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/approval-or-reject",
                data: body,
            });
            let params = {
                event_id: event_id,
                pagenumber: pagenumber,
                search: "",
                tickettype_id: tickettype_id,
            };
            if (response.status == 200 || response.status == 204) {
                if (body.get("is_admin_approve") == 1) {
                    toast.success("Admin Approved Succesfully");
                } else {
                    toast.success("Admin Not Approved Succesfully");
                }
                dispatch(getApproval(params));
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const PrintTag = createAsyncThunk(
    "print/tag",
    async ({ id, checkin = 1 }, { dispatch }) => {
        try {
            const response = await axios({
                method: "",
                url: `event/attendances/print/tag?id=${id}&checkin=${checkin}`,
                responseType: "blob",
                headers: {
                    Accept: "application/pdf",
                },
            });

            if (response.status == 200 || response.status == 204) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                printJS(url);
                //  link.setAttribute("download", "Attendee" + ".pdf"); //or any other extension
                //  document.body.appendChild(link);
                //  link.click();
                //  link.focus(); // Set focus.
                //  link.contentWindow.print();
                //dispatch(getCompanies());
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
            toast.error("User Already Checked In");
        }
    }
);
export const EditCompany = createAsyncThunk(
    "edit/company",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "company/update",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Company Edited successfully");
                //dispatch(getCompanies());
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const DeleteCompany = createAsyncThunk(
    "delete/company",
    async ({ updateFlag, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/partners/delete/${updateFlag}`,
            });
            if (response.status == 200 || response.status == 204) {
                toast.success("Partner Deleted successfully");
                //dispatch(getCompanies({ event_id: selectedEvent, pagenumber: 0 }));
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
