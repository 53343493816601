import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const route = process.env.REACT_APP_API;

export const userRegister = createAsyncThunk(
    "user/register",
    async ({ body, nav }) => {
        try {
            const response = await axios.post("customer/register", body);
            console.log(response, "res");
            if (response.status == 200 || response.status == 201) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user-info", response.data);
                nav("/");
                return response.data.user;
            } else {
            }
        } catch (e) {
            //alert();
            let error = Object.values(e.response.data.errors);
            toast.error(error[0][0]);
        }
    }
);
export const userLogin = createAsyncThunk(
    "user/Login",
    async ({ body, nav }) => {
        try {
            const response = await axios.post(
                "auth/login",
                //"http://165.227.140.163/yallaeventsapi/public/api/auth/login",
                body
            );
            if (response.status == 200 || response.status == 201) {
                localStorage.setItem("token", response.data.token);

                localStorage.setItem(
                    "user-info",
                    JSON.stringify(response?.data)
                );
                nav("/");
                return response.data;
            } else {
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);

export const usherLogin = createAsyncThunk(
    "usher/Login",
    async ({ body, nav }) => {
        try {
            const response = await axios.post(
                "auth/ushers/login",
                //"http://165.227.140.163/yallaeventsapi/public/api/auth/login",
                body
            );
            if (response.status == 200 || response.status == 201) {
                localStorage.setItem("token", response.data.token);

                localStorage.setItem(
                    "user-info",
                    JSON.stringify(response?.data)
                );
                nav(`/usher/attendee-list`);
                // nav(`/${response?.data?.user?.event_id}/attendee-list`);

                return response.data;
            } else {
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);
export const moderatorLogin = createAsyncThunk(
    "moderator/Login",
    async ({ body, nav }) => {
        try {
            const response = await axios.post(
                "auth/moderators/login",
                //"http://165.227.140.163/yallaeventsapi/public/api/auth/login",
                body
            );
            if (response.status == 200 || response.status == 201) {
                localStorage.setItem("token", response.data.token);

                localStorage.setItem(
                    "user-info",
                    JSON.stringify(response?.data)
                );
                nav(`/${response?.data?.user?.event_id}/attendee-list`);

                return response.data;
            } else {
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);
export const bsadminLogin = createAsyncThunk(
    "bs/Login",
    async ({ body, nav }) => {
        try {
            const response = await axios.post(
                "auth/exadmins/login",
                //"http://165.227.140.163/yallaeventsapi/public/api/auth/login",
                body
            );
            if (response.status == 200 || response.status == 201) {
                localStorage.setItem("token", response.data.token);

                localStorage.setItem(
                    "user-info",
                    JSON.stringify(response?.data)
                );
                nav(`/${response?.data?.user?.event_id}/organiztion/events`);

                return response.data;
            } else {
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);

export const companyRegister = createAsyncThunk(
    "company/register",
    async ({ body, nav }) => {
        try {
            const response = await axios.post(
                "company/public/registration",
                body
            );
            console.log(response, "res");
            if (response.status == 200 || response.status == 201) {
                toast.success("Account will be reviewed by the admin");
                return response.data;
            } else {
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.title);
        }
    }
);
export const forgetPass = createAsyncThunk(
    "forget/pass",
    async ({ body, nav }) => {
        try {
            const response = await axios.post("password/forget", body);
            if (response.status == 200 || response.status == 201) {
                nav("/Enter-Code");
                return body;
            } else {
                //
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);
export const resetPass = createAsyncThunk(
    "reset/pass",
    async ({ body, nav }) => {
        try {
            const response = await axios.post("password/reset", body);
            console.log(response, "res");
            if (response.status == 200 || response.status == 201) {
                nav("/");
                return body;
            } else {
                //
            }
        } catch (e) {
            //alert();
            toast.error(e.response.data.error);
        }
    }
);
export const getUserInfo = createAsyncThunk(
    "get/userinfo",
    async ({ user }) => {
        return user;
    }
);
export const logOut = createAsyncThunk("logout/user", async ({ nav }) => {
    localStorage.removeItem("user-info");
    localStorage.removeItem("token");
    localStorage.removeItem("selectedEvent");
    localStorage.removeItem("selectedCompany");
    nav("/login");
});
