import React from "react";
import { createPortal } from "react-dom";
import "./FullPageLoading.css";
const FullPageLoading = () => {
    return createPortal(
        <div className="pageLoading">
            please wait...
            <div className="spinner"></div>
        </div>,
        document.getElementById("portal")
    );
};

export default FullPageLoading;
