import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";

import ApexCharts from "apexcharts";
import { getAgeReport, getQuestionReport } from "redux/Reports/actions";
const route = process.env.REACT_APP_IMAGE_URI;

function QuestionsReport() {
  const dispatch = useDispatch();
  const { loader, age, ageDetails, questionsreport } = useSelector(
    (state) => state.reports
  );
  const [searchText, setSearchText] = useState("");

  const { selectedEvent } = useSelector((state) => state.events);
  useEffect(() => {
    if (selectedEvent) {
      dispatch(getQuestionReport({ event_id: selectedEvent }));
    }
  }, []);

  const getSeriesData = (arr, type) => {
    let values = [];
    if (type == "name") {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].name);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].total);
      }
    }

    return values;
  };
  //console.log(QuestionsReport,'questionsreport')
  const getdetailsSeries = () => {
    let value = [];
    let names = [];
    for (let i = 0; i < ageDetails[0].series.length; i++) {
      value.push({ name: ageDetails[0].series[i].name, data: [] });
      names.push(ageDetails[0].series[i].name);
    }
    for (let i = 0; i < ageDetails.length; i++) {
      for (let j = 0; j < ageDetails[i].series.length; j++) {
        for (let k = 0; k < names.length; k++) {
          if (ageDetails[i].series[j].name == names[k]) {
            value[k].data.push(ageDetails[i].series[j].value);
          }
        }
      }
    }
    console.log(value, "data");
    return value;
  };
  useEffect(() => {
    if (questionsreport?.length > 0) {
      for (let i = 0; i < questionsreport.length; i++) {
        console.log(
          getSeriesData(questionsreport[i].options, "value"),
          "tessst"
        );
        var options = {
          chart: {
            type: "donut",
          },
          series: getSeriesData(questionsreport[i].options, "value"),
          labels: getSeriesData(questionsreport[i].options, "name"),
        };
        var chart = new ApexCharts(
          document.querySelector(`#chart${i}`),
          options
        );
        if (questionsreport.length > 0) {
          chart.render();
          chart.destroy();
          var chart = new ApexCharts(
            document.querySelector(`#chart${i}`),
            options
          );
          chart.render();
        }
        console.log(questionsreport, "ques");
      }
    }

    //}
  }, [questionsreport]);

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"ReportQues"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Questions Report"}
            // setSearchText={setSearchText}
            EventName={selectedEvent}
            showUrl={true}
          ></PageHeader>
          <div className="event-page" style={{ marginInlineEnd: "0" }}>
            {!loader ? (
              questionsreport?.length == 0 ? (
                <div>No Charts Available</div>
              ) : (
                <>
                  {questionsreport?.map((question, index) => (
                    <>
                      <div
                        style={{
                          color: "#00D7E1",
                          fontSize: "1.5rem",
                          marginBottom: "0.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {question.name}
                      </div>
                      <div style={{ width: "50%", margin: "5rem auto" }}>
                        <div id={`chart${index}`}></div>
                      </div>
                    </>
                  ))}
                </>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionsReport;
