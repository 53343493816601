import React, { useEffect, useRef, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../../Components/Asidemenu2";
import PageHeader from "../../../Components/PageHeader";
import Loader from "../../../Components/Loader";

import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";

import Pagintation from "../../../Components/Pagintation";
import {
    CreateSponsors,
    DeleteSponsors,
    EditSponsors,
    getSponsors,
} from "redux/Sponsors/actions";
import { useNavigate, useParams } from "react-router";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
const route = process.env.REACT_APP_IMAGE_URI;
const initalSponsor = {
    name: "",
    about: "",
    address: "",
    logo: null,
    image: null,
    video: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    event_id: null,
    category: null,
};

function Sponsorsales() {
    const dispatch = useDispatch();
    const { sponsors, loader, total, number_of_pages } = useSelector(
        (state) => state.sponsors
    );
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalSponsor);
    const params = useParams();
    const nav = useNavigate();

    useEffect(() => {
        if (params.id) {
            dispatch(
                getSponsors({ event_id: params.id, pagenumber: pageNumber })
            );
        }
    }, [pageNumber, params]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const addind = () => {
        if (
            newSponsor.name == "" ||
            newSponsor.about == "" ||
            newSponsor.address == " " ||
            !newSponsor.category ||
            newSponsor.facebook == "" ||
            !newSponsor.image ||
            !newSponsor.logo ||
            newSponsor.instagram == "" ||
            newSponsor.video == "" ||
            newSponsor.website == "" ||
            newSponsor.youtube == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newSponsor };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditSponsors({ body, selectedEvent: params.id }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateSponsors({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalSponsor);
        setError(false);
        setUpdateFlag(-1);
    };

    return (
        <>
            <div className="aside-div">
                <AsideMenu selected={"Bs"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Sponsor Sales"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    {sponsors.map((event, index) => (
                                        <>
                                            <div
                                                className="sponsor-box"
                                                style={{ display: "flex" }}
                                                onClick={(e) => {
                                                    nav(`${event.id}`);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        //display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <img
                                                            style={{
                                                                maxWidth:
                                                                    "7rem",
                                                                maxHeight:
                                                                    "5rem",
                                                            }}
                                                            src={`${route}${event.logo}`}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sponsorsales;
