import { CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, Form, Formik, FormikProvider } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ChangeTicketType, editTicket } from "redux/Events/thunks";
import { EditTicketType } from "redux/TicketTypes/actions";

import { useLoadingSelector } from "redux/selectors";
import { AppDispatch, useAppDispatch } from "redux/store";

const EditForm = ({
    code,
    setIsInEditMode,
    setDetailsModal,
    tickets,
    eventid,
    ticketId,
    ticketDetails,
}: any) => {
    const dispatch = useAppDispatch();
    const [selectedTicket, setSelectedTicket] = React.useState("");
    const isLoading = useLoadingSelector(editTicket);
    console.log(ticketId);
    return (
        <Formik
            initialValues={{
                email: code.email,
                mobileno: code.mobileno,
                name: ticketDetails.name,
                lname: ticketDetails?.lname,
            }}
            onSubmit={(values) => {
                let data = {
                    id: code.paymentorder_id,
                    new_tickettypeid: selectedTicket,
                };
                let selectedEvent = eventid;
                dispatch(
                    ChangeTicketType({
                        data,
                        selectedEvent,
                        ticketId: ticketId,
                    })
                ).finally(() => {
                    dispatch(editTicket({ ...values, id: code.id }))
                        .then(unwrapResult)
                        .then((res) => {
                            if (!res) {
                                toast.error(
                                    "Something Went Wrong, Please try again!"
                                );
                                return;
                            }
                            setIsInEditMode("");
                            setDetailsModal(false);
                        });
                });
            }}
        >
            {(props) => (
                <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                    <th
                        style={{
                            color: "black",
                            display: "flex",
                            gap: "1rem",
                            width: "300px",
                        }}
                    >
                        <Field
                            {...props}
                            name="name"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                        <Field
                            {...props}
                            name="lname"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                    </th>
                    <th style={{ color: "black" }}>
                        <select
                            className="form-control form-control-lg form-control-solid"
                            name="type"
                            placeholder=""
                            value={code.ticket}
                            onChange={(e) => {
                                setSelectedTicket(e.target.value);
                            }}
                        >
                            <option value="" disabled selected>
                                Select Ticket Type
                            </option>
                            {tickets?.map((ticket: any, index: any) => (
                                <option key={index} value={ticket.id}>
                                    {ticket.name}
                                </option>
                            ))}
                        </select>
                    </th>
                    <th style={{ color: "black" }}>{code.price}</th>
                    <th style={{ color: "black" }}>
                        <Field
                            {...props}
                            name="mobileno"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                    </th>
                    <th style={{ color: "black" }}>
                        <Field
                            {...props}
                            name="email"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                    </th>
                    <th style={{ color: "black" }}>{code?.qrcode?.code}</th>
                    <th>
                        <div
                            className="attendee-export"
                            style={{
                                alignItems: "center",
                                display: "flex",
                                backgroundColor: "#2B6EC8",
                                color: "white",
                                width: "4rem",
                                cursor: "pointer",
                            }}
                            onClick={props.submitForm}
                        >
                            {isLoading ? (
                                <CircularProgress color="inherit" size={14} />
                            ) : (
                                <span>Save</span>
                            )}
                        </div>
                    </th>
                </tr>
            )}
        </Formik>
    );
};

export default EditForm;
