import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getAttendanceLine = createAsyncThunk(
  "get/attendanceline",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "reports/attendance/line",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getGenderReport = createAsyncThunk(
  "get/genderReport",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "reports/gender",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getTypesReport = createAsyncThunk(
  "get/typeReport",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "reports/tickettypes",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getAgeReport = createAsyncThunk(
  "get/AgeReport",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "reports/age",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getQuestionReport = createAsyncThunk(
  "get/Question",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "report/dynamic-questions",
        params: params,
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getOverViewReport = createAsyncThunk(
  "get/OverViewReport",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "report/tickettype/event",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getEventOverView = createAsyncThunk(
  "get/eventOverViewReport",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "report/event/tickettypes",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getselectedReport = createAsyncThunk(
  "get/selectedrepo",
  async (data) => {
    try {
      return data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
