import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getBooths = createAsyncThunk("get/booths", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/booths/list`,

      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateBooths = createAsyncThunk(
  "create/booths",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/booths/create`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Booth Created successfully");
        dispatch(getBooths({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditBooths = createAsyncThunk(
  "edit/booth",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/booths/update`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Booth Edited successfully");
        dispatch(getBooths({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteBooths = createAsyncThunk(
  "delete/booths",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/booths/delete/${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Booth Deleted successfully");
        dispatch(getBooths({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getBoothsTeam = createAsyncThunk("get/boothsTeam", async ({id,params}) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/booths/teams/list/${id}`,

      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateBoothsTeam = createAsyncThunk(
  "create/boothsTeam",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/booths/teams/create`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Member Created successfully");
        dispatch(getBoothsTeam({ id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditBoothsTeam = createAsyncThunk(
  "edit/boothTeam",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/booths/teams/update`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Member Edited successfully");
        dispatch(getBoothsTeam({ id: selectedEvent, pagenumber: 0 }));
      }
      else{
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeletedBoothsTeam = createAsyncThunk(
  "edit/deleteTeam",
  async ({ id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/booths/teams/delete/${id}`,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Member Deleted successfully");
        dispatch(getBoothsTeam({ id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);