import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
console.log(route, "route");

export const getEventAgenda = createAsyncThunk(
  "get/eventAgenda",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "get",
        url: `event/agenda/details`,
        params: params,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createEventAgenda = createAsyncThunk(
  "create/newEventAgenda",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/agenda/create`,
        data: body,
      });

      toast.success("Agenda Created successfully");
      dispatch(getEventAgenda({ event_id: selectedEvent }));
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const editEventAgenda = createAsyncThunk(
  "edit/EventAgenda",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/agenda/update`,
        data: body,
      });

      toast.success("Agenda Edited successfully");
      dispatch(getEventAgenda({ event_id: selectedEvent }));

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getAgendaDays = createAsyncThunk(
  "get/AgendaDays",
  async (selectedEvent) => {
    try {
      const response = await axios({
        method: "get",
        url: `event/agenda/days/list`,
        params: selectedEvent,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const setIntial = createAsyncThunk("get/inital", async () => {
  return;
});
