import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import TextField from "@mui/material/TextField";
import Loader from "../../Components/Loader";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import {
  CreateCompanysmtp,
  EditCompanysmtp,
  getCompanies,
  getCompaniesSmtp,
} from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateTicketType,
  DeleteTicketType,
  EditTicketType,
  getTicketTypes,
  getTicketTypesConst,
} from "redux/TicketTypes/actions";
import {
  CreateCode,
  DeleteCode,
  EditCode,
  getCodes,
} from "redux/Codes/actions";
import { useParams } from "react-router";

const initalCode = {
  company_id: "",
  smtp_host: "",
  smtp_port: "",
  smtp_username: "",
  smtp_password: "",
  smtp_from_address: "",
  smtp_encryption: "",
};

function EmailConfig() {
  const dispatch = useDispatch();
  const { codes, loader, total } = useSelector((state) => state.code);
  const { smtp } = useSelector((state) => state.companies);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newCode, setNewCode] = useState(initalCode);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(getCompaniesSmtp(params.uid));

      //dispatch(getTicketTypes_const())
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newCode.name == "" ||
      newCode.endduration == "" ||
      newCode.tickettypes == "" ||
      newCode.startduration == "" ||
      newCode.numberofuses == "" ||
      newCode.promovalue == " "
    ) {
      setError(true);
      return;
    }
    if (updateFlag == -1) {
      let body = { ...newCode };
      body.company_id = parseInt(params.uid);
      dispatch(CreateCompanysmtp({ body, selectedEvent: params.id }));
      setEventModal(false);
    } else if (updateFlag != -1) {
      let body = { ...newCode };
      body.company_id = parseInt(params.uid);
      dispatch(EditCompanysmtp({ body, id: smtp.id }));
      setEventModal(false);
    }
    setNewCode(initalCode);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  console.log(smtp, "newticket");
  useEffect(() => {
    if (smtp?.id) {
      newCode.smtp_username = smtp.smtp_username;
      newCode.smtp_encryption = smtp.smtp_encryption;
      newCode.smtp_from_address = smtp.smtp_from_address;
      newCode.smtp_host = smtp.smtp_host;
      newCode.smtp_password = smtp.smtp_password;
      newCode.smtp_port = smtp.smtp_port;
      if (smtp.id) {
        setUpdateFlag(smtp.id);
      } else {
        setUpdateFlag(-1);
      }
    }
  }, [smtp]);
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewCode(initalCode);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
            Edit Email Configuratioin
          </h1>

          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    User Name
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_username
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_username"
                    placeholder=""
                    value={newCode?.smtp_username}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Password
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_password
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_password"
                    placeholder=""
                    value={newCode.smtp_password}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Port
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_port
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="number"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_port"
                    placeholder=""
                    value={newCode.smtp_port}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Host
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_host
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_host"
                    placeholder=""
                    value={newCode.smtp_host}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Host
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_host
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_host"
                    placeholder=""
                    value={newCode.smtp_host}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    From Address
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_from_address
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_from_address"
                    placeholder=""
                    value={newCode.smtp_from_address}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Encryption
                  </span>
                  <input
                    style={
                      error && !newCode.smtp_encryption
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="smtp_encryption"
                    placeholder=""
                    value={newCode.smtp_encryption}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"emailconf"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Email Configuratioin"}
            // setSearchText={setSearchText}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "10%", color: "white" }}
                        >
                          #
                        </th>
                        <th style={{ width: "10%", color: "white" }}>Title</th>
                        <th style={{ width: "10%", color: "white" }}>
                          Number of redeems
                        </th>
                        <th style={{ width: "10%", color: "white" }}>
                          Number of actual users
                        </th>
                        <th style={{ width: "10%", color: "white" }}>values</th>
                        <th
                          style={{ width: "5%", color: "white" }}
                          className="tlast"
                        >
                          Actions
                        </th>
                      </tr>

                      <React.Fragment>
                        <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                          <th style={{ color: "black" }}>{smtp?.smtp_host}</th>
                          <th style={{ color: "black" }}>{smtp?.smtp_port}</th>

                          <th style={{ color: "black" }}>
                            {smtp?.smtp_username}
                          </th>
                          <th style={{ color: "black" }}>
                            {smtp?.smtp_password}
                          </th>
                          <th style={{ color: "black" }}>
                            {smtp?.smtp_from_address}
                          </th>
                          <th style={{ color: "black" }}>
                            {smtp?.smtp_encryption}
                          </th>
                        </tr>
                      </React.Fragment>
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Edit Email Configuration</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailConfig;
