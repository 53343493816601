import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import TextField from "@mui/material/TextField";
import Loader from "../../Components/Loader";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateTicketType,
  DeleteTicketType,
  EditTicketType,
  getTicketTypes,
  getTicketTypesConst,
} from "redux/TicketTypes/actions";
import {
  CreateCode,
  DeleteCode,
  EditCode,
  getCodes,
} from "redux/Codes/actions";
import { useParams } from "react-router";

const initalCode = {
  name: "",
  type: 2,
  numberofuses: "",
  note: "",
  tickettypes: "",
  startduration: null,
  endduration: null,
  promovalue: null,
  event_id: null,
};

function Codes() {
  const dispatch = useDispatch();
  const { codes, loader, total } = useSelector((state) => state.code);
  const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newCode, setNewCode] = useState(initalCode);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(getCodes({ event_id: params.id, pagenumber: pageNumber }));
      dispatch(getTicketTypesConst({ event_id: params.id }));
      //dispatch(getTicketTypes_const())
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newCode.name == "" ||
      newCode.endduration == "" ||
      newCode.tickettypes == "" ||
      newCode.startduration == "" ||
      newCode.numberofuses == "" ||
      newCode.promovalue == " "
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newCode };
      form.id = parseInt(updateFlag);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(EditCode({ body, selectedEvent: params.id }));
      setEventModal(false);
    } else {
      let form = { ...newCode };
      form.event_id = parseInt(params.id);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreateCode({ body, selectedEvent: params.id }));
      setEventModal(false);
    }
    setNewCode(initalCode);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  console.log(codes, "newticket");
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewCode(initalCode);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create New Promo Code
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Promo Code
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Code name
                  </span>
                  <input
                    style={
                      error && !newCode.name ? { border: "1px solid red" } : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    placeholder=""
                    value={newCode?.name}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Number of Uses
                  </span>
                  <input
                    style={
                      error && !newCode.numberofuses
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="numberofuses"
                    placeholder=""
                    value={newCode.numberofuses}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Promo values
                  </span>
                  <input
                    style={
                      error && !newCode.promovalue
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="number"
                    className="form-control form-control-lg form-control-solid"
                    name="promovalue"
                    placeholder=""
                    value={newCode.promovalue}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Choose Ticket Types
                  </span>
                  <select
                    style={
                      error && !newCode.tickettypes
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="tickettypes"
                    placeholder=""
                    value={newCode?.tickettypes}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value={""}></option>
                    {ticketTypesConst?.map((ticket, index) => (
                      <option value={ticket.id}>{ticket.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                  <label
                    style={{ color: "black" }}
                    className="d-flex align-items-center fs-7 mb-2"
                  >
                    <span>Start Date</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TextField
                      id="date"
                      type="date"
                      sx={{
                        width: "100%",
                        backgroundColor: "#eaf4fa",
                        borderRadius: "16px",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="startduration"
                      value={newCode.startduration}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                  <label
                    style={{ color: "black" }}
                    className="d-flex align-items-center fs-7 mb-2"
                  >
                    <span>End date</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TextField
                      id="date"
                      type="date"
                      sx={{
                        width: "100%",
                        backgroundColor: "#eaf4fa",
                        borderRadius: "16px",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="endduration"
                      value={newCode.endduration}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Note
                  </span>
                  <input
                    style={
                      error && !newCode.note
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="note"
                    placeholder=""
                    value={newCode.note}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Codes"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Codes"}
            // setSearchText={setSearchText}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "10%", color: "white" }}
                        >
                          #
                        </th>
                        <th style={{ width: "10%", color: "white" }}>Title</th>
                        <th style={{ width: "10%", color: "white" }}>
                          Number of redeems
                        </th>
                        <th style={{ width: "10%", color: "white" }}>
                          Number of actual users
                        </th>
                        <th style={{ width: "10%", color: "white" }}>values</th>
                        <th
                          style={{ width: "5%", color: "white" }}
                          className="tlast"
                        >
                          Actions
                        </th>
                      </tr>

                      {codes.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th style={{ color: "black" }}>{code.id}</th>
                            <th style={{ color: "black" }}>{code.name}</th>
                            <th style={{ color: "black" }}>
                              {code.numberofuses}
                            </th>
                            <th style={{ color: "black" }}>
                              {code.actualused}
                            </th>
                            <th style={{ color: "black" }}>
                              {code.promovalue}
                            </th>
                            <th>
                              <span
                                onClick={() => {
                                  setUpdateFlag(code.id);
                                  setNewCode({
                                    name: code.name,
                                    numberofuses: code.numberofuses,
                                    promovalue: code.promovalue,
                                    startduration:
                                      code.startduration?.split(" ")[0],
                                    endduration:
                                      code.endduration?.split(" ")[0],
                                    note: code.note,

                                    type: 2,
                                    tickettypes: code.tickettype_id,
                                  });
                                  setEventModal(true);
                                }}
                              >
                                <img
                                  src={edit}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                  }}
                                ></img>
                              </span>
                              <span
                                onClick={() => {
                                  dispatch(
                                    DeleteCode({
                                      updateFlag: code.id,
                                      selectedEvent: params.id,
                                    })
                                  );
                                }}
                              >
                                <img
                                  src={del}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Codes</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Codes;
