import { createReducer } from "@reduxjs/toolkit";
import {
    getEventsDetails,
    getEventsAgenda,
    getAllEvent,
    createEvent,
    getCompanies,
    getAdmins,
    getSuperAdmins,
    getUshers,
    getBoothsAdmins,
    getBoothsSales,
    getModerators,
    getAllUshers,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
    admins: [],
    superAdmins: [],
    boothsadmins: [],
    boothssales: [],
    allUshers: [],
    ushers: [],
    moderators: [],
    pagecount: 0,
    loader: false,
    total: 0,
};

export const userRolesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getAdmins.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getAdmins.fulfilled, (state, { payload }) => {
            state.admins = payload.list;
            state.loader = false;
            state.total = payload.total;

            state.pagecount = payload.number_of_pages;
            //state.errorpayload = false;
        })
        .addCase(getAdmins.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        })
        .addCase(getAllUshers.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getAllUshers.fulfilled, (state, { payload }) => {
            state.allUshers = payload.list;
            state.loader = false;
            state.total = payload.total;

            state.pagecount = payload.number_of_pages;
            //state.errorpayload = false;
        })
        .addCase(getAllUshers.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        })
        .addCase(getBoothsAdmins.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getBoothsAdmins.fulfilled, (state, { payload }) => {
            state.boothsadmins = payload.list;
            state.loader = false;
            state.total = payload.total;

            //state.errorpayload = false;
        })
        .addCase(getBoothsSales.fulfilled, (state, { payload }) => {
            state.boothssales = payload.list;
            state.loader = false;
            state.total = payload.total;
            //state.errorpayload = false;
        })
        .addCase(getBoothsAdmins.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        })
        .addCase(getSuperAdmins.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getSuperAdmins.fulfilled, (state, { payload }) => {
            state.superAdmins = payload.list;
            state.loader = false;
            state.total = payload.total;
            //state.errorpayload = false;
        })
        .addCase(getSuperAdmins.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        })
        .addCase(getUshers.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getUshers.fulfilled, (state, { payload }) => {
            state.ushers = payload;
            state.loader = false;
            state.total = payload.total;
            //state.errorpayload = false;
        })

        .addCase(getUshers.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        })
        .addCase(getModerators.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getModerators.fulfilled, (state, { payload }) => {
            console.log("payload", payload);
            state.moderators = payload?.list;
            state.loader = false;
            state.total = payload.total;
            //state.errorpayload = false;
        })
        .addCase(getModerators.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        });
});

export default userRolesReducer;
