import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
const route1 = process.env.REACT_APP_API2;

export const getEventsDetails = createAsyncThunk(
    "get/eventdetails",
    async (EventName, { rejectWithValue }) => {
        const eventName =
            typeof EventName != "object" ? EventName : EventName.EventName;
        const date = typeof EventName == "object" ? EventName.currentDate : "";
        try {
            const response = await axios({
                method: "get",
                url: `event/details/${eventName}?${date ? `date=${date}` : ""}`,
            });
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const getProfile = createAsyncThunk("get/profile", async () => {
    try {
        const response = await axios({
            method: "get",
            url: "auth/user-profile",
        });
        console.log(response.data);
        return response.data;
    } catch (err) {
        //return rejectWithValue(err.response.data);
    }
});
export const editProfile = createAsyncThunk(
    "edit/profile",
    async (form, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "post",
                url: "user/update",
                data: form,
            });
            console.log(response.data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.message);
        }
    }
);
export const getEventsAgenda = createAsyncThunk(
    "get/eventAgenda",
    async (params) => {
        try {
            const response = await axios.get("website/event/agenda", {
                params,
            });

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const getAllEvent = createAsyncThunk("get/allEvent", async (params) => {
    try {
        const response = await axios({
            method: "get",
            url: "event/list",
            //url:"http://165.227.140.163/yallaeventsapi/public/api/event/list",
            params: params,
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (err) {
        //return rejectWithValue(err.response.data);
    }
});
export const getAllEvent2 = createAsyncThunk(
    "get/allEvent2",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: "event/list",

                params: params,
            });
            console.log(response, "events");
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const getAllEventConst = createAsyncThunk(
    "get/allEventConst",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: "event/nopaginationlist",

                params: params,
            });

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const createEvent = createAsyncThunk(
    "create/newEvent",
    async ({ form, pagenumber }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/create",
                //url:"http://165.227.140.163/yallaeventsapi/public/api/event/create",
                data: form,
            });

            toast.success("Event Created successfully");
            dispatch(
                getAllEvent({ pagenumber: pagenumber, name: "", archive: 0 })
            );

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const DuplicateEvent = createAsyncThunk(
    "duplicate/newEvent",
    async ({ body }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/duplicate-event",

                data: body,
            });
            if (response.status == 200) {
                toast.success("Event Created successfully");
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const editEvent = createAsyncThunk(
    "edit/Event",
    async ({ form, pagenumber, archive }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/update",
                //url: "http://165.227.140.163/yallaeventsapi/public/api/event/update",

                data: form,
            });

            toast.success("Event Edited successfully");
            dispatch(
                getAllEvent2({
                    pagenumber: pagenumber,
                    name: "",
                    archive: archive,
                })
            );

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const editEventDetails = createAsyncThunk(
    "edit/Eventdetails",
    async ({ form, pagenumber, archive }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/update",

                data: form,
            });
            const id = form.get("id");

            toast.success("Email Edited successfully");
            dispatch(getEventsDetails(id));

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const archiveEvents = createAsyncThunk(
    "archive/Event",
    async ({ archivedEvent, pagenumber }, { dispatch }) => {
        try {
            const response = await axios({
                method: "put",
                url: `event/archive/${archivedEvent}`,
            });

            toast.success("Event Archived successfully");
            dispatch(
                getAllEvent2({ pagenumber: pagenumber, name: "", archive: 0 })
            );

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const unArchiveEvents = createAsyncThunk(
    "unArchive/Event",
    async ({ archivedEvent, pagenumber }, { dispatch }) => {
        try {
            const response = await axios({
                method: "put",
                url: `event/unarchive/${archivedEvent}`,
            });

            toast.success("Event Unarchived successfully");
            dispatch(
                getAllEvent2({ pagenumber: pagenumber, name: "", archive: 1 })
            );

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);

export const getSelectedEvent = createAsyncThunk(
    "get/selectedEvent",
    async ({ selectedCompany, selectedEvent, nav }) => {
        try {
            localStorage.setItem("selectedCompany", selectedCompany);
            localStorage.setItem("selectedEvent", selectedEvent);
            //nav("/organiztion/events");
            return [selectedCompany, selectedEvent];
        } catch (e) {}
    }
);

export const SelectEvent = createAsyncThunk(
    "get/selectEvent",
    async ({ user, selectedCompany, selectedEvent, nav }) => {
        try {
            localStorage.setItem("selectedCompany", selectedCompany);
            localStorage.setItem("selectedEvent", selectedEvent);
            console.log(user, "user");
            if (user?.user?.type == 5) {
                nav("/" + selectedEvent + "/attendee-list");
            } else {
                nav("/" + selectedEvent + "/organiztion/events");
            }
            return [selectedCompany, selectedEvent];
        } catch (e) {}
    }
);

export const DeleteEvent = createAsyncThunk(
    "delete/event",
    async ({ updateFlag, pagenumber, archive }, { dispatch }) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/delete/${updateFlag}`,
            });
            if (response.status == 200 || response.status == 204) {
                if (response.data?.title == "Error") {
                    toast.success(response.data.message);
                } else {
                    toast.success("Event Deleted successfully");
                }
                dispatch(
                    getAllEvent({
                        pagenumber: pagenumber,
                        name: "",
                        archive: archive,
                    })
                );
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);

export const sendTestEmail = createAsyncThunk(
    "edit/testEmail",
    async (form) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/send-test-email",
                data: form,
            });
            toast.success("Email Sent successfully");
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);

export const doSendEmailToAll = createAsyncThunk(
    "edit/send_email_to_all",
    async (form, { rejectWithValue }) => {
        try {
            const response = await axios.post("event/send-emails-all", form);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const doScheduleEmails = createAsyncThunk(
    "edit/send_email_to_all",
    async (form, { rejectWithValue }) => {
        try {
            const response = await axios.post("event/schedual-emails", form);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
