import { createReducer } from "@reduxjs/toolkit";
import {
  getEventsDetails,
  getEventsAgenda,
  getAllEvent,
  createEvent,
  getCompanies,
  getAttendee,
  ExportAttendee,
  uploadAttendee,
  exportFile,
  getCheckIn,
  getCheckIn2,
  getUnpaid,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  checkIn: [],
  unpaid:[],
  loader: false,
  number_of_pages: 0,
  total: 0,
  importAttendee: false,
};

export const checkInReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCheckIn.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getCheckIn.fulfilled, (state, { payload }) => {
      state.checkIn = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getUnpaid.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getUnpaid.fulfilled, (state, { payload }) => {
      state.unpaid = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getUnpaid.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getCheckIn2.fulfilled, (state, { payload }) => {
      state.checkIn = payload.list;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getCheckIn.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default checkInReducer;
