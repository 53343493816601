import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getSlideShow = createAsyncThunk(
  "get/slideshow",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: `https://frontiers.o-projects.org/api/event/slideshow/list`,

        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateSlideShow = createAsyncThunk(
  "create/slideshow",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/slideshow/create`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("SlideShow Created successfully");
        dispatch(getSlideShow({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditSlideShow = createAsyncThunk(
  "edit/slide",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: `event/slideshow/update`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("SlideShow Edited successfully");
        dispatch(getSlideShow({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
