import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";

import ApexCharts from "apexcharts";
import { getAgeReport } from "redux/Reports/actions";
const route = process.env.REACT_APP_IMAGE_URI;

function AgeGroup() {
  const dispatch = useDispatch();
  const { loader, age, ageDetails } = useSelector((state) => state.reports);
  const [searchText, setSearchText] = useState("");

  const { selectedEvent } = useSelector((state) => state.events);
  useEffect(() => {
    if (selectedEvent) {
      dispatch(getAgeReport({ id: selectedEvent }));
    }
  }, []);

  const getSeriesData = (arr, type) => {
    let values = [];
    if (type == "name") {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].name);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].value);
      }
    }

    return values;
  };
  const getdetailsSeries = () => {
    let value = [];
    let names = [];
    for (let i = 0; i < ageDetails[0].series.length; i++) {
      value.push({ name: ageDetails[0].series[i].name, data: [] });
      names.push(ageDetails[0].series[i].name);
    }
    for (let i = 0; i < ageDetails.length; i++) {
      for (let j = 0; j < ageDetails[i].series.length; j++) {
        for (let k = 0; k < names.length; k++) {
          if (ageDetails[i].series[j].name == names[k]) {
            value[k].data.push(ageDetails[i].series[j].value);
          }
        }
      }
    }
    console.log(value, "data");
    return value;
  };
  useEffect(() => {
    if (age && ageDetails.length > 0) {
      var options = {
        chart: {
          type: "donut",
        },
        series: getSeriesData(age, "value"),
        labels: getSeriesData(age, "name"),
      };
      var options2 = {
        series: getdetailsSeries(),

        chart: {
          type: "bar",
          height: 350,
          width: 900,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ageDetails.map((obj, index) => {
            return obj.name;
          }),
        },

        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
      var chart = new ApexCharts(document.querySelector("#chart"), options);
      var chart1 = new ApexCharts(document.querySelector("#chart1"), options2);

      if (loader == false) {
        chart.render();
        chart1.render();
      }
    }

    //}
  }, [loader]);

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"ReportAge"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Gender Report"}
            // setSearchText={setSearchText}
            EventName={selectedEvent}
            showUrl={true}
          ></PageHeader>
          <div className="event-page" style={{ marginInlineEnd: "0" }}>
            {!loader ? (
              !ageDetails && !age ? (
                <div>No Charts Available</div>
              ) : (
                <>
                  <div style={{ width: "50%", margin: "5rem auto" }}>
                    <div id="chart"></div>
                  </div>
                  <div
                    style={{
                      margin: "5rem auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div id="chart1"></div>
                  </div>
                </>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AgeGroup;
