import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getQuestion = createAsyncThunk("get/question", async (params) => {
    try {
        const response = await axios({
            method: "get",
            url: "tickettype/questions/list",

            params: params,
        });

        return response.data;
    } catch (err) {
        //return rejectWithValue(err.response.data);
    }
});
export const CreateQuestion = createAsyncThunk(
    "create/questoin",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "tickettype/questions/create",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success(" Question Created successfully");
                dispatch(
                    getQuestion({ tickettype_id: selectedEvent, pagenumber: 0 })
                );
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const ModifyOptionsApi = createAsyncThunk(
    "edit/options",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "tickettype/questions/options/update",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success(" options updated successfully");
                dispatch(
                    getQuestion({ tickettype_id: selectedEvent, pagenumber: 0 })
                );
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const EditQuestion = createAsyncThunk(
    "edit/question",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "tickettype/questions/update",

                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success("Question Edited successfully");
                dispatch(
                    getQuestion({ tickettype_id: selectedEvent, pagenumber: 0 })
                );
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const DeleteQuestion = createAsyncThunk(
    "delete/question",
    async ({ updateFlag, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "delete",
                url: `tickettype/questions/delete/${updateFlag}`,
            });
            if (response.status == 200 || response.status == 204) {
                toast.success("Question Deleted successfully");
                dispatch(
                    getQuestion({ tickettype_id: selectedEvent, pagenumber: 0 })
                );
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
