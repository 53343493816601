import React, { useEffect, useState } from "react";

import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";

import Loader from "../../Components/Loader";
import { useNavigate } from "react-router";

function UserRoles() {
    const nav = useNavigate();

    return (
        <>
            <div className="aside-div">
                <AsideMenu selected={"UserRoles"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"User Roles"}
                        setSearchText={""}
                        EventName={""}
                    ></PageHeader>
                    <div className="event-page">
                        <>
                            <div
                                className="row main-row"
                                style={{ margin: "0" }}
                            >
                                <div
                                    className="col-3 event-box-users"
                                    onClick={() => {
                                        nav("/user-roles/super-admin");
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div
                                        className="create-text"
                                        style={{ color: "white" }}
                                    >
                                        Super Admin
                                    </div>
                                </div>

                                <div
                                    className="col-3 event-box-users"
                                    onClick={() => {
                                        nav("/user-roles/admin");
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div
                                        className="create-text"
                                        style={{ color: "white" }}
                                    >
                                        Admin
                                    </div>
                                </div>
                                <div
                                    className="col-3 event-box-users"
                                    onClick={() => {
                                        nav("/user-roles/ushers");
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div
                                        className="create-text"
                                        style={{ color: "white" }}
                                    >
                                        Usher
                                    </div>
                                </div>
                                {/* 
                <div
                  className="col-3 event-box-users"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Moderator
                  </div>
                  <div style={{ fontSize: "1rem", color: "white" }}>
                    (Coming Soon)
                  </div>
                </div> */}
                                {/* 
                <div
                  className="col-3 event-box-users"
                  onClick={() => {
                    nav("/user-roles/ushers");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Ushers
                  </div>
                </div>
                <div
                  className="col-3 event-box-users"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Exhibitor/Sponsor Admin
                  </div>
                  <div style={{ fontSize: "1rem", color: "white" }}>
                    (Coming Soon)
                  </div>
                </div>

                <div
                  className="col-3 event-box-users"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Exhibitor/Sponsor Sales
                  </div>
                  <div style={{ fontSize: "1rem", color: "white" }}>
                    (Coming Soon)
                  </div>
                </div> */}
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserRoles;
