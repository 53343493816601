import React, { useEffect, useMemo, useState } from "react";
import {
    createEvent,
    doScheduleEmails,
    doSendEmailToAll,
    editEventDetails,
    getEventsDetails,
    sendTestEmail,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import send from "../../assets/images/send.svg";
import edit from "../../assets/images/white-edit.svg";
import sendAll2 from "../../assets/images/send3.svg";
import del from "../../assets/images/delete.svg";
import reminder from "../../assets/images/alarm-clock-solid.svg";
import Loader from "../../Components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
import { useRef } from "react";
import { useParams } from "react-router";
import { useLoadingSelector } from "redux/selectors";
import { initalEvent } from "./data";
import DateTimePickerComponent from "./components/DateTimePicker";
import dayjs from "dayjs";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

function Emails() {
    const defaultConfig = {
        uploader: {
            insertImageAsBase64URI: true, // Optional: to handle image as Base64
            // url: "your_upload_endpoint", // Endpoint for server-side upload handler
            // format: "json", // Expected response format
            // headers: {
            //     Authorization: "Bearer YOUR_TOKEN", // Optional: Add headers if needed
            // },
        },
        license: "C464Q-1GIK1-BF6OK-K290Y",
    };
    const dispatch = useDispatch();
    const dispatch2 = useAppDispatch();
    const { event, loader } = useSelector((state) => state.events);
    const { selectedEvent } = useSelector((state) => state.events);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [type, setType] = useState();
    const [email, setNewEmail] = useState({
        type: "2",
        testemail: "",
        event_id: "",
    });
    const [error, setError] = useState(false);
    const [tempType, settempType] = useState(null);
    const [content, setContent] = useState();
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalEvent);
    const isSendToAllLoading = useLoadingSelector(doSendEmailToAll);
    const isSendTestEmailLoading = useLoadingSelector(sendTestEmail);
    const params = useParams();
    //schedule
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
    const isScheduleLoading = useLoadingSelector(doScheduleEmails);
    const schedule = () => {
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("date", value.format("YYYY-MM-DD HH:mm:ss"));
        dispatch2(doScheduleEmails(form))
            .then(unwrapResult)
            .then((res) => {
                if (!res) {
                    toast.error("Something went wrong, Please try again");
                }
                setIsScheduleModalOpen(false);
            });
    };
    ///////////

    useEffect(() => {
        if (params.id) {
            dispatch(getEventsDetails(params.id));
        }
    }, [params]);

    const sendEmailToAll = (type) => {
        setType(type);
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("type", type);
        dispatch(doSendEmailToAll(form));
    };
    const changeReachTextValue = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                style={
                    error &&
                    (tempType == 1
                        ? !newSponsor.customconfirmemail
                        : tempType == 2
                        ? !newSponsor.customreminderemail
                        : tempType == 3
                        ? !newSponsor.approval_mail
                        : !newSponsor.rejection_mail)
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name={
                    tempType == 1
                        ? "customconfirmemail"
                        : tempType == 2
                        ? "customreminderemail"
                        : tempType == 3
                        ? "approval_mail"
                        : "rejection_mail"
                }
                placeholder=""
                value={
                    tempType == 1
                        ? newSponsor.customconfirmemail
                        : tempType == 2
                        ? newSponsor.customreminderemail
                        : tempType == 3
                        ? newSponsor.approval_mail
                        : newSponsor.rejection_mail
                }
                onChange={(newContent) => {
                    {
                        if (tempType == 1) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    customconfirmemail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        }
                        if (tempType == 3) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    approval_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 4) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    rejection_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 2) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    customreminderemail: newContent,
                                };
                            });
                        }
                    }
                }}
            />
        ),
        [editor, content]
    );
    console.log("email");
    // const changeReachTextValue = useMemo(
    //   () => (
    //     <JoditEditor                 config={defaultConfig}

    //       ref={editor}
    //       style={
    //         error &&
    //         (tempType == 1
    //           ? !newSponsor.customconfirmemail
    //           : !newSponsor.customreminderemail)
    //           ? { border: "1px solid red", height: "7rem" }
    //           : { height: "7rem" }
    //       }
    //       type="text"
    //       className="form-control form-control-lg form-control-solid"
    //       name={tempType == 1 ? "customconfirmemail" : "customreminderemail"}
    //       placeholder=""
    //       value={
    //         tempType == 1
    //           ? newSponsor.customconfirmemail
    //           : newSponsor.customreminderemail
    //       }
    //       onChange={(newContent) => {
    //         if (tempType == 1) {
    //           setNewSponsor({
    //             ...newSponsor,
    //             customconfirmemail: newContent,
    //             // customconfirmemail: newContent.includes("@code")
    //             //   ? newContent
    //             //   : newContent.concat("", "@code"),
    //           });
    //         } else {
    //           setNewSponsor({
    //             ...newSponsor,
    //             customreminderemail: newContent,
    //           });
    //         }
    //       }}
    //     />
    //   ),
    //   [editor, tempType]
    // );
    const sendEmail = () => {
        if (email.testemail == "" && email.type == "") {
            setError(true);
        }
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("testemail", email.testemail);
        form.append("template_type", email.type);

        dispatch(sendTestEmail(form));
    };
    const addind = () => {
        if (
            newSponsor.customconfirmemail == "" ||
            newSponsor.customconfirmsubject == "" ||
            newSponsor.customreminderemail == "" ||
            newSponsor.customremindersubject == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let body = { ...newSponsor };
            // if (tempType === 1) {
            //   //conf
            //   if (!body.customconfirmemail.includes("@code")) {
            //     body.customconfirmemail = body.customconfirmemail + "@code";
            //   }
            //   if (!body.customconfirmemail.includes("@gpslocation")) {
            //     body.customconfirmemail = body.customconfirmemail + "@gpslocation";
            //   }
            // }
            // if (tempType === 2) {
            //   //rem
            //   if (!body.customreminderemail.includes("@code")) {
            //     body.customreminderemail = body.customreminderemail + "@code";
            //   }
            //   if (!body.customreminderemail.includes("@gpslocation")) {
            //     body.customreminderemail =
            //       body.customreminderemail + "\n@gpslocation";
            //   }
            // }
            body.id = parseInt(updateFlag);

            const form = new FormData();
            for (var key in body) {
                form.append(key, body[key]);
            }

            dispatch(editEventDetails({ form, pagenumber: 0, archive: 0 }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(createEvent({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalEvent);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };

    return (
        <>
            <Modal
                isOpen={sendModal}
                onRequestClose={() => {
                    setSendModal(false);
                    setNewEmail({ type: "", testemail: "", event_id: "" });
                    setError(false);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div style={{ width: "40vw" }}>
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Send Test Email
                    </h1>
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Template
                                </span>
                                <select
                                    className="form-control form-control-lg form-control-solid"
                                    onChange={(e) => {
                                        setNewEmail({
                                            ...email,
                                            type: e.target.value,
                                        });
                                    }}
                                >
                                    <option value={2}>Reminder</option>
                                    <option value={1}>Confirmation</option>
                                    <option value={3}>Approve email</option>
                                    <option value={4}>Rejection mail</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email:
                                </span>
                                <input
                                    style={
                                        error && email.testemail
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="testemail"
                                    placeholder=""
                                    value={email.testemail}
                                    onChange={(e) =>
                                        setNewEmail({
                                            ...email,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={sendEmail}
                            disabled={isSendTestEmailLoading}
                        >
                            {isSendTestEmailLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <span>Send Email</span>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalEvent);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Sponsor
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Template
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Subject
                                </span>
                                <input
                                    style={
                                        error &&
                                        (tempType == 1
                                            ? !newSponsor.customconfirmsubject
                                            : tempType == 2
                                            ? !newSponsor.customremindersubject
                                            : tempType == 3
                                            ? !newSponsor.approval_subject
                                            : !newSponsor.rejection_subject)
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={
                                        tempType == 1
                                            ? "customconfirmsubject"
                                            : tempType == 2
                                            ? "customremindersubject"
                                            : tempType == 3
                                            ? "approval_subject"
                                            : "rejection_subject"
                                    }
                                    placeholder=""
                                    value={
                                        tempType == 1
                                            ? newSponsor.customconfirmsubject
                                            : tempType == 2
                                            ? newSponsor.customremindersubject
                                            : tempType == 3
                                            ? newSponsor.approval_subject
                                            : newSponsor.rejection_subject
                                    }
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Body
                                </span>
                                {changeReachTextValue}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isScheduleModalOpen}
                style={{
                    content: {
                        ...addModalStyles.content,
                        height: "30vh",
                        padding: "3rem",
                    },
                }}
                ariaHideApp={false}
            >
                <div
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <DateTimePickerComponent
                        value={value}
                        setValue={setValue}
                    />
                    <button
                        type="button"
                        id="kt_password_reset_submit"
                        className="save-btn fw-bolder"
                        onClick={schedule}
                        style={{ marginTop: "3rem" }}
                    >
                        {isScheduleLoading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <span>Schedule Emails</span>
                        )}
                    </button>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Emails"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Emails"}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setSendModal(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={`${send}`}
                                        ></img>
                                        <div className="create-text">
                                            Send Test Email
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Reminder
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: event?.customreminderemail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                event.id
                                                            );
                                                            setNewSponsor({
                                                                name: event.name,
                                                                ar_name:
                                                                    event.ar_name,
                                                                description:
                                                                    event.description,
                                                                ar_description:
                                                                    event.ar_description,
                                                                banner: event.banner,
                                                                location:
                                                                    event.location,
                                                                ar_location:
                                                                    event.ar_location,
                                                                company_id:
                                                                    event.company_id,
                                                                gpslocation:
                                                                    event.gpslocation,
                                                                startdate:
                                                                    event.startdate,
                                                                enddate:
                                                                    event.enddate,
                                                                customconfirmemail:
                                                                    event.customconfirmemail,
                                                                customconfirmsubject:
                                                                    event.customconfirmsubject,
                                                                customemailsender:
                                                                    event.customemailsender,
                                                                customreminderemail:
                                                                    event.customreminderemail,
                                                                customremindersubject:
                                                                    event.customremindersubject,
                                                                pre_register:
                                                                    event.pre_register,
                                                                approval_mail:
                                                                    event.approval_mail,
                                                                approval_subject:
                                                                    event.approval_subject,
                                                                rejection_mail:
                                                                    event.rejection_mail,
                                                                rejection_subject:
                                                                    event.rejection_subject,
                                                                from: event.from,
                                                                event_id:
                                                                    event.id,
                                                                to: event.to,
                                                                published:
                                                                    event.published,
                                                                logo: event.logo,
                                                                custompreregistration:
                                                                    event.custompreregistration,
                                                                customsmssenderid:
                                                                    event.customsmssenderid,
                                                                fbpixel:
                                                                    event.fbpixel,
                                                                googleanalytics:
                                                                    event.googleanalytics,
                                                                smspassword:
                                                                    event.smspassword,
                                                                smstemplate:
                                                                    event.smstemplate,
                                                                smsusername:
                                                                    event.smsusername,
                                                            });
                                                            settempType(2);
                                                            setContent(
                                                                event.customreminderemail
                                                            );
                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    <div
                                                        className="action-div"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setIsScheduleModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={reminder}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            flexDirection:
                                                                "row",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div>
                                                            {isSendToAllLoading &&
                                                            type === 2 ? (
                                                                <CircularProgress
                                                                    size={15}
                                                                    style={{
                                                                        marginRight:
                                                                            "10px",
                                                                        marginTop:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    onClick={() =>
                                                                        sendEmailToAll(
                                                                            2
                                                                        )
                                                                    }
                                                                    title="Send Email To All"
                                                                    alt="Send Email To All"
                                                                    src={
                                                                        sendAll2
                                                                    }
                                                                    style={{
                                                                        width: "1.4rem",
                                                                        marginRight:
                                                                            "10px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={del}
                                                                style={{
                                                                    width: "0.9rem",
                                                                }}
                                                            ></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Confirmation
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: event.customconfirmemail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                event.id
                                                            );
                                                            setNewSponsor({
                                                                name: event.name,
                                                                ar_name:
                                                                    event.ar_name,
                                                                description:
                                                                    event.description,
                                                                ar_description:
                                                                    event.ar_description,
                                                                banner: event.banner,
                                                                location:
                                                                    event.location,
                                                                ar_location:
                                                                    event.ar_location,
                                                                company_id:
                                                                    event.company_id,
                                                                gpslocation:
                                                                    event.gpslocation,
                                                                startdate:
                                                                    event.startdate,
                                                                enddate:
                                                                    event.enddate,
                                                                customconfirmemail:
                                                                    event.customconfirmemail,
                                                                customconfirmsubject:
                                                                    event.customconfirmsubject,
                                                                customemailsender:
                                                                    event.customemailsender,
                                                                customreminderemail:
                                                                    event.customreminderemail,
                                                                customremindersubject:
                                                                    event.customremindersubject,
                                                                approval_mail:
                                                                    event.approval_mail,
                                                                pre_register:
                                                                    event.pre_register,

                                                                approval_subject:
                                                                    event.approval_subject,
                                                                rejection_mail:
                                                                    event.rejection_mail,
                                                                rejection_subject:
                                                                    event.rejection_subject,
                                                                from: event.from,
                                                                to: event.to,
                                                                logo: event.logo,
                                                                custompreregistration:
                                                                    event.custompreregistration,
                                                                customsmssenderid:
                                                                    event.customsmssenderid,
                                                                fbpixel:
                                                                    event.fbpixel,
                                                                event_id:
                                                                    event.id,
                                                                published:
                                                                    event.published,
                                                                googleanalytics:
                                                                    event.googleanalytics,
                                                                smspassword:
                                                                    event.smspassword,
                                                                smstemplate:
                                                                    event.smstemplate,
                                                                smsusername:
                                                                    event.smsusername,
                                                            });
                                                            settempType(1);
                                                            setContent(
                                                                event.customconfirmemail
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 1 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        1
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Approval
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: event.approval_mail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                event.id
                                                            );
                                                            setNewSponsor({
                                                                name: event.name,
                                                                ar_name:
                                                                    event.ar_name,
                                                                description:
                                                                    event.description,
                                                                ar_description:
                                                                    event.ar_description,
                                                                banner: event.banner,
                                                                location:
                                                                    event.location,
                                                                ar_location:
                                                                    event.ar_location,
                                                                company_id:
                                                                    event.company_id,
                                                                gpslocation:
                                                                    event.gpslocation,
                                                                startdate:
                                                                    event.startdate,
                                                                enddate:
                                                                    event.enddate,
                                                                customconfirmemail:
                                                                    event.customconfirmemail,
                                                                customconfirmsubject:
                                                                    event.customconfirmsubject,
                                                                customemailsender:
                                                                    event.customemailsender,
                                                                customreminderemail:
                                                                    event.customreminderemail,
                                                                customremindersubject:
                                                                    event.customremindersubject,
                                                                from: event.from,
                                                                to: event.to,
                                                                approval_mail:
                                                                    event.approval_mail,
                                                                approval_subject:
                                                                    event.approval_subject,
                                                                rejection_mail:
                                                                    event.rejection_mail,
                                                                rejection_subject:
                                                                    event.rejection_subject,
                                                                logo: event.logo,
                                                                pre_register:
                                                                    event.pre_register,

                                                                custompreregistration:
                                                                    event.custompreregistration,
                                                                customsmssenderid:
                                                                    event.customsmssenderid,
                                                                fbpixel:
                                                                    event.fbpixel,
                                                                event_id:
                                                                    event.id,
                                                                published:
                                                                    event.published,
                                                                googleanalytics:
                                                                    event.googleanalytics,
                                                                smspassword:
                                                                    event.smspassword,
                                                                smstemplate:
                                                                    event.smstemplate,
                                                                smsusername:
                                                                    event.smsusername,
                                                            });
                                                            settempType(3);
                                                            setContent(
                                                                event.approval_mail
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 3 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        3
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Rejection
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: event.rejection_mail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                event.id
                                                            );
                                                            setNewSponsor({
                                                                name: event.name,
                                                                ar_name:
                                                                    event.ar_name,
                                                                description:
                                                                    event.description,
                                                                ar_description:
                                                                    event.ar_description,
                                                                banner: event.banner,
                                                                location:
                                                                    event.location,
                                                                ar_location:
                                                                    event.ar_location,
                                                                company_id:
                                                                    event.company_id,
                                                                gpslocation:
                                                                    event.gpslocation,
                                                                startdate:
                                                                    event.startdate,
                                                                enddate:
                                                                    event.enddate,
                                                                customconfirmemail:
                                                                    event.customconfirmemail,
                                                                customconfirmsubject:
                                                                    event.customconfirmsubject,
                                                                customemailsender:
                                                                    event.customemailsender,
                                                                customreminderemail:
                                                                    event.customreminderemail,
                                                                customremindersubject:
                                                                    event.customremindersubject,
                                                                from: event.from,
                                                                to: event.to,
                                                                approval_mail:
                                                                    event.approval_mail,
                                                                approval_subject:
                                                                    event.approval_subject,
                                                                rejection_mail:
                                                                    event.rejection_mail,
                                                                rejection_subject:
                                                                    event.rejection_subject,
                                                                logo: event.logo,
                                                                pre_register:
                                                                    event.pre_register,

                                                                custompreregistration:
                                                                    event.custompreregistration,
                                                                customsmssenderid:
                                                                    event.customsmssenderid,
                                                                fbpixel:
                                                                    event.fbpixel,
                                                                event_id:
                                                                    event.id,
                                                                published:
                                                                    event.published,
                                                                googleanalytics:
                                                                    event.googleanalytics,
                                                                smspassword:
                                                                    event.smspassword,
                                                                smstemplate:
                                                                    event.smstemplate,
                                                                smsusername:
                                                                    event.smsusername,
                                                            });
                                                            settempType(4);
                                                            setContent(
                                                                event.rejection_mail
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 4 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        4
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Emails;
