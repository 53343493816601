import { createReducer } from "@reduxjs/toolkit";
import {
    getEventsDetails,
    getEventsAgenda,
    getAllEvent,
    createEvent,
    getSelectedEvent,
    getAllEventConst,
    getAllEvent2,
    SelectEvent,
    doSendEmailToAll,
    getProfile,
    editProfile,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doSendConfirmation, editTicket } from "./thunks";
const initialState = {
    totalCount: 0,
    record: {
        id: null,
        name: "",
        alias: "",
        description: "",
        location: "",
        gpslocation: "",
        logo: null,
        banner: null,
        startdate: "",
        enddate: "",
        manualqrcodes: null,
        published: "",
        company_id: "",
        created_at: "",
        updated_at: "",
        map: null,
        catalogue_link: null,
        sections: [
            {
                id: null,
                name: "",
                type: null,
                title: "",
                title_ar: null,
                description: null,
                description_ar: null,
                extra: null,
                event_id: null,
                sectionorder: null,
                created_at: "",
                updated_at: "",
            },
        ],
    },
    agenda: [
        {
            id: null,
            order: null,
            title: "",
            description: "",
            event_id: null,
            booth_id: null,
            user_id: null,
            date: "",
            created_at: "",
            updated_at: "",
            sessions: [],
        },
    ],
    total: null,
    loader: false,
    ticketTypes: [
        {
            id: null,
            name: "",
            name_ar: "",
            description: "",
            description_ar: "",
            type: null,
            servicefees_type: null,
            price: null,
            price_override: null,
            quantity: null,
            soldquantity: null,
            needapproval: 0,
            isprivate: 0,
            min: 1,
            max: 1,
            startduration: "",
            endduration: "",
            paymentdeadlinetype: null,
            paymentdeadline_days: null,
            infocollect_type: 1,
            event_id: null,
            fallback_ticket_id: null,
            created_at: "",
            updated_at: "",
        },
    ],
    events: [],
    eventsConst: [],
    event: [],
    profile: {},
    selectedEvent: "",
    selectedCompany: "",
    selectedEventName: "",
    number_of_pages: 0,
};

export const eventReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(editTicket.rejected, (state, { payload }) => {})

        .addCase(getEventsDetails.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(getEventsDetails.fulfilled, (state, { payload }) => {
            state.event = payload.record;
            state.totalCount = payload?.attendeesCount;

            //state.errorpayload = false;
        })
        .addCase(getEventsDetails.rejected, (state, { payload }) => {
            //state.errorpayload = false;
        })
        .addCase(getProfile.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(getProfile.fulfilled, (state, { payload }) => {
            state.profile = payload;

            //state.errorpayload = false;
        })
        .addCase(editProfile.fulfilled, (state, { payload }) => {
            toast.success("Profile Edited successfully");

            //state.errorpayload = false;
        })
        .addCase(editProfile.rejected, (state, { payload }) => {
            toast.error("Updating Failed");

            //state.errorpayload = false;
        })
        .addCase(getProfile.rejected, (state, { payload }) => {
            //state.errorpayload = false;
        })
        .addCase(getEventsAgenda.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(getEventsAgenda.fulfilled, (state, { payload }) => {
            state.agenda = payload.list;
            state.total = payload.total;
            //state.errorpayload = false;
        })
        .addCase(getEventsAgenda.rejected, (state, { payload }) => {
            //state.errorpayload = false;
        })
        .addCase(getAllEvent.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getAllEvent.fulfilled, (state, { payload }) => {
            state.events = payload.list;
            state.total = payload.total;
            state.loader = false;
            state.number_of_pages = payload.number_of_pages;

            //state.errorpayload = false;
        })
        .addCase(getAllEvent2.rejected, (state, { payload }) => {
            state.loader = false;
            //state.errorpayload = false;
        })
        .addCase(getAllEvent2.pending, (state) => {
            // state.errorpayload = true;
            //state.loader = true;
        })
        .addCase(getAllEvent2.fulfilled, (state, { payload }) => {
            state.events = payload.list;
            state.total = payload.total;
            state.loader = false;
            state.number_of_pages = payload.number_of_pages;

            //state.errorpayload = false;
        })
        .addCase(getAllEventConst.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getAllEventConst.fulfilled, (state, { payload }) => {
            state.eventsConst = payload.list;
            state.total = payload.total;
            state.loader = false;
            //state.errorpayload = false;
        })
        .addCase(getAllEventConst.rejected, (state, { payload }) => {
            state.loader = false;
            //state.errorpayload = false;
        })
        .addCase(createEvent.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(createEvent.fulfilled, (state, { payload }) => {
            //state.errorpayload = false;
        })
        .addCase(createEvent.rejected, (state, { payload }) => {
            state.loader = true;
            //state.errorpayload = false;
        })
        .addCase(getSelectedEvent.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(getSelectedEvent.fulfilled, (state, { payload }) => {
            //state.errorpayload = false;
            state.selectedCompany = payload[0];
            state.selectedEvent = payload[1];
        })
        .addCase(getSelectedEvent.rejected, (state, { payload }) => {
            state.loader = true;
            //state.errorpayload = false;
        })
        .addCase(SelectEvent.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(SelectEvent.fulfilled, (state, { payload }) => {
            //state.errorpayload = false;
            console.log(payload, "selected");
            state.selectedCompany = payload[0];
            state.selectedEvent = payload[1];
        })
        .addCase(SelectEvent.rejected, (state, { payload }) => {
            state.loader = true;
            //state.errorpayload = false;
        })
        .addCase(doSendEmailToAll.fulfilled, (state, action) => {
            toast.success("Emails successfully sent");
        })
        .addCase(doSendEmailToAll.rejected, (state, action) => {
            toast.error("Something Went Wrong, Please try again");
        })
        .addCase(doSendConfirmation.fulfilled, (state, action) => {
            console.log(action);
            toast.success("Email successfully sent");
        })
        .addCase(doSendConfirmation.rejected, (state, action) => {
            console.log(action);
            toast.error("Something Went Wrong, Please try again");
        });
});

export default eventReducer;
