import React, { useEffect, useState } from "react";

import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import expblack from "../../assets/images/black-export.svg";

import del from "../../assets/images/delete.svg";
import TextField from "@mui/material/TextField";
import Loader from "../../Components/Loader";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import {
    CreateCompany,
    DeleteCompany,
    EditCompany,
    getCompanies,
} from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
    ExportOrgAttendee,
    ExportOrgAttendeeFreq,
} from "redux/Attendee/actions";

const initalCompany = {
    name: "",
    type: null,
    payment_driver: "yevents-paymob",
};

function Organization() {
    const dispatch = useDispatch();
    const { companies, loader, total, pagecount } = useSelector(
        (state) => state.companies
    );
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState(initalCompany);
    useEffect(() => {
        let params = {
            name: searchText,
            pagenumber: pageNumber,
        };
        dispatch(getCompanies(params));
    }, [pageNumber, searchText]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        // if(newSponsor.name==""||
        // newSponsor.about==""||
        //  newSponsor.address==" "||
        //  !newSponsor.category||newSponsor.facebook==''
        //  ||!newSponsor.image||
        //  !newSponsor.logo||newSponsor.instagram==''
        //  ||newSponsor.video==''||
        //  newSponsor.website==''||newSponsor.youtube=='')
        // {
        //     setError(true)
        //     return ;
        // }
        if (newCompany.name == "" || newCompany.type == "") {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newCompany };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditCompany({ body }));
            setEventModal(false);
        } else {
            let form = { ...newCompany };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateCompany({ body }));
            setEventModal(false);
        }
        setNewCompany(initalCompany);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const exportAttende = (id) => {
        dispatch(ExportOrgAttendee({ company_id: id }));
    };
    const exportFreq = (id) => {
        dispatch(ExportOrgAttendeeFreq({ company_id: id }));
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewCompany(initalCompany);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create Organization
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Organization
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Company name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newCompany?.name}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Type
                                    </span>
                                    <select
                                        style={
                                            error && !newCompany.type
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={newCompany?.type}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={""}></option>
                                        <option value={1}>Free</option>
                                        <option value={2}>Business</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Payment Driver
                                    </span>
                                    <select
                                        style={
                                            error && !newCompany.payment_driver
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={newCompany?.payment_driver}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={"yevents-paymob"}>
                                            yevents-paymob
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Organization"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Organizations"}
                        setSearchText={setSearchText}
                        EventName={""}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        pageCount={pagecount}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table className="table table-striped gy-2 gs-7">
                                        <thead>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Event Count
                                                </th>
                                                <th
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Attendees Count
                                                </th>
                                                <th
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "15%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {companies.map((code, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.id}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.name}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.events_count}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {
                                                                code.attendees_count
                                                            }
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.type}
                                                        </th>

                                                        <th>
                                                            <span
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginRight:
                                                                        "1rem",
                                                                }}
                                                                onClick={() => {
                                                                    exportFreq(
                                                                        code.id
                                                                    );
                                                                }}
                                                            >
                                                                <svg
                                                                    style={{
                                                                        width: "1rem",
                                                                    }}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 384 512"
                                                                >
                                                                    <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM128 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 432c0-44.2 35.8-80 80-80h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16z" />
                                                                </svg>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    exportAttende(
                                                                        code.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        expblack
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    setUpdateFlag(
                                                                        code.id
                                                                    );

                                                                    setNewCompany(
                                                                        {
                                                                            name: code.name,
                                                                            type: code.type,
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    dispatch(
                                                                        DeleteCompany(
                                                                            {
                                                                                updateFlag:
                                                                                    code.id,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={del}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Company</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Organization;
