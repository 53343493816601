import { createReducer } from "@reduxjs/toolkit";
import { getAgendaDays, getEventAgenda, setIntial } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  agenda: {},
  agendaDays: [],
  loader: false,
};

export const agendaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getEventAgenda.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(setIntial.fulfilled, (state) => {
      state.agenda = {};
      state.agendaDays = [];
    })
    .addCase(getEventAgenda.fulfilled, (state, { payload }) => {
      state.agenda = payload.record;

      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getAgendaDays.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getAgendaDays.fulfilled, (state, { payload }) => {
      state.agendaDays = payload.list;

      //state.errorpayload = false;
    })
    .addCase(getEventAgenda.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default agendaReducer;
