import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";

import ApexCharts from "apexcharts";

import {
  CreateBooths,
  DeleteBooths,
  EditBooths,
  getBooths,
} from "redux/Booths/actions";
import { getAttendanceLine, getGenderReport } from "redux/Reports/actions";
const route = process.env.REACT_APP_IMAGE_URI;

function Gender() {
  const dispatch = useDispatch();
  const { loader, gender, genderDetails } = useSelector(
    (state) => state.reports
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { selectedEvent } = useSelector((state) => state.events);
  useEffect(() => {
    if (selectedEvent) {
      dispatch(getGenderReport({ id: selectedEvent }));
    }
  }, []);
  console.log(gender, "attendence");
  const getSeriesData = (arr, type) => {
    let values = [];
    if (type == "name") {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].name);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        values.push(arr[i].value);
      }
    }

    return values;
  };
  const getdetailsSeries = () => {
    let value = [
      { name: "Male", data: [] },
      {
        name: "Female",
        data: [],
      },
    ];
    for (let i = 0; i < genderDetails.length; i++) {
      for (let j = 0; j < genderDetails[i].series.length; j++) {
        if (genderDetails[i].series[j].name == "Male") {
          value[0].data.push(genderDetails[i].series[j].value);
        }
        if (genderDetails[i].series[j].name == "Female") {
          value[1].data.push(genderDetails[i].series[j].value);
        }
      }
    }
    return value;
  };
  useEffect(() => {
    if (gender && genderDetails) {
      var options = {
        chart: {
          type: "donut",
        },
        series: getSeriesData(gender, "value"),
        labels: getSeriesData(gender, "name"),
      };
      var options2 = {
        series: getdetailsSeries(),

        chart: {
          type: "bar",
          height: 350,
          width: 900,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: genderDetails.map((obj, index) => {
            return obj.name;
          }),
        },

        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    }

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    var chart1 = new ApexCharts(document.querySelector("#chart1"), options2);

    if (loader == false) {
      chart.render();
      chart1.render();
    }
    //}
  }, [loader]);

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"ReportGender"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Gender Report"}
            // setSearchText={setSearchText}
            EventName={selectedEvent}
            showUrl={true}
          ></PageHeader>
          <div className="event-page" style={{ marginInlineEnd: "0" }}>
            {!loader ? (
              <>
                <div style={{ width: "50%", margin: "5rem auto" }}>
                  <div id="chart"></div>
                </div>
                <div
                  style={{
                    margin: "5rem auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div id="chart1"></div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Gender;
