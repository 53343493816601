import React, { useEffect, useState } from "react";
import {
  createEvent,
  editEvent,
  getAllEvent,
  getEventsDetails,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/ticket.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Loader from "../../Components/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateTicketType,
  DeleteTicketType,
  EditTicketType,
  getTicketTypes,
} from "redux/TicketTypes/actions";
import { useParams } from "react-router";
import {
  CreatePackages,
  EditPackage,
  getPackages,
} from "redux/Packages/actions";

const initalTicket = {
  name: "",
  name_ar: "",
  credit: "",
  description: "",
  description_ar: "",
  type: 1,
  event_id: null,
  duration_value: null,
  duration_type: "days",
  price: 0,
};

function Packges() {
  const dispatch = useDispatch();
  const { packages, loader, total } = useSelector((state) => state.packges);
  const { event } = useSelector((state) => state.events);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();

  const [newTicket, setNewTicket] = useState(initalTicket);
  useEffect(() => {
    if (params.id) {
      dispatch(getPackages({ event_id: params.id, pagenumber: pageNumber }));
      dispatch(getEventsDetails(params.id));
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    // if(newSponsor.name==""||
    // newSponsor.about==""||
    //  newSponsor.address==" "||
    //  !newSponsor.category||newSponsor.facebook==''
    //  ||!newSponsor.image||
    //  !newSponsor.logo||newSponsor.instagram==''
    //  ||newSponsor.video==''||
    //  newSponsor.website==''||newSponsor.youtube=='')
    // {
    //     setError(true)
    //     return ;
    // }
    if (updateFlag != -1) {
      let form = { ...newTicket };
      form.event_id = parseInt(params.id);

      dispatch(EditPackage({ body: form, selectedEvent: updateFlag }));
    } else {
      console.log(newTicket);
      let form = { ...newTicket };
      form.event_id = parseInt(selectedEvent);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreatePackages({ body, selectedEvent }));
    }
    setNewTicket(initalTicket);
    setError(false);
    setUpdateFlag(-1);
    setEventModal(false);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };
  const handleToggle1 = (event) => {
    if (newTicket.type == 1) {
      setNewTicket({ ...newTicket, type: 2 });
    } else {
      setNewTicket({ ...newTicket, type: 1, price: 0 });
    }
  };
  const handleToggle2 = (event) => {
    if (newTicket.isprivate == 1) {
      setNewTicket({ ...newTicket, isprivate: 0 });
    } else {
      setNewTicket({ ...newTicket, isprivate: 1 });
    }
  };
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewTicket(initalTicket);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create New Package
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Package
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div
                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ color: "black" }} className="d-flex  fs-7 mb-2">
                  Is the package paid or free ?
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={newTicket.type == 1 ? true : false}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggle1(e);
                      }}
                      //color={event.published ? "primary" : "secondary"}
                      value="selectUploadBool"
                    />
                  </ThemeProvider>
                  {newTicket.type == 1 ? "Free" : "Paid"}
                </div>
              </div>
              {/* <div
                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ color: "black" }} className="d-flex  fs-7 mb-2">
                  Is the ticket require code ?
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={newTicket.isprivate == 0 ? true : false}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggle2(e);
                      }}
                      //color={event.published ? "primary" : "secondary"}
                      value="selectUploadBool"
                    />
                    {newTicket.isprivate == 0 ? "No" : "Yes"}
                  </ThemeProvider>
                </div>
              </div> */}
            </div>
            <div className="row mb-5">
              <div className="col">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Package name
                </span>
                <input
                  style={
                    error && !newTicket.name ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="name"
                  placeholder=""
                  value={newTicket?.name}
                  onChange={(e) =>
                    setNewTicket({
                      ...newTicket,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              {event.supports_arabic == 1 && (
                <div className="col">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Arabic package name
                  </span>
                  <input
                    style={
                      error && !newTicket.name
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name_ar"
                    placeholder=""
                    value={newTicket?.name_ar}
                    onChange={(e) =>
                      setNewTicket({
                        ...newTicket,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
            {newTicket.type == 2 && (
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Price
                  </span>
                  <input
                    style={
                      error && !newTicket.price
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="price"
                    placeholder=""
                    value={newTicket?.price}
                    onChange={(e) =>
                      setNewTicket({
                        ...newTicket,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Credits
                </span>
                <input
                  style={
                    error && !newTicket.credit
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="credit"
                  placeholder=""
                  value={newTicket?.credit}
                  onChange={(e) =>
                    setNewTicket({
                      ...newTicket,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Duration
                </span>
                <div className="d-flex">
                  <input
                    style={
                      error && !newTicket.duration_value
                        ? { border: "1px solid red", margin: "0 1rem" }
                        : { margin: "0 1rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="duration_value"
                    placeholder=""
                    value={newTicket?.duration_value}
                    onChange={(e) =>
                      setNewTicket({
                        ...newTicket,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <select
                    className="form-control form-control-lg form-control-solid"
                    name="duration_type"
                    value={newTicket.duration_type}
                    onChange={(e) =>
                      setNewTicket({
                        ...newTicket,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value={"days"}>Days</option>
                    <option value={"months"}>Months</option>
                    <option value={"years"}>Years</option>
                  </select>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Package Description
                  </span>
                  <input
                    style={
                      error && !newTicket.description
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="description"
                    placeholder=""
                    value={newTicket?.description}
                    onChange={(e) =>
                      setNewTicket({
                        ...newTicket,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {event.supports_arabic == 1 && (
                <div className="row mb-5">
                  <div className="col-12">
                    <span
                      className="d-flex align-items-center py-2 "
                      style={{ color: "black" }}
                    >
                      Arabic ticket Description
                    </span>
                    <input
                      style={
                        error && !newTicket.description_ar
                          ? { border: "1px solid red", height: "7rem" }
                          : { height: "7rem" }
                      }
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="description_ar"
                      placeholder=""
                      value={newTicket?.description_ar}
                      onChange={(e) =>
                        setNewTicket({
                          ...newTicket,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
            <button
              type="button"
              id="kt_password_reset_submit"
              className="save-btn fw-bolder"
              onClick={addind}
            >
              <span>Save Changes</span>
            </button>

            {updateFlag != -1 && (
              <>
                <div className="separtor"></div>
                <button
                  className="delete-btn"
                  onClick={() => {
                    dispatch(DeleteTicketType({ updateFlag, selectedEvent }));
                    setUpdateFlag(-1);
                    setEventModal(false);
                    setNewTicket(initalTicket);
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Packages"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Packages"}
            showUrl={true}
            EventName={params.id}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row main-row" style={{ margin: "0" }}>
                  <div
                    className="col-3 event-box-final"
                    onClick={() => {
                      setEventModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "2.25rem", margin: "1rem" }}
                      src={`${create}`}
                    ></img>
                    <div className="create-text">Add Packages</div>
                  </div>
                  {packages?.map((event, index) => (
                    <>
                      <div
                        className="event-box-hidden"
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUpdateFlag(event.id);
                          setNewTicket({
                            name: event.name,
                            name_ar: event.name_ar,
                            description: event.description,
                            description_ar: event.description_ar,
                            type: event.type,
                            credit: event.credit,
                            duration_type: event.duration_type,
                            duration_value: event.duration_value,

                            price: parseFloat(event.price),
                          });
                          setEventModal(true);
                        }}
                      >
                        <div>
                          <div className="ticket-status">
                            {event.type == 2
                              ? `${event.price} EGP`
                              : "Free Ticket"}
                          </div>
                          <div className="ticket-title">{event.name}</div>
                          <div className="ticket-about">
                            {event.description}
                          </div>
                          <div className="ticket-date">
                            {event.duration_value} {event.duration_type}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Packges;
