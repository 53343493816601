import { createReducer } from "@reduxjs/toolkit";
import { getSessions, getSessionsConst } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  sessions: [],
  sessionConst: [],
  loader: false,
  total: 0,
  number_of_pages: 0,
};

export const sessionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSessions.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getSessions.fulfilled, (state, { payload }) => {
      state.sessions = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.number_of_pages = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getSessionsConst.fulfilled, (state, { payload }) => {
      state.sessionConst = payload.list;

      //state.errorpayload = false;
    })
    .addCase(getSessions.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default sessionsReducer;
