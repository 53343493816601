import React from 'react'
import { Bars } from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function Loader() {
  return (
    <Bars
      height="5rem"
      width="5rem"
      color="#181c32"
      ariaLabel="bars-loading"
      wrapperStyle={{justifyContent:'center',height:'100vh'}}
      wrapperClass="flex-center"
      visible={true}
    />
  );
}

export default Loader