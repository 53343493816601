import React, { useEffect, useLayoutEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";

import ApexCharts from "apexcharts";

import { getAttendanceLine, getselectedReport } from "redux/Reports/actions";
const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
    name: "",
    about: "",
    address: "",
    logo: null,
    image: null,
    video: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    event_id: null,
};

function Attendance() {
    const dispatch = useDispatch();
    const { attendanceLine, loader, attendancedays } = useSelector(
        (state) => state.reports
    );
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [clicked, setClicked] = useState(false);
    const { selectedEvent } = useSelector((state) => state.events);
    const { totalCount } = useSelector((state) => state.events);
    const [currentDate, setCurrentDate] = useState("");
    useEffect(() => {
        if (selectedEvent) {
            dispatch(getAttendanceLine({ id: selectedEvent }));
        }
    }, []);

    const getSeriesData = (arr, type) => {
        let values = [];
        if (type == "name") {
            for (let i = 0; i < arr.length; i++) {
                values.push(arr[i].name);
            }
        } else {
            for (let i = 0; i < arr.length; i++) {
                values.push(arr[i].value);
            }
        }

        return values;
    };
    let options = {};
    var chart = "";
    useEffect(() => {
        setCurrentDate(attendancedays[0]);
    }, []);
    useEffect(() => {
        if (loader == false && attendanceLine.length > 0) {
            options = {
                series: [
                    {
                        name: attendanceLine[selectedIndex][0]?.name,
                        data: attendanceLine[selectedIndex][0]?.series
                            ? getSeriesData(
                                  attendanceLine[selectedIndex][0].series,
                                  "value"
                              )
                            : [0],
                    },
                    {
                        name: attendanceLine[selectedIndex][1]?.name,
                        data: attendanceLine[selectedIndex][1]?.series
                            ? getSeriesData(
                                  attendanceLine[selectedIndex][1].series,
                                  "value"
                              )
                            : [0],
                    },
                ],
                chart: {
                    id: "mychart",
                    height: 350,
                    width: "100%",
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: "straight",
                    dashArray: [0, 8, 5],
                },
                title: {
                    text: "Page Statistics",
                    align: "left",
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return (
                            val +
                            " - " +
                            opts.w.globals.series[opts.seriesIndex][
                                opts.dataPointIndex
                            ] +
                            ""
                        );
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6,
                    },
                },

                xaxis: {
                    categories: getSeriesData(
                        attendanceLine[selectedIndex][0].series,
                        "name"
                    ),
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    ],
                },
                grid: {
                    borderColor: "#f1f1f1",
                },
            };
            if (chart.ohYeahThisChartHasBeenRendered) {
                chart.destroy();
            } else {
                chart = new ApexCharts(
                    document.querySelector("#chart"),
                    options
                );
                if (loader == false) {
                    chart
                        .render()
                        .then(
                            () => (chart.ohYeahThisChartHasBeenRendered = true)
                        );
                }
            }
        }
    }, [loader, selectedIndex]);

    return (
        <>
            <div className="aside-div">
                <AsideMenu selected={"ReportAttend"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Attendance Report"}
                        // setSearchText={setSearchText}
                        showUrl={true}
                        EventName={selectedEvent}
                        currentDate={currentDate}
                    ></PageHeader>
                    <div
                        className="event-page"
                        style={{ marginInlineEnd: "0rem", width: "80vw" }}
                    >
                        {!loader ? (
                            attendancedays.length == 0 ? (
                                <>
                                    <div>No Charts Available</div>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "2rem",
                                            overflowY: "auto",
                                            width: "100%",
                                        }}
                                    >
                                        {attendancedays.map((day, index) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    cursor: "pointer",
                                                }}
                                                key={index}
                                                className={
                                                    selectedIndex == index
                                                        ? "dayBox-Active"
                                                        : "dayBox"
                                                }
                                                onClick={() => {
                                                    setSelectedIndex(index);
                                                    setCurrentDate(day);
                                                    dispatch(
                                                        getselectedReport(index)
                                                    );
                                                }}
                                            >
                                                {day}
                                            </div>
                                        ))}
                                    </div>
                                    <h2>Attendance Count {totalCount} </h2>
                                    <div style={{ margin: "0" }}>
                                        <div id="chart"></div>
                                    </div>
                                </>
                            )
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Attendance;
