import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { TStore } from "./store";
import assign from "lodash/assign";
import { create } from "apisauce";

export const api = create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { "Content-Type": "application/json" },
});

const initAxios = (store: TStore) => {
    console.log(store.getState().tokens, "axiostoken");

    api.axiosInstance.interceptors.request.use((config) => {
        const tokens = store.getState().tokens;
        console.log(tokens, "axiostoken");
        const authorization = `Bearer ${localStorage.getItem("token")}`;
        //alert(authorization);
        const authHeaders = { authorization };
        assign(config.headers, authHeaders);
        return config;
    });

    const injectAuthHeaders = (config: AxiosRequestConfig) => {
        const tokens = store.getState().tokens;
        const authorization = `Bearer ${localStorage.getItem("token")}`;
        const authHeaders = { authorization };
        assign(config.headers, authHeaders);
        return config;
    };

    const handleFormData = (config: AxiosRequestConfig) => {
        if (config.data instanceof FormData) {
            assign(config.headers, {
                "Content-Type": "multipart/form-data",
            });
        }
        return config;
    };

    // axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.interceptors.request.use(injectAuthHeaders);
    axios.interceptors.request.use(handleFormData);
};

export default initAxios;
