import React, { useState } from "react";
import { SubTitle, Title } from "./styles";
import { useAppDispatch } from "redux/store";
import { doCheckOtp } from "redux/Auth/thunks";
import { useLoadingSelector } from "redux/selectors";
import { useLocation, useNavigate } from "react-router";
import ReactCodeInput from "react-code-input";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const CheckOtp = () => {
  const [code, setCode] = useState("");
  const state: any = useLocation()?.state || {};
  const nav = useNavigate();
  const { email } = state;
  const dispatch = useAppDispatch();
  const isLoading = useLoadingSelector(doCheckOtp);
  const submitHandler = () => {
    dispatch(doCheckOtp({ email, code }))
      .then(unwrapResult)
      .then((res) => {
        if (!res) {
          toast.error("The Code is invalid, Please try again");
          return;
        }
        nav("/reset-password", { state: { email, code } });
      });
  };
  return (
    <div className="login-background">
      <div className="login">
        <div
          className="card shadow align-items-center mb-10 py-30"
          style={{ padding: "2.5rem" }}
        >
          <Title>Forgot Password?</Title>
          <SubTitle>A code is sent to your registered email address</SubTitle>
          <ReactCodeInput
            type="number"
            fields={6}
            inputMode="tel"
            name="code"
            onChange={(e) => setCode(e)}
          />
          <div
            style={{
              marginTop: "42px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <button className="login-btn" type="submit" onClick={submitHandler}>
              {!isLoading ? (
                "CHANGE PASSWORD"
              ) : (
                <CircularProgress size={20} color="inherit" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOtp;
