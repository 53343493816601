import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getPackages = createAsyncThunk("get/packages", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "packages",
      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getTicketTypesConst = createAsyncThunk(
  "get/ticketTypesConst",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "event/tickets/nopaginationlist",
        params: params,
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreatePackages = createAsyncThunk(
  "create/packges",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "packages",
        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success(" Packages Created successfully");
        dispatch(getPackages({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditPackage = createAsyncThunk(
  "edit/package",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "patch",
        url: `packages/${selectedEvent}`,

        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Package Edited successfully");
        dispatch(getPackages({ event_id: body.event_id, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteTicketType = createAsyncThunk(
  "delete/ticketType",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/tickets/delete/${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Ticket Type Deleted successfully");
        //dispatch(getTicketTypes({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
