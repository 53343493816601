import React, { useEffect, useMemo, useState } from "react";
import {
    createEvent,
    doScheduleEmails,
    doSendEmailToAll,
    editEventDetails,
    getEventsDetails,
    sendTestEmail,
} from "redux/Events/actions";
import AsideMenu from "../../../Components/Asidemenu2";
import PageHeader from "../../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import send from "../../../assets/images/send.svg";
import edit from "../../../assets/images/white-edit.svg";
import sendAll2 from "../../../assets/images/send3.svg";
import del from "../../../assets/images/delete.svg";
import reminder from "../../../assets/images/alarm-clock-solid.svg";
import Loader from "../../../Components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";

import { useRef } from "react";
import { useParams } from "react-router";
import { useLoadingSelector } from "redux/selectors";
import { initalEvent } from "./data";
import DateTimePickerComponent from "./components/DateTimePicker";
import dayjs from "dayjs";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { EditTicketType, getTicketType } from "redux/TicketTypes/actions";

function TicketEmails() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging

                // Determine the file type based on the response or other logic
                const fileType = response.url.match(/\.(jpg|jpeg|png|gif)$/i)
                    ? "image"
                    : "file";

                return {
                    files: [
                        {
                            path: response.url,
                            type: fileType,
                        },
                    ],
                    baseurl: "", // Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                const field = "files";
                if (data[field] && data[field].length) {
                    for (let i = 0; i < data[field].length; i++) {
                        const file = data[field][i];

                        // Insert image if it's an image
                        if (file.type === "image") {
                            this.selection.insertImage(file.path);
                        }
                        // Insert link if it's a file (non-image)
                        else if (file.type === "file") {
                            const fileName = file.path.split("/").pop(); // Get file name from path
                            this.selection.insertHTML(
                                `<a href="${file.path}" target="_blank">${fileName}</a>`
                            );
                        }
                    }
                }
            },
        },
    };

    const dispatch = useDispatch();
    const dispatch2 = useAppDispatch();
    const { ticketType, loader } = useSelector((state) => state.ticketTypes);
    console.log(ticketType);
    const { selectedEvent } = useSelector((state) => state.events);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [type, setType] = useState();
    const [email, setNewEmail] = useState({
        type: "",
        testemail: "",
        event_id: "",
    });
    const [error, setError] = useState(false);
    const [tempType, settempType] = useState(null);
    const [content, setContent] = useState();
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalEvent);
    const isSendToAllLoading = useLoadingSelector(doSendEmailToAll);
    const isSendTestEmailLoading = useLoadingSelector(sendTestEmail);
    const params = useParams();
    //schedule
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
    const isScheduleLoading = useLoadingSelector(doScheduleEmails);
    const schedule = () => {
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("date", value.format("YYYY-MM-DD HH:mm:ss"));
        dispatch2(doScheduleEmails(form))
            .then(unwrapResult)
            .then((res) => {
                if (!res) {
                    toast.error("Something went wrong, Please try again");
                }
                setIsScheduleModalOpen(false);
            });
    };
    ///////////

    useEffect(() => {
        if (params.uuid) {
            dispatch(getTicketType(params.uuid));
        }
    }, [params]);

    const sendEmailToAll = (type) => {
        setType(type);
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("type", type);
        form.append("ticket_id", params.uuid);
        dispatch(doSendEmailToAll(form));
    };
    const changeReachTextValue = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                style={
                    error &&
                    (tempType == 1
                        ? !newSponsor.customconfirmemail
                        : tempType == 2
                        ? !newSponsor.customreminderemail
                        : tempType == 3
                        ? !newSponsor.approval_mail
                        : tempType == 4
                        ? !newSponsor.rejection_mail
                        : tempType == 5
                        ? !newSponsor.pending_mail
                        : tempType == 6
                        ? !newSponsor.NewTicketAssignmentMail
                        : !newSponsor.unpaid_reminder_mail)
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name={
                    tempType == 1
                        ? "customconfirmemail"
                        : tempType == 2
                        ? "customreminderemail"
                        : tempType == 3
                        ? "approval_mail"
                        : tempType == 4
                        ? "rejection_mail"
                        : tempType == 5
                        ? "pending_mail"
                        : tempType == 6
                        ? "NewTicketAssignmentMail"
                        : "unpaid_reminder_mail"
                }
                placeholder=""
                value={
                    tempType == 1
                        ? newSponsor.customconfirmemail
                        : tempType == 2
                        ? newSponsor.customreminderemail
                        : tempType == 3
                        ? newSponsor.approval_mail
                        : tempType == 4
                        ? newSponsor.rejection_mail
                        : tempType == 5
                        ? newSponsor.pending_mail
                        : tempType == 6
                        ? newSponsor.NewTicketAssignmentMail
                        : newSponsor.unpaid_reminder_mail
                }
                onChange={(newContent) => {
                    {
                        if (tempType == 1) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    customconfirmemail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        }
                        if (tempType == 3) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    approval_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 4) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    rejection_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 5) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    pending_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 6) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    NewTicketAssignmentMail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 7) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    unpaid_reminder_mail: newContent,
                                };
                                // customconfirmemail: newContent.includes("@code")
                                //   ? newContent
                                //   : newContent.concat("", "@code"),
                            });
                        } else if (tempType == 2) {
                            setNewSponsor((prev) => {
                                return {
                                    ...prev,
                                    customreminderemail: newContent,
                                };
                            });
                        }
                    }
                }}
            />
        ),
        [editor, content]
    );
    console.log(tempType, "temptype");
    // const changeReachTextValue = useMemo(
    //   () => (
    //     <JoditEditor                 config={defaultConfig}

    //       ref={editor}
    //       style={
    //         error &&
    //         (tempType == 1
    //           ? !newSponsor.customconfirmemail
    //           : !newSponsor.customreminderemail)
    //           ? { border: "1px solid red", height: "7rem" }
    //           : { height: "7rem" }
    //       }
    //       type="text"
    //       className="form-control form-control-lg form-control-solid"
    //       name={tempType == 1 ? "customconfirmemail" : "customreminderemail"}
    //       placeholder=""
    //       value={
    //         tempType == 1
    //           ? newSponsor.customconfirmemail
    //           : newSponsor.customreminderemail
    //       }
    //       onChange={(newContent) => {
    //         if (tempType == 1) {
    //           setNewSponsor({
    //             ...newSponsor,
    //             customconfirmemail: newContent,
    //             // customconfirmemail: newContent.includes("@code")
    //             //   ? newContent
    //             //   : newContent.concat("", "@code"),
    //           });
    //         } else {
    //           setNewSponsor({
    //             ...newSponsor,
    //             customreminderemail: newContent,
    //           });
    //         }
    //       }}
    //     />
    //   ),
    //   [editor, tempType]
    // );
    const sendEmail = () => {
        console.log(tempType);
        if (!email.type) {
            toast.error("Please select template type");
            return;
        }
        if (email.testemail == "" && email.type == "") {
            setError(true);
        }
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("testemail", email.testemail);
        form.append("template_type", email.type);
        form.append("ticket_id", params.uuid);

        dispatch(sendTestEmail(form));
    };
    const addind = () => {
        if (
            newSponsor.customconfirmemail == "" ||
            newSponsor.customconfirmsubject == "" ||
            newSponsor.customreminderemail == "" ||
            newSponsor.customremindersubject == "" ||
            newSponsor.unpaid_reminder_mail == ""
        ) {
            console.log("#### test ####");
            // setError(true);
            // return;
        }
        if (updateFlag != -1) {
            console.log(newSponsor, "##########");
            let body = { ...newSponsor };
            // if (tempType === 1) {
            //   //conf
            //   if (!body.customconfirmemail.includes("@code")) {
            //     body.customconfirmemail = body.customconfirmemail + "@code";
            //   }
            //   if (!body.customconfirmemail.includes("@gpslocation")) {
            //     body.customconfirmemail = body.customconfirmemail + "@gpslocation";
            //   }
            // }
            // if (tempType === 2) {
            //   //rem
            //   if (!body.customreminderemail.includes("@code")) {
            //     body.customreminderemail = body.customreminderemail + "@code";
            //   }
            //   if (!body.customreminderemail.includes("@gpslocation")) {
            //     body.customreminderemail =
            //       body.customreminderemail + "\n@gpslocation";
            //   }
            // }
            body.id = parseInt(updateFlag);

            const form = new FormData();
            for (var key in body) {
                console.log(form[key], "########");
                form.append(key, body[key] || "");
            }

            dispatch(EditTicketType({ body: form, selectedEvent }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                console.log(form[key], "########");
                body.append(key, form[key] || "");
            }
            dispatch(createEvent({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalEvent);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    console.log(newSponsor, "############");
    return (
        <>
            <Modal
                isOpen={sendModal}
                onRequestClose={() => {
                    setSendModal(false);
                    setNewEmail({ type: "", testemail: "", event_id: "" });
                    setError(false);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div style={{ width: "40vw" }}>
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Send Test Email
                    </h1>
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Template
                                </span>
                                <select
                                    className="form-control form-control-lg form-control-solid"
                                    onChange={(e) => {
                                        setNewEmail({
                                            ...email,
                                            type: e.target.value,
                                        });
                                    }}
                                >
                                    <option value={""}>Select Template</option>
                                    <option value={2}>Reminder</option>
                                    <option value={1}>Confirmation</option>
                                    <option value={3}>Approve email</option>
                                    <option value={4}>Rejection mail</option>
                                    <option value={5}>pending mail</option>
                                    <option value={6}>Assignment Mail</option>
                                    <option value={7}>
                                        unpaid reminder mail
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email:
                                </span>
                                <input
                                    style={
                                        error && email.testemail
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="testemail"
                                    placeholder=""
                                    value={email.testemail}
                                    onChange={(e) =>
                                        setNewEmail({
                                            ...email,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={sendEmail}
                            disabled={isSendTestEmailLoading}
                        >
                            {isSendTestEmailLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <span>Send Email</span>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalEvent);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Sponsor
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Template
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Subject
                                </span>
                                <input
                                    style={
                                        error &&
                                        (tempType == 1
                                            ? !newSponsor.customconfirmsubject
                                            : tempType == 2
                                            ? !newSponsor.customremindersubject
                                            : tempType == 3
                                            ? !newSponsor.approval_subject
                                            : tempType == 4
                                            ? !newSponsor.rejection_subject
                                            : tempType == 5
                                            ? !newSponsor.pending_subject
                                            : tempType == 6
                                            ? !newSponsor.NewTicketAssignmentSubject
                                            : !newSponsor.unpaid_reminder_subject)
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={
                                        tempType == 1
                                            ? "customconfirmsubject"
                                            : tempType == 2
                                            ? "customremindersubject"
                                            : tempType == 3
                                            ? "approval_subject"
                                            : tempType == 4
                                            ? "rejection_subject"
                                            : tempType == 5
                                            ? "pending_subject"
                                            : tempType == 6
                                            ? "NewTicketAssignmentSubject"
                                            : "unpaid_reminder_subject"
                                    }
                                    placeholder=""
                                    value={
                                        tempType == 1
                                            ? newSponsor.customconfirmsubject
                                            : tempType == 2
                                            ? newSponsor.customremindersubject
                                            : tempType == 3
                                            ? newSponsor.approval_subject
                                            : tempType == 4
                                            ? newSponsor.rejection_subject
                                            : tempType == 5
                                            ? newSponsor.pending_subject
                                            : tempType == 6
                                            ? newSponsor.NewTicketAssignmentSubject
                                            : newSponsor.unpaid_reminder_subject
                                    }
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Body
                                </span>
                                {changeReachTextValue}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isScheduleModalOpen}
                style={{
                    content: {
                        ...addModalStyles.content,
                        height: "30vh",
                        padding: "3rem",
                    },
                }}
                ariaHideApp={false}
            >
                <div
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <DateTimePickerComponent
                        value={value}
                        setValue={setValue}
                    />
                    <button
                        type="button"
                        id="kt_password_reset_submit"
                        className="save-btn fw-bolder"
                        onClick={schedule}
                        style={{ marginTop: "3rem" }}
                    >
                        {isScheduleLoading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <span>Schedule Emails</span>
                        )}
                    </button>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Emails"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Emails"}
                        EventName={params.id}
                        ticketName={ticketType?.name}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader && ticketType ? (
                            <>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setSendModal(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={`${send}`}
                                        ></img>
                                        <div className="create-text">
                                            Send Test Email
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Reminder
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType?.customreminderemail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description ||
                                                                    "",
                                                                description_ar:
                                                                    ticketType.description_ar ||
                                                                    "",
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                max: ticketType.max,
                                                                pre_register:
                                                                    ticketType.pre_register,
                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval ||
                                                                    "",
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(2);
                                                            setContent(
                                                                ticketType.customreminderemail ||
                                                                    ""
                                                            );
                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    <div
                                                        className="action-div"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setIsScheduleModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={reminder}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            flexDirection:
                                                                "row",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div>
                                                            {isSendToAllLoading &&
                                                            type === 2 ? (
                                                                <CircularProgress
                                                                    size={15}
                                                                    style={{
                                                                        marginRight:
                                                                            "10px",
                                                                        marginTop:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    onClick={() =>
                                                                        sendEmailToAll(
                                                                            2
                                                                        )
                                                                    }
                                                                    title="Send Email To All"
                                                                    alt="Send Email To All"
                                                                    src={
                                                                        sendAll2
                                                                    }
                                                                    style={{
                                                                        width: "1.4rem",
                                                                        marginRight:
                                                                            "10px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={del}
                                                                style={{
                                                                    width: "0.9rem",
                                                                }}
                                                            ></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Confirmation
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType.customconfirmemail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description,
                                                                description_ar:
                                                                    ticketType.description_ar,
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                pre_register:
                                                                    ticketType.pre_register,
                                                                max: ticketType.max,
                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval,
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(1);
                                                            setContent(
                                                                ticketType.customconfirmemail
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 1 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        1
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Approval
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType.approval_mail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description,
                                                                description_ar:
                                                                    ticketType.description_ar,
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                max: ticketType.max,
                                                                pre_register:
                                                                    ticketType.pre_register,

                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval,
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(3);
                                                            setContent(
                                                                ticketType.approval_mail ||
                                                                    ""
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 3 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        3
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Rejection
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType.rejection_mail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description,
                                                                description_ar:
                                                                    ticketType.description_ar,
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                max: ticketType.max,
                                                                pre_register:
                                                                    ticketType.pre_register,

                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval,
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(4);
                                                            setContent(
                                                                ticketType.rejection_mail
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 4 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        4
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Event Pending
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType?.pending_mail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description,
                                                                description_ar:
                                                                    ticketType.description_ar,
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                max: ticketType.max,
                                                                pre_register:
                                                                    ticketType.pre_register,

                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval,
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(5);
                                                            setContent(
                                                                ticketType.pending_mail ||
                                                                    ""
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 5 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        5
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sponsor-box"
                                        style={{ display: "flex" }}
                                    >
                                        <div>
                                            <div className="email-title">
                                                Announcements
                                            </div>
                                            {eventModal != true && (
                                                <div
                                                    className="email-desc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: ticketType?.NewTicketAssignmentMail,
                                                    }}
                                                ></div>
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="action-div"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setUpdateFlag(
                                                                ticketType.id
                                                            );
                                                            setNewSponsor({
                                                                name: ticketType.name,
                                                                name_ar:
                                                                    ticketType.name_ar,
                                                                description:
                                                                    ticketType.description,
                                                                description_ar:
                                                                    ticketType.description_ar,
                                                                type: ticketType.type,
                                                                quantity:
                                                                    ticketType.quantity,
                                                                isprivate:
                                                                    ticketType.isprivate,
                                                                min: ticketType.min,
                                                                max: ticketType.max,
                                                                pre_register:
                                                                    ticketType.pre_register,

                                                                event_id:
                                                                    ticketType.event_id,
                                                                infocollect_type:
                                                                    ticketType.infocollect_type,
                                                                startduration:
                                                                    ticketType.startduration,
                                                                endduration:
                                                                    ticketType.endduration,
                                                                price: ticketType.price,
                                                                needapproval:
                                                                    ticketType.needapproval,
                                                                customconfirmemail:
                                                                    ticketType.customconfirmemail ||
                                                                    "",
                                                                customconfirmsubject:
                                                                    ticketType.customconfirmsubject ||
                                                                    "",
                                                                customemailsender:
                                                                    ticketType.customemailsender ||
                                                                    "",
                                                                customreminderemail:
                                                                    ticketType.customreminderemail ||
                                                                    "",
                                                                customremindersubject:
                                                                    ticketType.customremindersubject ||
                                                                    "",
                                                                pending_mail:
                                                                    ticketType.pending_mail ||
                                                                    "",
                                                                pending_subject:
                                                                    ticketType.pending_subject ||
                                                                    "",
                                                                approval_mail:
                                                                    ticketType.approval_mail ||
                                                                    "",
                                                                approval_subject:
                                                                    ticketType.approval_subject ||
                                                                    "",
                                                                rejection_mail:
                                                                    ticketType.rejection_mail ||
                                                                    "",
                                                                rejection_subject:
                                                                    ticketType.rejection_subject ||
                                                                    "",
                                                                NewTicketAssignmentMail:
                                                                    ticketType.NewTicketAssignmentMail ||
                                                                    "",
                                                                NewTicketAssignmentSubject:
                                                                    ticketType.NewTicketAssignmentSubject ||
                                                                    "",
                                                                unpaid_reminder_subject:
                                                                    ticketType.unpaid_reminder_subject ||
                                                                    "",
                                                                unpaid_reminder_mail:
                                                                    ticketType.unpaid_reminder_mail ||
                                                                    "",
                                                            });
                                                            settempType(6);
                                                            setContent(
                                                                ticketType.NewTicketAssignmentMail ||
                                                                    ""
                                                            );

                                                            setEventModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="separtor"></div>

                                                    {/* <div className="action-div"> */}
                                                    {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                    {/* </div> */}
                                                </div>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {isSendToAllLoading &&
                                                        type === 6 ? (
                                                            <CircularProgress
                                                                size={15}
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                onClick={() =>
                                                                    sendEmailToAll(
                                                                        6
                                                                    )
                                                                }
                                                                title="Send Email To All"
                                                                alt="Send Email To All"
                                                                src={sendAll2}
                                                                style={{
                                                                    width: "1.4rem",
                                                                    marginRight:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></img>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {ticketType?.type == 1 && (
                                        <div
                                            className="sponsor-box"
                                            style={{ display: "flex" }}
                                        >
                                            <div>
                                                <div className="email-title">
                                                    Unpaid Reminder
                                                </div>
                                                {eventModal != true && (
                                                    <div
                                                        className="email-desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html: ticketType?.unpaid_reminder_mail,
                                                        }}
                                                    ></div>
                                                )}

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="action-div"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setUpdateFlag(
                                                                    ticketType.id
                                                                );
                                                                setNewSponsor({
                                                                    name: ticketType.name,
                                                                    name_ar:
                                                                        ticketType.name_ar,
                                                                    description:
                                                                        ticketType.description,
                                                                    description_ar:
                                                                        ticketType.description_ar,
                                                                    type: ticketType.type,
                                                                    quantity:
                                                                        ticketType.quantity,
                                                                    isprivate:
                                                                        ticketType.isprivate,
                                                                    min: ticketType.min,
                                                                    max: ticketType.max,
                                                                    pre_register:
                                                                        ticketType.pre_register,

                                                                    event_id:
                                                                        ticketType.event_id,
                                                                    infocollect_type:
                                                                        ticketType.infocollect_type,
                                                                    startduration:
                                                                        ticketType.startduration,
                                                                    endduration:
                                                                        ticketType.endduration,
                                                                    price: ticketType.price,
                                                                    needapproval:
                                                                        ticketType.needapproval,
                                                                    customconfirmemail:
                                                                        ticketType.customconfirmemail ||
                                                                        "",
                                                                    customconfirmsubject:
                                                                        ticketType.customconfirmsubject ||
                                                                        "",
                                                                    customemailsender:
                                                                        ticketType.customemailsender ||
                                                                        "",
                                                                    customreminderemail:
                                                                        ticketType.customreminderemail ||
                                                                        "",
                                                                    customremindersubject:
                                                                        ticketType.customremindersubject ||
                                                                        "",
                                                                    pending_mail:
                                                                        ticketType.pending_mail ||
                                                                        "",
                                                                    pending_subject:
                                                                        ticketType.pending_subject ||
                                                                        "",
                                                                    approval_mail:
                                                                        ticketType.approval_mail ||
                                                                        "",
                                                                    approval_subject:
                                                                        ticketType.approval_subject ||
                                                                        "",
                                                                    rejection_mail:
                                                                        ticketType.rejection_mail ||
                                                                        "",
                                                                    rejection_subject:
                                                                        ticketType.rejection_subject ||
                                                                        "",
                                                                    NewTicketAssignmentMail:
                                                                        ticketType.NewTicketAssignmentMail ||
                                                                        "",
                                                                    NewTicketAssignmentSubject:
                                                                        ticketType.NewTicketAssignmentSubject ||
                                                                        "",
                                                                    unpaid_reminder_subject:
                                                                        ticketType.unpaid_reminder_subject ||
                                                                        "",
                                                                    unpaid_reminder_mail:
                                                                        ticketType.unpaid_reminder_mail ||
                                                                        "",
                                                                });
                                                                settempType(7);
                                                                setContent(
                                                                    ticketType.unpaid_reminder_mail ||
                                                                        ""
                                                                );

                                                                setEventModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={edit}
                                                                style={{
                                                                    width: "0.9rem",
                                                                }}
                                                            ></img>
                                                        </div>
                                                        <div className="separtor"></div>

                                                        {/* <div className="action-div"> */}
                                                        {/* <img
                              src={reminder}
                              style={{ width: "0.9rem" }}
                            ></img> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flexDirection:
                                                                "row",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div>
                                                            {isSendToAllLoading &&
                                                            type === 7 ? (
                                                                <CircularProgress
                                                                    size={15}
                                                                    style={{
                                                                        marginRight:
                                                                            "10px",
                                                                        marginTop:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    onClick={() =>
                                                                        sendEmailToAll(
                                                                            7
                                                                        )
                                                                    }
                                                                    title="Send Email To All"
                                                                    alt="Send Email To All"
                                                                    src={
                                                                        sendAll2
                                                                    }
                                                                    style={{
                                                                        width: "1.4rem",
                                                                        marginRight:
                                                                            "10px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={del}
                                                                style={{
                                                                    width: "0.9rem",
                                                                }}
                                                            ></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketEmails;
