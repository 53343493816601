import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from "redux/Companies/actions";
import {
  CreateSuperAdmin,
  EditSuperAdmin,
  getSuperAdmins,
} from "redux/UserRoles/actions";
import Pagintation from "../../Components/Pagintation";

const initalAdmin = {
  name: "",
  email: "",
  password: "",
  type: 1,
};

function SuperAdmin() {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);
  const { superAdmins, loader, total } = useSelector(
    (state) => state.userRoles
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newAdmin, setNewAdmin] = useState(initalAdmin);
  useEffect(() => {
    dispatch(getCompanies({ name: "" }));
    dispatch(getSuperAdmins({ pagenumber: pageNumber }));
  }, [pageNumber]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newAdmin.name == "" ||
      newAdmin.email == "" ||
      newAdmin.password == "" ||
      newAdmin.type == null
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newAdmin };
      form.id = parseInt(updateFlag);
      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(EditSuperAdmin({ body }));
      setEventModal(false);
    } else {
      let form = { ...newAdmin };

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreateSuperAdmin({ body }));
      setEventModal(false);
    }
    setNewAdmin(initalAdmin);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewAdmin(initalAdmin);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create Admin
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Admin
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Admin name
                  </span>
                  <input
                    style={
                      error && !newAdmin.name ? { border: "1px solid red" } : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    placeholder=""
                    value={newAdmin?.name}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Admin password
                  </span>
                  <input
                    style={
                      error && !newAdmin.password
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="password"
                    placeholder=""
                    value={newAdmin?.password}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Admin email
                  </span>
                  <input
                    style={
                      error && !newAdmin.email
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="email"
                    placeholder=""
                    value={newAdmin?.email}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"UserRoles"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Super Admins"}
            setSearchText={setSearchText}
            EventName={""}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6  border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "25%", color: "white" }}
                        >
                          #
                        </th>
                        <th style={{ width: "25%", color: "white" }}>name</th>
                        <th style={{ width: "25%", color: "white" }}>email</th>

                        <th
                          className="tlast"
                          style={{ width: "5%", color: "white" }}
                        >
                          Actions
                        </th>
                      </tr>

                      {superAdmins.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th style={{ color: "black" }}>{code.id}</th>
                            <th style={{ color: "black" }}>{code.name}</th>
                            <th style={{ color: "black" }}>{code.email}</th>

                            <th>
                              <span
                                onClick={() => {
                                  setUpdateFlag(code.id);

                                  setNewAdmin({
                                    name: code.name,
                                    email: code.email,
                                    password: code.password,

                                    type: code.type,
                                  });
                                  setEventModal(true);
                                }}
                              >
                                <img
                                  src={edit}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                  }}
                                ></img>
                              </span>
                              <span
                              // onClick={() => {
                              //   dispatch(
                              //     DeleteCode({
                              //       updateFlag: code.index,
                              //       selectedEvent,
                              //     })
                              //   );
                              // }}
                              >
                                <img
                                  onClick={() => {
                                    //dispatch(DeleteCode({ updateFlag, selectedEvent }));
                                    setUpdateFlag(-1);
                                    setEventModal(false);
                                  }}
                                  src={del}
                                  style={{ cursor: "pointer" }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Super Admin</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdmin;
