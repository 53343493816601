import React from "react";
const DatePicker = ({ startDate, endDate }: any) => {
    return (
        <div
            style={{
                width: "20rem",
            }}
        ></div>
    );
};

export default DatePicker;
