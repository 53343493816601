import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { CreateCompany, EditCompany } from "redux/Companies/actions";
import del from "../../assets/images/delete.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import sendEmail from "../../assets/images/send-message.png";
import exp from "../../assets/images/export.svg";
import edit from "../../assets/images/edit.svg";

import Pagintation from "../../Components/Pagintation";
import {
    CheckInUser,
    CheckOutUser,
    getCheckIn,
    getUnpaid,
} from "redux/CheckIn/actions";
import { useParams } from "react-router";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import { AttendOptionsList } from "./data";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
    ChangeTicketType,
    doSendIndividualEmail,
    doSendUnpaidReminder,
} from "redux/Events/thunks";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import EditForm from "./Form";
import {
    CreateAttende,
    DeleteUnpaid,
    ExportPerType,
} from "redux/Attendee/actions";
import useMarkAsPaid from "./useMarkAsPaid";

const initalCompany = {
    name: "",
    type: null,
    payment_driver: "yevents-paymob",
};
const initalinfo = {
    tickets: [],
};
function Unpaid() {
    const dispatch = useDispatch();
    const { unpaid, loader, total, number_of_pages } = useSelector(
        (state) => state.checkIn
    );
    const params = useParams();
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);

    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState(initalCompany);
    const [info, setInfo] = useState(initalinfo);
    const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
    const [searchParams, setSearchParams] = useSearchParams();
    const [delModal, setDelModal] = useState(false);

    const [filter, setFilter] = useState("");
    const [ticketId, setTicketId] = useState(0);
    const [selectedTicketType, setSelectedTicketType] = useState(-1);
    /////////////edit
    const [isInEditMode, setIsInEditMode] = useState();
    const [dynamicQuestions, setDynamicQuestions] = useState([]);
    const [optionIds, setOptionIds] = useState([]);
    const [ticketDetails, setTicketDetails] = useState("");

    const { MarkAsPaid } = useMarkAsPaid();
    useEffect(() => {
        setDynamicQuestions(
            ticketTypesConst
                ?.filter((item) => item.id == filter)[0]
                ?.questions.filter(
                    (item) =>
                        item.type == "radiobutton" || item.type == "checkbox"
                ) || []
        );
        setOptionIds([]);
    }, [filter]);
    useEffect(() => {
        if (params.id) {
            dispatch(
                getUnpaid({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    tickettype_id: filter,
                    search: searchText,
                    ...(optionIds.length && {
                        option_id: optionIds.map((item) => item.option_id),
                    }),
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
    }, [params, pageNumber, filter, isInEditMode, searchParams, optionIds]);
    const handleKeyDown = (e) => {
        // if (e.key === "Enter") {
        if (params?.id) {
            dispatch(
                getUnpaid({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    search: searchText,
                    tickettype_id: filter,
                    ...(optionIds.length && {
                        option_id: optionIds.map((item) => item.option_id),
                    }),
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
        // }
    };
    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const addind = () => {
        if (
            newCompany.name == "" ||
            newCompany.lname == "" ||
            newCompany.email == "" ||
            newCompany.mobileno == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newCompany };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditCompany({ body }));
            setEventModal(false);
        } else {
            let form = { ...newCompany };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            body.append("event_id", params?.id);

            dispatch(CreateAttende({ body, event_id: params.id }));
            setEventModal(false);
        }
        setNewCompany(initalCompany);
        setError(false);
        setUpdateFlag(-1);
    };
    const onButtonClick = (file) => {
        fetch(`https://frontiers.o-projects.org/storage/${file}`, {
            method: "get",
            mode: "no-cors",
            referrerPolicy: "no-referrer",
        })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a");
                aElement.setAttribute("download", `${file}`);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                URL.revokeObjectURL(href);
            });
    };
    const exportUnpaid = () => {
        if (!filter) {
            toast.error("Please select ticket type");
            return;
        }
        dispatch(
            ExportPerType({
                event_id: params.id,
                tickettype_id: filter,
                type: "unpaid",
                ...(optionIds.length && {
                    option_id: optionIds.map((item) => item.option_id),
                }),
            })
        ).then((res) => {});
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const addModalStyles3 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "30vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={detailsModal}
                onRequestClose={() => {
                    setDetailsModal(false);
                    setInfo(initalinfo);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Payment Details
                    </h1>
                    <h1 className="d-flex  card-title fw-bolder fs-1 px-5">
                        Payment Order #{info?.id}
                    </h1>
                    <h2
                        className="d-flex  card-title  fw-bolder  px-5"
                        style={{
                            fontSize: "18px",
                        }}
                    >
                        paymob order id #{info?.orderid || "pending payment"}
                    </h2>
                    <div className="flex-center">
                        <div className="row">
                            <div className="row mb-5">
                                <div
                                    className="col-12"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        className="d-flex align-items-center py-2 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Ticket Type:{" "}
                                        {info?.tickets[0]?.tickete_type?.name}
                                    </span>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Name: {info?.tickets[0]?.name}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number:{" "}
                                        {info?.tickets[0]?.mobileno}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Email: {info?.tickets[0]?.email}
                                    </span>
                                </div>
                            </div>
                            {/* <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Gender: {info?.tickets[0]?.gender}
                                    </span>
                                </div>
                            </div> */}
                            {/* <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Age: {info?.tickets[0]?.agegroup}
                                    </span>
                                </div>
                            </div> */}
                            <div>
                                {info?.questions?.map((ques, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-12">
                                            <span
                                                className="d-flex align-items-center py-1 px-5"
                                                style={{ color: "black" }}
                                            >
                                                {ques.name}:{" "}
                                                {ques.type == "text" ? (
                                                    ques.answers[0]
                                                        ?.answeredvalue
                                                ) : ques.type == "image" ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>

                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                onButtonClick(
                                                                    `${ques.answers[0]?.answeredvalue}`
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Download
                                                            </span>
                                                        </button>
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>
                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                window.open(
                                                                    `https://frontiers.o-projects.org/storage/${ques.answers[0]?.answeredvalue}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <span>Preview</span>
                                                        </button>
                                                    </div>
                                                ) : // <img
                                                //   src={`}`}
                                                // ></img>
                                                ques.type == "radiobutton" ? (
                                                    ques.answers[0]?.option
                                                        ?.name
                                                ) : ques.type == "session" ? (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.session
                                                                        .title
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                ) : (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.option
                                                                        .name
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <hr></hr>
                            <div className="table-responsive">
                                <table className="table table-striped gy-2 gs-7">
                                    <thead>
                                        <tr
                                            className="fw-bolder fs-6  border-bottom border-gray-200"
                                            style={{
                                                backgroundColor: "#2B6EC8",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <th
                                                className="tfirst"
                                                style={{
                                                    width: "20%",
                                                    color: "white",
                                                }}
                                            >
                                                Name
                                            </th>
                                            <th
                                                style={{
                                                    width: "20%",
                                                    color: "white",
                                                }}
                                            >
                                                Phone Number
                                            </th>
                                            <th
                                                className=""
                                                style={{
                                                    width: "20%",
                                                    color: "white",
                                                }}
                                            >
                                                Email
                                            </th>
                                            <th
                                                className=""
                                                style={{
                                                    width: "20%",
                                                    color: "white",
                                                }}
                                            >
                                                ticket name
                                            </th>
                                            <th
                                                className="tlast"
                                                style={{
                                                    width: "20%",
                                                    color: "white",
                                                }}
                                            >
                                                actions
                                            </th>
                                        </tr>
                                        {info?.tickets?.map((code, index) => (
                                            <React.Fragment key={index}>
                                                {isInEditMode !== code?.id ? (
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.name}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.mobileno}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.email}
                                                        </th>
                                                        <th>
                                                            <form
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    gap: "1rem",
                                                                }}
                                                                onSubmit={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        selectedTicketType ==
                                                                        -1
                                                                    ) {
                                                                        toast.error(
                                                                            "Please select ticket type"
                                                                        );
                                                                        return;
                                                                    }
                                                                    console.log(
                                                                        "submitt"
                                                                    );
                                                                    let data = {
                                                                        id: code.paymentorder_id,
                                                                        new_tickettypeid:
                                                                            selectedTicketType,
                                                                    };
                                                                    let selectedEvent =
                                                                        params.id;

                                                                    dispatch(
                                                                        ChangeTicketType(
                                                                            {
                                                                                data,
                                                                                selectedEvent,
                                                                                ticketId:
                                                                                    ticketId,
                                                                            }
                                                                        )
                                                                    )
                                                                        .finally(
                                                                            () => {
                                                                                setIsInEditMode(
                                                                                    selectedTicketType
                                                                                );
                                                                                // setDetailsModal(
                                                                                //     false
                                                                                // );
                                                                            }
                                                                        )
                                                                        .finally(
                                                                            unwrapResult
                                                                        );
                                                                }}
                                                            >
                                                                <select
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    name="type"
                                                                    style={{
                                                                        minWidth:
                                                                            "20rem",
                                                                    }}
                                                                    placeholder=""
                                                                    value={
                                                                        selectedTicketType
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedTicketType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    <option
                                                                        value="-1"
                                                                        selected
                                                                    >
                                                                        Select
                                                                        Ticket
                                                                        Type
                                                                    </option>
                                                                    {ticketTypesConst?.map(
                                                                        (
                                                                            ticket,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    ticket.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    ticket.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                                <button
                                                                    className="attendee-export"
                                                                    style={{
                                                                        alignItems:
                                                                            "center",
                                                                        display:
                                                                            "flex",
                                                                        backgroundColor:
                                                                            "#2B6EC8",
                                                                        color: "white",
                                                                        width: "4rem",
                                                                        cursor: "pointer",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    {false ? (
                                                                        <CircularProgress
                                                                            color="inherit"
                                                                            size={
                                                                                14
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            Save
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </form>
                                                        </th>
                                                        <th>
                                                            <img
                                                                onClick={() =>
                                                                    setIsInEditMode(
                                                                        code.id
                                                                    )
                                                                }
                                                                src={edit}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginRight:
                                                                        "0.7rem",
                                                                }}
                                                            />
                                                            <img
                                                                onClick={() => {
                                                                    dispatch(
                                                                        doSendIndividualEmail(
                                                                            {
                                                                                ticket_id:
                                                                                    ticketId,
                                                                                event_id:
                                                                                    params.id,
                                                                                template_type: 7,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                                src={sendEmail}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                width={15}
                                                                height={15}
                                                            ></img>
                                                        </th>
                                                    </tr>
                                                ) : (
                                                    <>
                                                        <EditForm
                                                            code={code}
                                                            setIsInEditMode={
                                                                setIsInEditMode
                                                            }
                                                            ticketDetails={
                                                                ticketDetails
                                                            }
                                                            dispatch={dispatch}
                                                            setDetailsModal={
                                                                setDetailsModal
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </thead>
                                </table>
                                <div
                                    style={{
                                        width: "fit-content",
                                        margin: "20px auto 0",
                                    }}
                                >
                                    <button
                                        type="button"
                                        id="kt_password_reset_submit"
                                        className="save-btn fw-bolder"
                                        onClick={() => {
                                            MarkAsPaid({
                                                event_id: params.id,
                                                tickettype_id: filter,
                                                pagenumber: pageNumber,
                                                paymentorder_id: info.id,
                                                searchText,
                                                ...(optionIds.length && {
                                                    option_id: optionIds.map(
                                                        (item) => item.option_id
                                                    ),
                                                }),
                                            });
                                        }}
                                    >
                                        Mark as Paid
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewCompany(initalCompany);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create User
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Organization
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newCompany?.name}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee last name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.lname
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="lname"
                                        placeholder=""
                                        value={newCompany?.lname}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Email
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.email
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="email"
                                        placeholder=""
                                        value={newCompany?.email}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.mobileno
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="mobileno"
                                        placeholder=""
                                        value={newCompany?.mobileno}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={delModal}
                onRequestClose={() => {
                    setDelModal(false);
                    updateFlag(-1);
                }}
                style={addModalStyles3}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Are you sure you want delete this attendee
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                style={{ background: "red" }}
                                onClick={() => {
                                    dispatch(
                                        DeleteUnpaid({
                                            body: updateFlag,
                                            event_id: params.id,
                                            tickettype_id: filter,
                                            pagenumber: pageNumber,
                                            search: searchText,
                                            ...(optionIds.length && {
                                                option_id: optionIds.map(
                                                    (item) => item.option_id
                                                ),
                                            }),
                                        })
                                    );
                                    setDelModal(false);

                                    updateFlag(-1);
                                }}
                            >
                                <span>Delete Attendee</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Unpaid Attendee"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Unpaid Attendee"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                        handleKeyDown={handleKeyDown}
                    ></PageHeader>
                    <div>
                        <div
                            style={{
                                display: "",
                                flexDirection: "row-reverse",
                                padding: "1rem 5rem 1rem 5rem",
                                alignItems: "center",
                            }}
                        >
                            <Pagintation
                                setPageNumber={setPageNumber}
                                pageNumber={pageNumber}
                                pageCount={number_of_pages}
                            ></Pagintation>
                            <div className="row">
                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                    <select
                                        style={
                                            error && !newCompany.type
                                                ? {
                                                      border: "1px solid red",
                                                  }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={filter}
                                        onChange={(e) =>
                                            setFilter(e.target.value)
                                        }
                                    >
                                        <option value="" disabled selected>
                                            Select Ticket Type
                                        </option>
                                        {ticketTypesConst?.map(
                                            (ticket, index) => (
                                                <option
                                                    key={index}
                                                    value={ticket.id}
                                                >
                                                    {ticket.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>

                                {dynamicQuestions.map((question, index) => (
                                    <>
                                        <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                            <select
                                                style={
                                                    error && !newCompany.type
                                                        ? {
                                                              border: "1px solid red",
                                                          }
                                                        : {}
                                                }
                                                type="text"
                                                className="form-control form-control-lg form-control-solid"
                                                name={question.id}
                                                placeholder=""
                                                value={
                                                    optionIds?.find(
                                                        (item) =>
                                                            item.id ==
                                                            question.id
                                                    )?.option_id
                                                }
                                                onChange={(e) =>
                                                    setOptionIds((prev) => {
                                                        if (
                                                            optionIds.find(
                                                                (item) =>
                                                                    item.id ==
                                                                    question.id
                                                            )
                                                        ) {
                                                            return prev.map(
                                                                (item) =>
                                                                    item.id ==
                                                                    question.id
                                                                        ? {
                                                                              id: question.id,
                                                                              option_id:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : item
                                                            );
                                                        } else {
                                                            return [
                                                                ...prev,
                                                                {
                                                                    id: question.id,
                                                                    option_id:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            ];
                                                        }
                                                    })
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                >
                                                    Select {question.name}
                                                </option>
                                                {question.options?.map(
                                                    (item, index) => (
                                                        <option
                                                            key={index}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </>
                                ))}
                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                    <div
                                        className="attendee-export"
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            backgroundColor: "#2B6EC8",
                                            color: "white",

                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            exportUnpaid();
                                        }}
                                    >
                                        {!true ? (
                                            <CircularProgress
                                                size={20}
                                                color="inherit"
                                            />
                                        ) : (
                                            <>
                                                <img
                                                    src={exp}
                                                    style={{
                                                        width: "0.9rem",
                                                    }}
                                                ></img>
                                                <div className="separtor"></div>
                                                <div
                                                    style={{
                                                        fontFamily: "lato-bold",
                                                    }}
                                                >
                                                    EXPORT
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col attendee-btn">
                                <select
                                    style={
                                        error && !newCompany.type
                                            ? {
                                                  border: "1px solid red",
                                                  width: "20rem",
                                              }
                                            : {
                                                  width: "20rem",
                                              }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="type"
                                    placeholder=""
                                    value={isAttended}
                                    onChange={(e) =>
                                        setIsAttended(e.target.value)
                                    }
                                >
                                    <option value="" disabled selected>
                                        Select if Attend
                                    </option>
                                    {AttendOptionsList?.map((ticket, index) => (
                                        <option
                                            key={index}
                                            value={ticket.value}
                                        >
                                            {ticket.name}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                        </div>
                    </div>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    className="row table-responsive"
                                    style={{ margin: "0" }}
                                >
                                    <>
                                        <table className="table table-striped gy-2 gs-7">
                                            <thead>
                                                <tr
                                                    className="fw-bolder fs-6  border-bottom border-gray-200"
                                                    style={{
                                                        backgroundColor:
                                                            "#2B6EC8",
                                                        borderRadius: "16px",
                                                    }}
                                                >
                                                    <th
                                                        className="tfirst"
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Phone Number
                                                    </th>
                                                    <th
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Email
                                                    </th>
                                                    <th
                                                        className=""
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Ticket
                                                    </th>
                                                    <th
                                                        className=""
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        className="tlast"
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        actions
                                                    </th>
                                                </tr>

                                                {(unpaid || [])?.map(
                                                    (code, index) => (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {code?.name}{" "}
                                                                    {
                                                                        code?.lname
                                                                    }
                                                                    {code?.paymenturl && (
                                                                        <button
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(
                                                                                    code?.paymenturl
                                                                                );
                                                                                toast.success(
                                                                                    "Copied to clipboard"
                                                                                );
                                                                            }}
                                                                            className="save-btn"
                                                                            style={{
                                                                                width: "fit-content",
                                                                                height: "fit-content",
                                                                                marginLeft:
                                                                                    "12px",
                                                                            }}
                                                                        >
                                                                            Copy
                                                                            payment
                                                                            url
                                                                        </button>
                                                                    )}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code?.mobileno
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code?.email
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code
                                                                            ?.tickets?.[0]
                                                                            ?.tickete_type
                                                                            ?.name
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code?.amount
                                                                    }
                                                                </th>
                                                                <th>
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            gap: "1rem",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                rightArrow
                                                                            }
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                marginRight:
                                                                                    "1rem",
                                                                            }}
                                                                            onClick={() => {
                                                                                setTicketDetails(
                                                                                    code
                                                                                );
                                                                                setInfo(
                                                                                    code
                                                                                );
                                                                                setTicketId(
                                                                                    code
                                                                                        ?.tickets[0]
                                                                                        ?.id
                                                                                );
                                                                                setSelectedTicketType(
                                                                                    code
                                                                                        ?.tickets[0]
                                                                                        ?.tickettype_id
                                                                                );
                                                                                setDetailsModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        ></img>
                                                                        <img
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            src={
                                                                                del
                                                                            }
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            onClick={() => {
                                                                                setDelModal(
                                                                                    true
                                                                                );
                                                                                setUpdateFlag(
                                                                                    code.id
                                                                                );
                                                                            }}
                                                                        ></img>
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {
                                                                            setUpdateFlag(
                                                                                code.id
                                                                            );
                                                                            setNewCompany(
                                                                                {
                                                                                    name: code.name,
                                                                                    type: code.type,
                                                                                }
                                                                            );
                                                                        }}
                                                                    ></span>
                                                                </th>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </thead>
                                        </table>
                                    </>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Unpaid;
