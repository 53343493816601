import React, { useState } from "react";
import logo from "../../assets/images/Layer 2.svg";
import backGround from "../../assets/images/Group 1379.png";
import { useDispatch } from "react-redux";
import {
    bsadminLogin,
    moderatorLogin,
    userLogin,
    usherLogin,
} from "redux/Auth/actions";
import { useNavigate } from "react-router";
function LogIn() {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [userInfo, setUserInfo] = useState({ email: "", password: "" });
    const [usher, setUsher] = useState(0);
    const signIn = () => {
        if (usher == 0) {
            dispatch(userLogin({ body: userInfo, nav }));
        } else if (usher == 1) {
            dispatch(usherLogin({ body: userInfo, nav }));
        } else if (usher == 2) {
            dispatch(bsadminLogin({ body: userInfo, nav }));
        } else if (usher == 3) {
            dispatch(moderatorLogin({ body: userInfo, nav }));
        }
    };
    return (
        <div className="login-background">
            <div className="login">
                <div
                    className="card shadow align-items-center mb-10 py-30"
                    style={{ padding: "2.5rem" }}
                >
                    <div style={{ marign: "52px", marginBottom: "2rem " }}>
                        <img src={logo} class="logo" alt="Frontiers Logo" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <label
                            className="d-block input-label"
                            style={{ marginBottom: "5px" }}
                        >
                            Email Address
                        </label>
                        <input
                            className="user-inputs"
                            value={userInfo.email}
                            onChange={(e) => {
                                setUserInfo({
                                    ...userInfo,
                                    email: e.target.value,
                                });
                            }}
                        ></input>
                    </div>
                    <div style={{ marginTop: "17px", width: "100%" }}>
                        <label
                            className="d-block input-label"
                            style={{ marginBottom: "5px" }}
                        >
                            Password
                        </label>
                        <input
                            type={"password"}
                            className="user-inputs"
                            value={userInfo.password}
                            onChange={(e) => {
                                setUserInfo({
                                    ...userInfo,
                                    password: e.target.value,
                                });
                            }}
                        ></input>
                    </div>
                    <div style={{ marginTop: "17px", width: "100%" }}>
                        <label
                            className="d-block input-label"
                            style={{ marginBottom: "5px" }}
                        >
                            User Type
                        </label>
                        <select
                            type={"password"}
                            className="user-inputs"
                            value={usher}
                            onChange={(e) => {
                                setUsher(e.target.value);
                            }}
                        >
                            <option value={0}>Admin</option>
                            <option value={1}>Usher</option>
                            <option value={2}>Booth/Sponsor Team</option>
                            <option value={3}>moderator</option>
                        </select>
                    </div>

                    <div
                        className="d-flex"
                        style={{
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "10px",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <input type={"checkbox"}></input>
                            <label style={{ margin: "0 5px" }}>
                                Remember me
                            </label>
                        </div>

                        <div
                            onClick={() => nav("/forget-password")}
                            style={{ cursor: "pointer" }}
                        >
                            <span style={{ textDecoration: "underline" }}>
                                Forget password?
                            </span>
                        </div>
                    </div>

                    <div style={{ marginTop: "52px" }}>
                        <button className="login-btn" onClick={signIn}>
                            SIGN IN
                        </button>
                    </div>
                    <div>
                        <span
                            style={{
                                textDecoration: "underline",
                                color: " #2B6EC8",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                nav("/register");
                            }}
                        >
                            Register Now
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;
