import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const doForgetPassword = createAsyncThunk<any, any, any>(
  "user/forget_password",
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await axios.post(
        "https://frontiers.o-projects.org/api/admins/forget-password",
        data
      );
      return {
        data: response.data,
      };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const doCheckOtp = createAsyncThunk<any, any, any>(
  "user/check_code",
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await axios.post(
        "https://frontiers.o-projects.org/api/admins/check-password",
        data
      );
      return {
        data: response.data,
      };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const doResetPassword = createAsyncThunk<any, any, any>(
  "user/reset_password",
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await axios.post(
        "https://frontiers.o-projects.org/api/admins/reset-password",
        data
      );
      return {
        data: response.data,
      };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
