import { createReducer } from "@reduxjs/toolkit";
import { bsadminLogin, logOut, userLogin, userRegister, usherLogin } from "redux/Auth/actions";

const initialState: { token?: any } = {
  token: null,
};

const tokenReducer = createReducer(initialState, (builder) => {
  builder.addCase(userRegister.fulfilled, (state, action) => {
    state.token = action.payload.token;
  });
  builder.addCase(userLogin.fulfilled, (state, action) => {
    state.token = action.payload.token;
  })
  builder.addCase(usherLogin.fulfilled, (state, action) => {
    state.token = action.payload.token;
  });
  builder.addCase(bsadminLogin.fulfilled, (state, action) => {
    state.token = action.payload.token;
  });
  builder.addCase(logOut.fulfilled, (state) => {
    state.token = null;
  });
});

export default tokenReducer;
