import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getTimeLine = createAsyncThunk("get/Timeline", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/booths/exhibitor-sales-timelines/attendees/list`,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
