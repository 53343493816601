import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import link from "../../assets/images/link-solid.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from "redux/Companies/actions";
import { getAdmins, CreateAdmin, EditAdmin } from "redux/UserRoles/actions";
import Pagintation from "../../Components/Pagintation";
import {
  createSocial,
  deleteEngagment,
  getSocial,
} from "redux/socialengagment/actions";
import { Link } from "react-router-dom";

const initalAdmin = {
  name: "",
  background_image: "",
  mobile_image: "",
  download_image: "",
  organization_id: "",
};

function SocialEngagment() {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);
  const { socials, loader, total } = useSelector((state) => state.social);
  const { user } = useSelector((state) => state.user);
  const { admins } = useSelector((state) => state.userRoles);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newAdmin, setNewAdmin] = useState(initalAdmin);
  useEffect(() => {
    dispatch(getCompanies({ name: "" }));
    let id = user.user?.company_id;
    dispatch(getSocial(id));
  }, [user]);
  console.log(socials);
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });
  console.log(user, "user");
  const addind = () => {
    // if (
    //   newAdmin.name == "" ||
    //   newAdmin.background_image == "" ||
    //   newAdmin.mobile_image == "" ||
    //   newAdmin.download_image == "" ||
    //   !newAdmin.company_id
    // ) {
    //   setError(true);
    //   return;
    // }
    if (updateFlag != -1) {
      let form = { ...newAdmin };
      form.id = parseInt(updateFlag);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }

      body.append("organization_name");
      dispatch(createSocial({ body }));
      setEventModal(false);
    } else {
      let form = { ...newAdmin };

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      const com = companies.find(
        ({ id }) => id === parseInt(newAdmin.organization_id)
      );
      body.append("organization_name", com?.name);
      dispatch(createSocial(body));
      setEventModal(false);
    }
    setNewAdmin(initalAdmin);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewAdmin(initalAdmin);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create Social Engagment
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Admin
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div className="row mb-5">
                <div
                  className="col-4 fv-row mb-5 fv-plugins-icon-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{ color: "black" }}
                    className="d-flex align-items-center fs-7 mb-2"
                  >
                    Background Image
                  </label>

                  {newAdmin.background_image ? (
                    <>
                      <img
                        style={{ maxWidth: "35%", height: "7rem" }}
                        src={
                          newAdmin.background_image
                            ? newAdmin.background_image?.name
                              ? URL.createObjectURL(newAdmin.background_image)
                              : `${newAdmin.background_image}`
                            : ""
                        }
                      ></img>
                      <div className="d-flex" style={{ margin: "1rem 0" }}>
                        <label className="circles">
                          <img src={edit} style={{ width: "1rem" }}></img>
                          <input
                            name="background_image"
                            value={""}
                            onChange={(e) =>
                              setNewAdmin({
                                ...newAdmin,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                            type="file"
                            style={{ display: "none", cursor: "pointer" }}
                            accept=".png, .jpg, .jpeg, .svg"
                          />
                        </label>
                        <div className="separtor"></div>
                        <div className="circles">
                          <img
                            src={del}
                            style={{ width: "0.8rem", cursor: "pointer" }}
                            onClick={() => {
                              setNewAdmin({
                                ...newAdmin,
                                background_image: null,
                              });
                            }}
                          ></img>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="upload-btn">
                      <input
                        style={
                          error && !newAdmin.background_image
                            ? { border: "1px solid red", display: "none" }
                            : { display: "none" }
                        }
                        type="file"
                        name="background_image"
                        value={""}
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(e) => {
                          setNewAdmin((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.files[0],
                          }));
                        }}
                      />
                      Upload image
                    </label>
                  )}
                </div>

                <div
                  className="col-4 fv-row mb-5 fv-plugins-icon-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{ color: "black" }}
                    className="d-flex align-items-center fs-7 mb-2"
                  >
                    Mobile Image
                  </label>

                  {newAdmin.mobile_image ? (
                    <>
                      <img
                        style={{ maxWidth: "35%", height: "7rem" }}
                        src={
                          newAdmin.mobile_image
                            ? newAdmin.mobile_image?.name
                              ? URL.createObjectURL(newAdmin.mobile_image)
                              : `${newAdmin.mobile_image}`
                            : ""
                        }
                      ></img>
                      <div className="d-flex" style={{ margin: "1rem 0" }}>
                        <label className="circles">
                          <img src={edit} style={{ width: "1rem" }}></img>
                          <input
                            name="mobile_image"
                            value={""}
                            onChange={(e) =>
                              setNewAdmin({
                                ...newAdmin,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                            type="file"
                            style={{ display: "none", cursor: "pointer" }}
                            accept=".png, .jpg, .jpeg, .svg"
                          />
                        </label>
                        <div className="separtor"></div>
                        <div className="circles">
                          <img
                            src={del}
                            style={{ width: "0.8rem", cursor: "pointer" }}
                            onClick={() => {
                              setNewAdmin({
                                ...newAdmin,
                                mobile_image: null,
                              });
                            }}
                          ></img>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="upload-btn">
                      <input
                        style={
                          error && !newAdmin.mobile_image
                            ? { border: "1px solid red", display: "none" }
                            : { display: "none" }
                        }
                        type="file"
                        name="mobile_image"
                        value={""}
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(e) => {
                          setNewAdmin((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.files[0],
                          }));
                        }}
                      />
                      Upload image
                    </label>
                  )}
                </div>

                <div
                  className="col-4 fv-row mb-5 fv-plugins-icon-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{ color: "black" }}
                    className="d-flex align-items-center fs-7 mb-2"
                  >
                    Download Image
                  </label>

                  {newAdmin.download_image ? (
                    <>
                      <img
                        style={{ maxWidth: "35%", height: "7rem" }}
                        src={
                          newAdmin.download_image
                            ? newAdmin.download_image?.name
                              ? URL.createObjectURL(newAdmin.download_image)
                              : `${newAdmin.download_image}`
                            : ""
                        }
                      ></img>
                      <div className="d-flex" style={{ margin: "1rem 0" }}>
                        <label className="circles">
                          <img src={edit} style={{ width: "1rem" }}></img>
                          <input
                            name="download_image"
                            value={""}
                            onChange={(e) =>
                              setNewAdmin({
                                ...newAdmin,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                            type="file"
                            style={{ display: "none", cursor: "pointer" }}
                            accept=".png, .jpg, .jpeg, .svg"
                          />
                        </label>
                        <div className="separtor"></div>
                        <div className="circles">
                          <img
                            src={del}
                            style={{ width: "0.8rem", cursor: "pointer" }}
                            onClick={() => {
                              setNewAdmin({
                                ...newAdmin,
                                download_image: null,
                              });
                            }}
                          ></img>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="upload-btn">
                      <input
                        style={
                          error && !newAdmin.download_image
                            ? { border: "1px solid red", display: "none" }
                            : { display: "none" }
                        }
                        type="file"
                        name="download_image"
                        value={""}
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(e) => {
                          setNewAdmin((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.files[0],
                          }));
                        }}
                      />
                      Upload image
                    </label>
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Slug
                  </span>
                  <input
                    style={
                      error && !newAdmin.name ? { border: "1px solid red" } : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    placeholder=""
                    value={newAdmin?.name}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Company
                  </span>
                  <select
                    style={
                      error && !newAdmin.organization_id
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="organization_id"
                    placeholder=""
                    value={newAdmin?.organization_id}
                    onChange={(e) =>
                      setNewAdmin({
                        ...newAdmin,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value={""}></option>
                    {companies?.map((company, index) => (
                      <option value={company.id}>{company.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"SocialEngagment"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Social Engagment"}
            setSearchText={setSearchText}
            EventName={""}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6  border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{ width: "25%", color: "white" }}
                        >
                          #
                        </th>
                        <th style={{ width: "25%", color: "white" }}>
                          Company name
                        </th>
                        <th style={{ width: "25%", color: "white" }}>Slug</th>

                        <th
                          className="tlast"
                          style={{ width: "25%", color: "white" }}
                        >
                          Actions
                        </th>
                      </tr>

                      {socials?.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th style={{ color: "black" }}>{code.id}</th>
                            <th style={{ color: "black" }}>
                              {code.organization_name}
                            </th>
                            <th style={{ color: "black" }}>
                              {code.event_name}
                            </th>

                            <th>
                              <span>
                                <img
                                  src={link}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                    width: "2rem",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      `${code.link}`,
                                      "_blank",
                                      "noreferrer"
                                    )
                                  }
                                ></img>
                              </span>
                              <span
                              // onClick={() => {
                              //   dispatch(
                              //     DeleteCode({
                              //       updateFlag: code.index,
                              //       selectedEvent,
                              //     })
                              //   );
                              // }}
                              >
                                <img
                                  src={del}
                                  onClick={() =>
                                    dispatch(
                                      deleteEngagment({
                                        id: code.id,
                                        or: user.user?.company_id,
                                      })
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Social Engagement</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialEngagment;
