import styled from "styled-components";

export const Title = styled.p`
  color: #2b6ec8;
  font-size: 2rem;
  font-weight: 600;
`;

export const SubTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  margin-top: 20px;
`;
