import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import Loader from "../../Components/Loader";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";
import {
  CreatePartners,
  DeletePartners,
  EditPartner,
  getPartners,
} from "redux/Partners/actions";
import { useParams } from "react-router";

const initalSponsor = {
  name: "",
  about: "",
  address: "",
  logo: null,
  image: null,
  video: "",
  website: "",
  facebook: "",
  instagram: "",
  youtube: "",
  event_id: null,
};
const route = process.env.REACT_APP_IMAGE_URI;

function Partners() {
  const dispatch = useDispatch();
  const { partners, loader, total, number_of_pages } = useSelector(
    (state) => state.partners
  );
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newSponsor, setNewSponsor] = useState(initalSponsor);
  const params = useParams();

  useEffect(() => {
    if (params) {
      dispatch(getPartners({ event_id: params.id, pagenumber: pageNumber }));
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });
  console.log(partners, "sponsors");

  const addind = () => {
    if (
      newSponsor.name == "" ||
      newSponsor.about == "" ||
      newSponsor.address == " " ||
      newSponsor.facebook == "" ||
      !newSponsor.image ||
      !newSponsor.logo ||
      newSponsor.instagram == "" ||
      newSponsor.video == "" ||
      newSponsor.website == "" ||
      newSponsor.youtube == ""
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newSponsor };
      form.id = parseInt(updateFlag);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(EditPartner({ body, selectedEvent: params.id }));
      setEventModal(false);
    } else {
      let form = { ...newSponsor };
      form.event_id = parseInt(params.id);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreatePartners({ body, selectedEvent: params.id }));
      setEventModal(false);
    }
    setNewSponsor(initalSponsor);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewSponsor(initalSponsor);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create New Partner
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Sponsor
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div
                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ color: "black" }}
                  className="d-flex align-items-center fs-7 mb-2"
                >
                  Partner logo
                </label>

                {newSponsor.logo ? (
                  <>
                    <img
                      style={{ maxWidth: "35%", height: "7rem" }}
                      src={
                        newSponsor.logo
                          ? newSponsor.logo?.name
                            ? URL.createObjectURL(newSponsor.logo)
                            : `${route}${newSponsor.logo}`
                          : ""
                      }
                    ></img>
                    <div className="d-flex" style={{ margin: "1rem 0" }}>
                      <label className="circles">
                        <img src={edit} style={{ width: "1rem" }}></img>
                        <input
                          name="logo"
                          value={""}
                          onChange={(e) =>
                            setNewSponsor({
                              ...newSponsor,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          style={{ display: "none", cursor: "pointer" }}
                          accept=".png, .jpg, .jpeg, .svg"
                        />
                      </label>
                      <div className="separtor"></div>
                      <div className="circles">
                        <img
                          src={del}
                          style={{ width: "0.8rem", cursor: "pointer" }}
                          onClick={() => {
                            setNewSponsor({ ...newSponsor, logo: null });
                          }}
                        ></img>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="upload-btn">
                    <input
                      style={
                        error && !newSponsor.logo
                          ? { border: "1px solid red" }
                          : {}
                      }
                      type="file"
                      style={{ display: "none" }}
                      name="logo"
                      value={""}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(e) => {
                        console.log("file", e.target.files);
                        setNewSponsor((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.files[0],
                        }));
                      }}
                    />
                    Upload image
                  </label>
                )}
                <div style={{ marginTop: "0.5rem" }}>
                  <span>Logo Dimension:160x160 px</span>
                </div>
                <div>
                  {!newSponsor.logo && error && (
                    <span style={{ color: "red" }}>Logo is required</span>
                  )}
                </div>
              </div>
              <div
                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ color: "black" }}
                  className="d-flex align-items-center fs-7 mb-2"
                >
                  Partner image
                </label>

                {newSponsor.image ? (
                  <>
                    <img
                      style={{ maxWidth: "35%", height: "7rem" }}
                      src={
                        newSponsor.image
                          ? newSponsor.image?.name
                            ? URL.createObjectURL(newSponsor.image)
                            : `${route}${newSponsor.image}`
                          : ""
                      }
                    ></img>
                    <div className="d-flex" style={{ margin: "1rem 0" }}>
                      <label className="circles">
                        <img src={edit} style={{ width: "1rem" }}></img>
                        <input
                          name="image"
                          value={""}
                          onChange={(e) =>
                            setNewSponsor({
                              ...newSponsor,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          style={{ display: "none", cursor: "pointer" }}
                          accept=".png, .jpg, .jpeg, .svg"
                        />
                      </label>
                      <div className="separtor"></div>
                      <div className="circles">
                        <img
                          src={del}
                          style={{ width: "0.8rem", cursor: "pointer" }}
                          onClick={() => {
                            setNewSponsor({ ...newSponsor, image: null });
                          }}
                        ></img>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="upload-btn">
                    <input
                      style={
                        error && !newSponsor.image
                          ? { border: "1px solid red" }
                          : {}
                      }
                      type="file"
                      style={{ display: "none" }}
                      name="image"
                      value={""}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(e) =>
                        setNewSponsor((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.files[0],
                        }))
                      }
                    />
                    Upload image
                  </label>
                )}
                <div style={{ marginTop: "0.5rem" }}>
                  <span>Cover Dimension:1349x600 px</span>
                </div>
                <div>
                  {!newSponsor.image && error && (
                    <span style={{ color: "red" }}>Cover is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Partner name
                </span>
                <input
                  style={
                    error && !newSponsor.name ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="name"
                  placeholder=""
                  value={newSponsor?.name}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Partner Address
                </span>
                <input
                  style={
                    error && !newSponsor.address
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="address"
                  placeholder=""
                  value={newSponsor?.address}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Partner video
                </span>
                <input
                  style={
                    error && !newSponsor.video
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="video"
                  placeholder=""
                  value={newSponsor?.video}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Website
                </span>
                <input
                  style={
                    error && !newSponsor.website
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="website"
                  placeholder=""
                  value={newSponsor?.website}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  YouTube Link
                </span>
                <input
                  style={
                    error && !newSponsor.youtube
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="youtube"
                  placeholder=""
                  value={newSponsor?.youtube}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Facebook Link
                </span>
                <input
                  style={
                    error && !newSponsor.facebook
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="facebook"
                  placeholder=""
                  value={newSponsor?.facebook}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Instagram Link
                </span>
                <input
                  style={
                    error && !newSponsor.instagram
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="instagram"
                  placeholder=""
                  value={newSponsor?.instagram}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Partner Description
                </span>
                <input
                  style={
                    error && !newSponsor.about
                      ? { border: "1px solid red", height: "7rem" }
                      : { height: "7rem" }
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="about"
                  placeholder=""
                  value={newSponsor?.about}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <span>* Description Limit is 255 character</span>
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-center pb-lg-0 my-10">
            <button
              type="button"
              id="kt_password_reset_submit"
              className="save-btn fw-bolder"
              onClick={addind}
            >
              <span>Save Changes</span>
            </button>
            {updateFlag != -1 && (
              <>
                <div className="separtor"></div>
                <button
                  className="delete-btn"
                  onClick={() => {
                    dispatch(
                      DeletePartners({ updateFlag, selectedEvent: params.id })
                    );

                    setUpdateFlag(-1);
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Partners"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Partners"}
            setSearchText={setSearchText}
            showUrl={true}
            EventName={params.id}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation
                    setPageNumber={setPageNumber}
                    pageCount={number_of_pages}
                  ></Pagintation>
                </div>
                <div className="row main-row" style={{ margin: "0" }}>
                  <div
                    className="col-3 event-box-final"
                    onClick={() => {
                      setEventModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "2.25rem", margin: "1rem" }}
                      src={`${create}`}
                    ></img>
                    <div className="create-text">Add Partner</div>
                  </div>
                  {partners.map((event, index) => (
                    <>
                      <div
                        className="sponsor-box"
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUpdateFlag(event.id);
                          setNewSponsor({
                            name: event.name,
                            about: event.about,
                            image: event.image,
                            address: event.address,
                            logo: event.logo,

                            event_id: event.event_id,
                            facebook: event.facebook,
                            instagram: event.instagram,
                            video: event.video,
                            website: event.website,
                            youtube: event.youtube,
                          });
                          setEventModal(true);
                        }}
                      >
                        <div
                          style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <img
                              style={{ maxWidth: "11rem" }}
                              src={`https://frontiers.o-projects.org/storage/${event.logo}`}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
