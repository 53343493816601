import React, { useEffect, useState } from "react";

import AsideMenu from "../Components/Asidemenu2";
import PageHeader from "../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";

import ApexCharts from "apexcharts";

import {
    getAttendanceLine,
    getEventOverView,
    getOverViewReport,
} from "redux/Reports/actions";
import { useParams } from "react-router";
const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
    name: "",
    about: "",
    address: "",
    logo: null,
    image: null,
    video: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    event_id: null,
};

function SelectEvent() {
    const dispatch = useDispatch();
    const { loader, overView, eventOverView } = useSelector(
        (state) => state.reports
    );
    const params = useParams();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [reportload, setreportload] = useState(false);
    const { selectedEvent } = useSelector((state) => state.events);
    useEffect(() => {
        if (params.id) {
            dispatch(getOverViewReport({ id: params.id }));
            dispatch(getEventOverView({ event_id: params.id }));
        }
    }, [params.id]);
    const getSeriesData = (arr, type) => {
        let values = [];
        if (type == "name") {
            for (let i = 0; i < arr.length; i++) {
                values.push(arr[i].name);
            }
        } else {
            for (let i = 0; i < arr.length; i++) {
                values.push(arr[i].value);
            }
        }

        return values;
    };
    useEffect(() => {
        if (loader == false && overView?.totalReport?.length > 0) {
            var options = {
                series: [
                    {
                        name: "Total Sales",
                        data: getSeriesData(
                            overView?.totalReport[0].series,
                            "value"
                        ),
                    },
                    {
                        name: "Total Registration",
                        data: getSeriesData(
                            overView?.totalReport[1].series,
                            "value"
                        ),
                    },
                ],
                chart: {
                    height: 350,
                    width: "100%",
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: "straight",
                    dashArray: [0, 8, 5],
                },
                title: {
                    text: "Total Registration/Total Count Per Day",
                    align: "left",
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return (
                            val +
                            " - " +
                            opts.w.globals.series[opts.seriesIndex][
                                opts.dataPointIndex
                            ] +
                            ""
                        );
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6,
                    },
                },

                xaxis: {
                    categories: getSeriesData(
                        overView?.totalReport[1].series,
                        "name"
                    ),
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    ],
                },
                grid: {
                    borderColor: "#f1f1f1",
                },
            };

            var chart = new ApexCharts(
                document.querySelector("#chart"),
                options
            );
            if (loader == false) {
                chart.render();
                chart.destroy();
                var chart = new ApexCharts(
                    document.querySelector("#chart"),
                    options
                );
                chart.render();
            }
            // if (eventOverView?.length > 0) {
            //       for (let i = 0; i < eventOverView.length; i++) {
            //         var options = {
            //             series: [
            //                 {
            //                     name: "Total Sales",
            //                     data: getSeriesData(
            //                         eventOverView[i]?.totalReport[0]?.series,
            //                         "value"
            //                     ),
            //                 },
            //                 {
            //                     name: "Total Registration",
            //                     data: getSeriesData(
            //                         eventOverView[i]?.totalReport[1]?.series,
            //                         "value"
            //                     ),
            //                 },
            //             ],
            //             chart: {
            //                 id: "mychart",
            //                 height: 350,
            //                 width: "100%",
            //                 type: "line",
            //                 zoom: {
            //                     enabled: false,
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: false,
            //             },
            //             stroke: {
            //                 width: [5, 7, 5],
            //                 curve: "straight",
            //                 dashArray: [0, 8, 5],
            //             },
            //             title: {
            //                 text: "Page Statistics",
            //                 align: "left",
            //             },
            //             legend: {
            //                 tooltipHoverFormatter: function (val, opts) {
            //                     return (
            //                         val +
            //                         " - " +
            //                         opts.w.globals.series[opts.seriesIndex][
            //                             opts.dataPointIndex
            //                         ] +
            //                         ""
            //                     );
            //                 },
            //             },
            //             markers: {
            //                 size: 0,
            //                 hover: {
            //                     sizeOffset: 6,
            //                 },
            //             },

            //             xaxis: {
            //                 categories: getSeriesData(
            //                     eventOverView[i]?.totalReport[1]?.series,
            //                     "name"
            //                 ),
            //             },
            //             tooltip: {
            //                 y: [
            //                     {
            //                         title: {
            //                             formatter: function (val) {
            //                                 return val;
            //                             },
            //                         },
            //                     },
            //                     {
            //                         title: {
            //                             formatter: function (val) {
            //                                 return val;
            //                             },
            //                         },
            //                     },
            //                     {
            //                         title: {
            //                             formatter: function (val) {
            //                                 return val;
            //                             },
            //                         },
            //                     },
            //                 ],
            //             },
            //             grid: {
            //                 borderColor: "#f1f1f1",
            //             },
            //         };

            //         var chart = new ApexCharts(
            //             document.querySelector(`#chart${i}`),
            //             options
            //         );
            //         if (overView) {
            //             chart.render();
            //             chart.destroy();
            //             var chart = new ApexCharts(
            //                 document.querySelector(`#chart${i}`),
            //                 options
            //             );
            //             chart.render();
            //         }
            //     }
            // }
        }
    }, [overView, eventOverView]);
    console.log(eventOverView, "#########");
    return (
        <>
            <div className="aside-div">
                <AsideMenu selected={"Events"}></AsideMenu>
                {!loader && overView ? (
                    <div
                        className="d-block main-page"
                        style={{ width: "100%" }}
                    >
                        <PageHeader
                            title={"Event Overview"}
                            setSearchText={setSearchText}
                            EventName={params.id}
                            showUrl={true}
                        ></PageHeader>
                        <div className="event-page">
                            <>
                                <div>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        fontSize: "1.5rem",
                                                        marginBottom: "1rem",
                                                    }}
                                                >
                                                    Total Number Of
                                                    Registrations:
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#00D7E1",
                                                        fontSize: "2rem",
                                                        marginBottom: "1rem",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {
                                                        overView?.total_registration
                                                    }
                                                </div>
                                            </div>
                                            {!overView?.pre_register &&
                                                overView?.type == 2 && (
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "1.5rem",
                                                                marginBottom:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            Total Number Of
                                                            Sale:
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "#00D7E1",
                                                                fontSize:
                                                                    "2rem",
                                                                marginBottom:
                                                                    "1rem",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {
                                                                overView?.total_sales
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div style={{ margin: "0" }}>
                                            <div id="chart"></div>
                                        </div>
                                    </div>
                                    {eventOverView?.length > 0 && (
                                        <>
                                            {eventOverView?.map(
                                                (event, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            marginTop: "1rem",
                                                        }}
                                                    >
                                                        <div
                                                            className=""
                                                            style={{
                                                                fontSize:
                                                                    "1.5rem",
                                                                marginBottom:
                                                                    "1rem",
                                                                color: "#00D7E1",
                                                            }}
                                                        >
                                                            {event.name}
                                                        </div>
                                                        <div
                                                            className="event-name"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                flexWrap:
                                                                    "wrap",
                                                                gap: "3rem",
                                                            }}
                                                        >
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        fontSize:
                                                                            "1.5rem",
                                                                        marginBottom:
                                                                            "1rem",
                                                                    }}
                                                                >
                                                                    Total Number
                                                                    Of
                                                                    Registrations:
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        color: "#00D7E1",
                                                                        fontSize:
                                                                            "1.8rem",
                                                                        marginBottom:
                                                                            "1rem",
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        event?.total_registration
                                                                    }
                                                                </div>
                                                            </div>
                                                            {!overView?.pre_register &&
                                                            overView?.type ==
                                                                2 ? (
                                                                ""
                                                            ) : (
                                                                <>
                                                                    {event?.type !=
                                                                        2 && (
                                                                        <div>
                                                                            <div
                                                                                style={{
                                                                                    fontSize:
                                                                                        "1.5rem",
                                                                                    marginBottom:
                                                                                        "1rem",
                                                                                }}
                                                                            >
                                                                                Total
                                                                                Number
                                                                                Of
                                                                                Sale:
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    color: "#00D7E1",
                                                                                    fontSize:
                                                                                        "1.8rem",
                                                                                    marginBottom:
                                                                                        "1rem",
                                                                                    fontWeight:
                                                                                        "bold",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    event?.total_sales
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {event?.type !=
                                                                        2 && (
                                                                        <div>
                                                                            <div
                                                                                style={{
                                                                                    fontSize:
                                                                                        "1.5rem",
                                                                                    marginBottom:
                                                                                        "1rem",
                                                                                }}
                                                                            >
                                                                                unpaid
                                                                                list:
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    color: "#00D7E1",
                                                                                    fontSize:
                                                                                        "1.8rem",
                                                                                    marginBottom:
                                                                                        "1rem",
                                                                                    fontWeight:
                                                                                        "bold",
                                                                                }}
                                                                            >
                                                                                {event?.unpaidListCount ||
                                                                                    0}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {event?.pre_register ? (
                                                                        <>
                                                                            <div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.5rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                    }}
                                                                                >
                                                                                    confirmed
                                                                                    List:
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#00D7E1",
                                                                                        fontSize:
                                                                                            "1.8rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    {event?.confirmedListCount ||
                                                                                        0}
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.5rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                    }}
                                                                                >
                                                                                    pending
                                                                                    List:
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#00D7E1",
                                                                                        fontSize:
                                                                                            "1.8rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    {event?.pendingListCount ||
                                                                                        0}
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "1.5rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                    }}
                                                                                >
                                                                                    rejected
                                                                                    List:
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#00D7E1",
                                                                                        fontSize:
                                                                                            "1.8rem",
                                                                                        marginBottom:
                                                                                            "1rem",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    {event?.rejecedListCount ||
                                                                                        0}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                margin: "0",
                                                            }}
                                                        >
                                                            <div
                                                                id={`chart${index}`}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        </div>
                    </div>
                ) : (
                    <Loader></Loader>
                )}
            </div>
        </>
    );
}

export default SelectEvent;
