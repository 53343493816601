import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { CreateCompany, EditCompany } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import { CheckInUser, CheckOutUser, getCheckIn } from "redux/CheckIn/actions";
import { useParams } from "react-router";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import { AttendOptionsList } from "./data";
import DatePicker from "./DatePicker";
import { eachDayOfInterval, format } from "date-fns";

const initalCompany = {
    name: "",
    type: null,
    payment_driver: "yevents-paymob",
};

function CheckIn() {
    const dispatch = useDispatch();
    const { companies } = useSelector((state) => state.companies);
    const { checkIn, loader, total, number_of_pages } = useSelector(
        (state) => state.checkIn
    );
    const params = useParams();
    const [search, setSearchText] = useState("");
    const [isAttended, setIsAttended] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [tickettype_id, setTickettype_id] = useState();
    const { selectedCompany, selectedEvent, event } = useSelector(
        (state) => state.events
    );
    const [eventDates, setEventDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState(initalCompany);
    const [searchby, setSearchby] = useState("name");

    useEffect(() => {
        if (event.startdate && event.enddate) {
            const datesArray = eachDayOfInterval({
                start: new Date(event.startdate),
                end: new Date(event.enddate),
            }).map((date) => format(date, "yyyy-MM-dd")); // Format each date
            setEventDates(datesArray);
            setSelectedDate(datesArray[0]);
        }
    }, [event]);
    console.log(eventDates);
    useEffect(() => {
        if (params.id) {
            dispatch(
                getCheckIn({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    tickettype_id,
                    search,
                    searchby,
                    date: selectedDate,
                    attended: isAttended,
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
    }, [params, pageNumber, tickettype_id, isAttended, selectedDate]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const handleKeyDown = (e) => {
        // if (e.key === "Enter") {
        if (params?.id) {
            dispatch(
                getCheckIn({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    search: search,
                    searchby,
                    date: selectedDate,
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
        // }
    };
    const addind = () => {
        // if(newSponsor.name==""||
        // newSponsor.about==""||
        //  newSponsor.address==" "||
        //  !newSponsor.category||newSponsor.facebook==''
        //  ||!newSponsor.image||
        //  !newSponsor.logo||newSponsor.instagram==''
        //  ||newSponsor.video==''||
        //  newSponsor.website==''||newSponsor.youtube=='')
        // {
        //     setError(true)
        //     return ;
        // }
        if (updateFlag != -1) {
            let form = { ...newCompany };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditCompany({ body }));
            setEventModal(false);
        } else {
            let form = { ...newCompany };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateCompany({ body }));
            setEventModal(false);
        }
        setNewCompany(initalCompany);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };

    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewCompany(initalCompany);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create Organization
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Organization
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Company name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newCompany?.name}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Type
                                    </span>
                                    <select
                                        style={
                                            error && !newCompany.type
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={newCompany?.type}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={""}></option>
                                        <option value={1}>Free</option>
                                        <option value={2}>Business</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Payment Driver
                                    </span>
                                    <select
                                        style={
                                            error && !newCompany.payment_driver
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={newCompany?.payment_driver}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={"yevents-paymob"}>
                                            yevents-paymob
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"CheckIn"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Check In"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                        handleKeyDown={handleKeyDown}
                        searchby={searchby}
                        setSearchby={setSearchby}
                    ></PageHeader>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                overflowY: "auto",
                                width: "80vw",
                                margin: "0 5rem 2rem 5rem",
                                padding: "1rem 0",
                            }}
                        >
                            {eventDates.map((day, index) => (
                                <div
                                    style={{
                                        display: "flex",
                                        cursor: "pointer",
                                    }}
                                    key={index}
                                    className={
                                        selectedDate == day
                                            ? "dayBox-Active"
                                            : "dayBox"
                                    }
                                    onClick={() => {
                                        setSelectedDate(day);
                                    }}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "1rem 5rem 1rem 5rem",
                                alignItems: "center",
                            }}
                        >
                            <Pagintation
                                setPageNumber={setPageNumber}
                                pageNumber={pageNumber}
                                pageCount={number_of_pages}
                            ></Pagintation>
                            <div className="col attendee-btn">
                                <select
                                    style={
                                        error && !newCompany.type
                                            ? {
                                                  border: "1px solid red",
                                                  width: "20rem",
                                              }
                                            : {
                                                  width: "20rem",
                                              }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="type"
                                    placeholder=""
                                    value={tickettype_id}
                                    onChange={(e) =>
                                        setTickettype_id(e.target.value)
                                    }
                                >
                                    <option value="" disabled selected>
                                        Select Ticket Type
                                    </option>
                                    {ticketTypesConst?.map((ticket, index) => (
                                        <option key={index} value={ticket.id}>
                                            {ticket.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col attendee-btn">
                                <select
                                    style={
                                        error && !newCompany.type
                                            ? {
                                                  border: "1px solid red",
                                                  width: "20rem",
                                              }
                                            : {
                                                  width: "20rem",
                                              }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="type"
                                    placeholder=""
                                    value={isAttended}
                                    onChange={(e) =>
                                        setIsAttended(e.target.value)
                                    }
                                >
                                    <option value="" disabled selected>
                                        Select if Attend
                                    </option>
                                    {AttendOptionsList?.map((ticket, index) => (
                                        <option
                                            key={index}
                                            value={ticket.value}
                                        >
                                            {ticket.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    className="row table-responsive"
                                    style={{ margin: "0" }}
                                >
                                    <>
                                        <table className="table table-striped gy-2 gs-7">
                                            <thead>
                                                <tr
                                                    className="fw-bolder fs-6  border-bottom border-gray-200"
                                                    style={{
                                                        backgroundColor:
                                                            "#2B6EC8",
                                                        borderRadius: "16px",
                                                    }}
                                                >
                                                    <th
                                                        className="tfirst"
                                                        style={{
                                                            width: "25%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "25%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Phone Number
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "15%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Email
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "25%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Attended Days
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "25%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        QR
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "25%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Attended
                                                    </th>
                                                    <th
                                                        className="tlast"
                                                        style={{
                                                            width: "7%",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Actions
                                                    </th>
                                                </tr>

                                                {(checkIn || [])?.map(
                                                    (code, index) => (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {code?.name}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code?.mobileno
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code?.email
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code
                                                                            ?.attendanceStatus
                                                                            ?.attended_days
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {
                                                                        code
                                                                            ?.qrcode
                                                                            ?.code
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    {code?.attended ==
                                                                        0 ||
                                                                    !code?.attended
                                                                        ? "Not attended"
                                                                        : "Attended"}
                                                                </th>
                                                                <th>
                                                                    <button
                                                                        className="save-btn fw-bolder"
                                                                        style={{
                                                                            width: "max-content",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                code?.attended ==
                                                                                    0 ||
                                                                                !code?.attended
                                                                            ) {
                                                                                dispatch(
                                                                                    CheckInUser(
                                                                                        {
                                                                                            id: code.id,
                                                                                            event_id:
                                                                                                params.id,
                                                                                            pagenumber:
                                                                                                pageNumber,
                                                                                            search,
                                                                                            searchby,
                                                                                            date: selectedDate,
                                                                                            attended:
                                                                                                isAttended,
                                                                                        }
                                                                                    )
                                                                                );
                                                                            } else {
                                                                                let param =
                                                                                    {
                                                                                        attended:
                                                                                            isAttended,
                                                                                    };
                                                                                dispatch(
                                                                                    CheckOutUser(
                                                                                        {
                                                                                            id: code.id,
                                                                                            event_id:
                                                                                                params.id,
                                                                                            pagenumber:
                                                                                                pageNumber,
                                                                                            param,
                                                                                            search,
                                                                                            searchby,
                                                                                            date: selectedDate,
                                                                                            attended:
                                                                                                isAttended,
                                                                                        }
                                                                                    )
                                                                                );
                                                                                setIsAttended(
                                                                                    1
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {code.attended ==
                                                                            0 ||
                                                                        !code.attended
                                                                            ? "Check In"
                                                                            : "Check Out"}
                                                                    </button>
                                                                </th>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </thead>
                                        </table>
                                    </>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckIn;
