export const initalEvent = {
  name: "",
  ar_name: "",
  description: "",
  ar_description: "",
  location: "",
  ar_location: "",
  company_id: "",
  gpslocation: "",
  pre_register:0,
  startdate: "",
  enddate: "",
  logo: "",
  banner: "",
  from: "",
  to: "",
  fbpixel: 123456,
  published:1,
  googleanalytics: 12345,
  customemailsender: "customemail",
customconfirmemail: "customconfirmemail",
  custompreregistration: "<html></html>",
  customsmssenderid: "LaCasaExpo",
  smstemplate: "Thank you for purchasing your ticket",
  smsusername: "hdwxNtai",
  smspassword: "BfgELGANQZ",
  customreminderemail: "<html></html>",
  customremindersubject: "Reminder for your tickets",
  customconfirmsubject: "Tickets Confirmed",
};
