import { useEffect, useState } from "react";
import searchLogo from "../assets/images/search.svg";
import Logo from "../assets/images/Layer3.svg";
import Event from "../assets/images/FontAwsome (calendar-day).svg";
import Home from "../assets/images/home.svg";
import Case from "../assets/images/case.svg";
import log from "../assets/images/logout1.svg";
import Users from "../assets/images/people.svg";
import archive from "../assets/images/archive.svg";
import navbar from "../assets/images/nav.svg";
import closenav from "../assets/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logOut } from "redux/Auth/actions";

import { Link, NavLink } from "react-router-dom";
const addModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        padding: 0,
        transform: "translate(-50%, -50%)",
    },
};

function isAfterToday(date) {
    var today = new Date();
    var mydate = new Date(`${date}`);
    today.setHours(23, 59, 59, 998);
    today.setHours(23, 59, 59, 998);
    return mydate < today;
}
const AsideMenu = ({ selected }) => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [expandQuestion, setExpandQuestion] = useState(false);
    const [eventAside, setEventAside] = useState(false);
    const { user } = useSelector((state) => state.user);

    const logout = () => {
        dispatch(logOut({ nav: nav }));
    };
    function myFunction() {
        var x = document.getElementById("kt_aside");
        var z = document.getElementById("kt_aside_logo");
        var y = document.getElementById("logo");
        if (x.style.display === "none") {
            x.style.display = "flex";
            z.style.display = "flex";
            y.style.display = "none";
        } else {
            x.style.display = "none";
            y.style.display = "flex";
        }
    }
    return (
        <>
            <div>
                <img
                    className="nav-logo"
                    id="logo"
                    src={navbar}
                    onClick={myFunction}
                ></img>
            </div>
            <div
                id="kt_aside"
                className="aside aside-dark aside-hoverable"
                style={{
                    height: "100vh",
                    overflow: "auto",
                    width: "230px",
                    position: "fixed",
                    zIndex: "99",
                }}
            >
                <div
                    className="aside-logo flex-column-auto mt-10"
                    id="kt_aside_logo"
                    style={{ justifyContent: "space-around" }}
                >
                    <NavLink
                        className={({ isActive }) => {
                            return isActive ? "activeNavLink" : "=";
                        }}
                        to="/"
                    >
                        <img
                            alt="Logo"
                            src={Logo}
                            className="logo"
                            style={{ width: "159px" }}
                        />
                    </NavLink>
                    <div
                        id="kt_aside_toggle"
                        className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                    ></div>
                    <img
                        src={closenav}
                        className="nav-logo"
                        style={{ width: "1.2rem" }}
                        onClick={myFunction}
                    ></img>
                </div>
                {eventAside ? (
                    <>
                        <div className="aside-menu flex-column-fluid">
                            <div
                                className="hover-scroll-overlay-y my-2 py-5 py-lg-8"
                                id="kt_aside_menu_wrapper"
                            >
                                <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
                                    <div
                                        onClick={() =>
                                            setExpandQuestion(!expandQuestion)
                                        }
                                        className={
                                            expandQuestion ||
                                            selected === "Engagement" ||
                                            selected === "360"
                                                ? "menu-item menu-accordion show"
                                                : "menu-item menu-accordion"
                                        }
                                    >
                                        {/* <span className="menu-link">
                      <span className="menu-icon">
                        <img
                          className="fs-3"
                          style={{ width: "0.9rem" }}
                          src={Event}
                        />
                      </span>
                      <Link to={"/organiztion/events"}>
                        <span className="menu-title">Events</span>
                      </NavLink>
                      <span className="menu-arrow"></span>
                    </span> */}
                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div
                                                style={{ border: "none" }}
                                                className="menu-item "
                                            >
                                                <a
                                                    style={{ paddingBottom: 0 }}
                                                    className={
                                                        selected ===
                                                        "Engagement"
                                                            ? "menu-link active"
                                                            : "menu-link"
                                                    }
                                                    onClick={() =>
                                                        nav("/events")
                                                    }
                                                >
                                                    <span className="menu-icon"></span>
                                                    <span className="menu-title">
                                                        Agenda
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="aside-footer flex-column-auto pt-5 pb-7"
                                        id="kt_aside_footer"
                                        onClick={logout}
                                    >
                                        <div
                                            style={{ border: "none" }}
                                            className="menu-item"
                                        >
                                            <a
                                                style={{
                                                    paddingLeft: 25,
                                                    paddingRight: 25,
                                                }}
                                                className="menu-link"
                                            >
                                                <span
                                                    style={{
                                                        justifyContent:
                                                            "flex-start",
                                                    }}
                                                    className="menu-icon"
                                                >
                                                    <img
                                                        width={15}
                                                        height={15}
                                                        className="fs-3"
                                                        src={logout}
                                                    />
                                                </span>
                                                <span
                                                    style={{ color: "white" }}
                                                    className="menu-title"
                                                >
                                                    Sign Out
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="aside-menu flex-column-fluid">
                        <div
                            className="hover-scroll-overlay-y my-2 py-5 py-lg-8"
                            id="kt_aside_menu_wrapper"
                        >
                            <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
                                <div className="menu-item">
                                    <NavLink
                                        className={({ isActive }) => {
                                            return isActive
                                                ? "activeNavLink"
                                                : "=";
                                        }}
                                        to="/"
                                    >
                                        <a
                                            className={
                                                selected === "Home"
                                                    ? "menu-link active"
                                                    : "menu-link"
                                            }
                                            //onClick={() => router.push("/surveys")}
                                        >
                                            <span className="menu-icon">
                                                <img
                                                    className="fs-3"
                                                    style={{ width: "0.9rem" }}
                                                    src={Home}
                                                />
                                            </span>
                                            <span className="menu-title">
                                                Home
                                            </span>
                                        </a>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink
                                        className={({ isActive }) => {
                                            return isActive
                                                ? "activeNavLink"
                                                : "=";
                                        }}
                                        to="/socialengagment"
                                    >
                                        <a
                                            className={
                                                selected === "SocialEngagment"
                                                    ? "menu-link active"
                                                    : "menu-link"
                                            }
                                            //onClick={() => router.push("/surveys")}
                                        >
                                            <span className="menu-icon">
                                                <img
                                                    className="fs-3"
                                                    style={{ width: "0.9rem" }}
                                                    src={Home}
                                                />
                                            </span>
                                            <span className="menu-title">
                                                Social Engagement
                                            </span>
                                        </a>
                                    </NavLink>
                                </div>
                                {user?.user?.type == 1 && (
                                    <>
                                        <div className="menu-item">
                                            <NavLink
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? "activeNavLink"
                                                        : "=";
                                                }}
                                                to="/organization"
                                            >
                                                <a
                                                    className={
                                                        selected ===
                                                        "Organization"
                                                            ? "menu-link active"
                                                            : "menu-link"
                                                    }
                                                    //onClick={() => router.push("/surveys")}
                                                >
                                                    <span className="menu-icon">
                                                        <img
                                                            className="fs-3"
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                            src={Case}
                                                        />
                                                    </span>
                                                    <span className="menu-title">
                                                        Organization
                                                    </span>
                                                </a>
                                            </NavLink>
                                        </div>

                                        <div
                                            onClick={() =>
                                                setExpandQuestion(
                                                    !expandQuestion
                                                )
                                            }
                                            className={
                                                expandQuestion ||
                                                selected === "Engagement" ||
                                                selected === "360"
                                                    ? "menu-item menu-accordion show"
                                                    : "menu-item menu-accordion"
                                            }
                                        >
                                            {/* <span
                    className="menu-link"
                    onClick={() => {
                      if (!eventAside == true) {
                        nav("/organiztion/events");
                      }
                      setEventAside(!eventAside);
                    }}
                  >
                    <span className="menu-icon">
                      <img
                        className="fs-3"
                        style={{ width: "0.9rem" }}
                        src={Event}
                      />
                    </span>

                    <span className="menu-title">Events</span>

                    <span className="menu-arrow"></span>
                  </span> */}
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                <div
                                                    style={{ border: "none" }}
                                                    className="menu-item "
                                                >
                                                    <a
                                                        style={{
                                                            paddingBottom: 0,
                                                        }}
                                                        className={
                                                            selected ===
                                                            "Engagement"
                                                                ? "menu-link active"
                                                                : "menu-link"
                                                        }
                                                    >
                                                        <span className="menu-icon"></span>
                                                        <span className="menu-title">
                                                            Agenda
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? "activeNavLink"
                                                        : "=";
                                                }}
                                                to="/user-roles"
                                            >
                                                <a
                                                    className={
                                                        selected === "UserRoles"
                                                            ? "menu-link active"
                                                            : "menu-link"
                                                    }
                                                    //onClick={() => router.push("/surveys")}
                                                >
                                                    <span className="menu-icon">
                                                        <img
                                                            className="fs-3"
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                            src={Users}
                                                        />
                                                    </span>
                                                    <span className="menu-title">
                                                        User Roles
                                                    </span>
                                                </a>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? "activeNavLink"
                                                        : "=";
                                                }}
                                                to="/archived-event"
                                            >
                                                <a
                                                    className={
                                                        selected === "Archived"
                                                            ? "menu-link active"
                                                            : "menu-link"
                                                    }
                                                    //onClick={() => router.push("/surveys")}
                                                >
                                                    <span className="menu-icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="0.9rem"
                                                            height="43.06"
                                                            fill="white"
                                                            viewBox="0 0 49.211 43.06"
                                                        >
                                                            <g
                                                                id="archive"
                                                                transform="translate(-3.6 -5.4)"
                                                            >
                                                                <path
                                                                    id="Path_4963"
                                                                    data-name="Path 4963"
                                                                    d="M9.751,5.4a6.151,6.151,0,0,0,0,12.3H46.66a6.151,6.151,0,0,0,0-12.3H9.751Z"
                                                                    transform="translate(0 0)"
                                                                />
                                                                <path
                                                                    id="Path_4964"
                                                                    data-name="Path 4964"
                                                                    d="M5.4,14.4H48.46V35.93a6.151,6.151,0,0,1-6.151,6.151H11.551A6.151,6.151,0,0,1,5.4,35.93Zm15.378,9.227a3.076,3.076,0,0,1,3.076-3.076h6.151a3.076,3.076,0,1,1,0,6.151H23.854a3.076,3.076,0,0,1-3.076-3.076Z"
                                                                    transform="translate(1.276 6.378)"
                                                                    fill-rule="evenodd"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span className="menu-title">
                                                        Archived Events
                                                    </span>
                                                </a>
                                            </NavLink>
                                        </div>
                                    </>
                                )}
                                <div className="menu-item">
                                    <NavLink
                                        className={({ isActive }) => {
                                            return isActive
                                                ? "activeNavLink"
                                                : "=";
                                        }}
                                        to="/profile"
                                    >
                                        <a
                                            className={
                                                selected === "Profile"
                                                    ? "menu-link active"
                                                    : "menu-link"
                                            }
                                            //onClick={() => router.push("/surveys")}
                                        >
                                            <span className="menu-icon">
                                                <svg
                                                    className="fs-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ width: "0.9rem" }}
                                                >
                                                    <path
                                                        style={{
                                                            fill: "white",
                                                        }}
                                                        d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="menu-title">
                                                Settings
                                            </span>
                                        </a>
                                    </NavLink>
                                </div>
                                <div
                                    className="aside-footer flex-column-auto pt-5 pb-7"
                                    id="kt_aside_footer"
                                    onClick={logout}
                                >
                                    <div
                                        style={{ border: "none" }}
                                        className="menu-item"
                                    >
                                        <a
                                            style={{
                                                paddingLeft: 25,
                                                paddingRight: 25,
                                            }}
                                            className="menu-link"
                                        >
                                            <span
                                                style={{
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                                className="menu-icon"
                                            >
                                                <img
                                                    width={15}
                                                    height={15}
                                                    className="fs-3"
                                                    src={log}
                                                />
                                            </span>
                                            <span
                                                style={{ color: "white" }}
                                                className="menu-title"
                                            >
                                                Sign Out
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AsideMenu;
