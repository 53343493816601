import { useDispatch } from "react-redux";
import { MarkAsPaid as markAsPaidApi } from "redux/Attendee/actions";

export default function useMarkAsPaid() {
    const dispatch = useDispatch();
    const MarkAsPaid = async ({
        event_id,
        tickettype_id,
        pagenumber,
        search,
        option_id,
        paymentorder_id,
        searchText,
    }) => {
        dispatch(
            markAsPaidApi({
                event_id,
                tickettype_id,
                pagenumber,
                search,
                option_id,
                paymentorder_id,
                searchText,
            })
        );
    };

    return {
        MarkAsPaid,
    };
}
