import React, { useEffect, useRef, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../../Components/Asidemenu2";
import PageHeader from "../../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Loader";
import Pagintation from "../../../Components/Pagintation";

import {
  CreateBooths,
  DeleteBooths,
  EditBooths,
  getBooths,
} from "redux/Booths/actions";
import { useNavigate, useParams, useRoutes } from "react-router";
const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
  name: "",
  about: "",
  address: "",
  logo: null,
  image: null,
  video: "",
  website: "",
  facebook: "",
  instagram: "",
  youtube: "",
  event_id: null,
};

function Boothsales() {
  const dispatch = useDispatch();
  const { booths, loader, total, number_of_pages } = useSelector(
    (state) => state.booths
  );

  const [searchText, setSearchText] = useState("");
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const params = useParams();
  const nav = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const editor = useRef(null);
  const [newSponsor, setNewSponsor] = useState(initalSponsor);

  useEffect(() => {
    if (params.id) {
      dispatch(getBooths({ event_id: params.id, pagenumber: pageNumber }));
    }
  }, [pageNumber, params.id]);

  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"Bs"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Booths"}
            setSearchText={setSearchText}
            EventName={params.id}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation
                    setPageNumber={setPageNumber}
                    pageCount={number_of_pages}
                  ></Pagintation>
                </div>
                <div className="row main-row" style={{ margin: "0" }}>
                  {booths.map((event, index) => (
                    <>
                      <div
                        className="sponsor-box"
                        //style={{ display: "flex" }}
                        onClick={() => {
                          nav(`${event.id}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            margin: "auto",
                            padding: "2rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <img
                              style={{ maxWidth: "9rem", maxHeight: "4rem" }}
                              src={`https://frontiers.o-projects.org/storage/${event.logo}`}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Boothsales;
