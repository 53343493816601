import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
export const getSponsors = createAsyncThunk("get/sponsors", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "event/sponsors/list",
      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateSponsors = createAsyncThunk(
  "create/sponsors",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/sponsors/create",
        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Sponsor Created successfully");
        dispatch(getSponsors({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditSponsors = createAsyncThunk(
  "edit/sponsors",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/sponsors/update",
        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Sponsor Edited successfully");
        dispatch(getSponsors({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteSponsors = createAsyncThunk(
  "delete/sponsors",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/sponsors/delete/${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Sponsor Deleted successfully");
        dispatch(getSponsors({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
