import React, { useState } from "react";
import logo from "../../assets/images/Layer 2.svg";
import backGround from "../../assets/images/Group 1379.png";
import { useDispatch } from "react-redux";
import { companyRegister, userLogin } from "redux/Auth/actions";
import { useNavigate } from "react-router";
function Register() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    name: "",
    orgname: "",
    mobileno: "",
    // orgtype: "",
  });
  const [error, setError] = useState(false);
  const signIn = () => {
    dispatch(companyRegister({ body: userInfo, nav }));
  };
  return (
    <div className="login-background">
      <div className="login">
        <div
          className="card shadow align-items-center mb-10 py-30"
          style={{ padding: "2.5rem" }}
        >
          <div style={{ marign: "52px", marginBottom: "2rem " }}>
            <img src={logo} class="logo" alt="Frontiers Logo" />
          </div>
          <div style={{ width: "100%" }}>
            <label
              className="d-block input-label"
              style={{ marginBottom: "5px", marginBottom: "1rem" }}
            >
              Email Address
            </label>
            <input
              className="user-inputs"
              value={userInfo.email}
              onChange={(e) => {
                setUserInfo({ ...userInfo, email: e.target.value });
              }}
            ></input>
          </div>
          <div style={{ width: "100%", marginBottom: "1rem" }}>
            <label
              className="d-block input-label"
              style={{ marginBottom: "5px" }}
            >
              Password
            </label>
            <input
              type={"password"}
              className="user-inputs"
              value={userInfo.password}
              onChange={(e) => {
                setUserInfo({ ...userInfo, password: e.target.value });
              }}
            ></input>
          </div>
          <div className="row" style={{ width: "100%", marginBottom: "1rem" }}>
            <div className="col-6">
              <label
                className="d-block input-label"
                style={{ marginBottom: "5px" }}
              >
                Name
              </label>
              <input
                className="user-inputs"
                value={userInfo.name}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, name: e.target.value });
                }}
              ></input>
            </div>
            <div className="col-6">
              <label
                className="d-block input-label"
                style={{ marginBottom: "5px" }}
              >
                Mobile Number
              </label>
              <input
                className="user-inputs"
                value={userInfo.mobileno}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, mobileno: e.target.value });
                }}
              ></input>
            </div>
          </div>

          <div className="row " style={{ width: "100%", marginBottom: "1rem" }}>
            <div className="col-12">
              <label
                className="d-block input-label"
                style={{ marginBottom: "5px" }}
              >
                Organization Name
              </label>
              <input
                className="user-inputs"
                value={userInfo.orgname}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, orgname: e.target.value });
                }}
              ></input>
            </div>
            {/* <div className="col-6">
              <span
                className="d-flex align-items-center "
                style={{ color: "black" }}
              >
                Type
              </span>
              <select
                style={
                  error && !userInfo.orgtype ? { border: "1px solid red" } : {}
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="orgtype"
                placeholder=""
                value={userInfo.orgtype}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    [e.target.name]: parseInt(e.target.value),
                  })
                }
              >
                <option value={""}></option>
                <option value={1}>Personal</option>
               
              </select>
            </div> */}
          </div>
          <div style={{ marginTop: "52px" }}>
            <button className="login-btn" onClick={signIn}>
              SIGN UP
            </button>
          </div>
          <div>
            <span
              style={{
                textDecoration: "underline",
                color: " #2B6EC8",
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/login");
              }}
            >
              Sign in
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
