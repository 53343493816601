import { createReducer } from "@reduxjs/toolkit";
import { getPackages } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  packages: [],

  loader: false,
  total: 0,
};

export const PackageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPackages.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getPackages.fulfilled, (state, { payload }) => {
      state.packages = payload.list;
      state.loader = false;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getPackages.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default PackageReducer;
