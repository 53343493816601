import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DateTimePickerComponent = ({
  value,
  setValue,
}: {
  value: any;
  setValue: any;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Date&Time picker"
        value={value}
        onChange={(date) => {
          console.log(date);
          setValue(date);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Schedule Date"
            variant='filled'
            focused
            color='info'
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerComponent;
