import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
export const createSocial = createAsyncThunk(
  "create/social",
  async (form, { dispatch }) => {
    let id = form.get("organization_id");
    try {
      const response = await axios({
        method: "post",
        url: "https://social.frontiers.events/api/v1/create",
        data: form,
      });
      dispatch(getSocial(id));
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getSocial = createAsyncThunk("get/Social", async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: `https://social.frontiers.events/api/v1/get-social-engagement/${id}`,
    });
    if (response.data.status == 1) {
      return response.data;
    } else {
      return "stoploading";
    }
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});

export const deleteEngagment = createAsyncThunk(
  "delete/BoothsSales",
  async ({ id, or }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: "https://social.frontiers.events/api/v1/delete-event/" + id + "",
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Social Engagment Deleted successfully");
        dispatch(getSocial(or));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
