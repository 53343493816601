import { createReducer } from "@reduxjs/toolkit";
import {
    bsadminLogin,
    forgetPass,
    getUserInfo,
    moderatorLogin,
    userLogin,
    userRegister,
    usherLogin,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doForgetPassword } from "./thunks";
const initialState = {
    token: "",
    user: [],

    ticket: "",
    qrcode: "",
    paymentOrder: "",
    isAuth: false,
    enteredEmail: "",
};

export const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(userRegister.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(userRegister.fulfilled, (state, { payload }) => {
            state.user = payload.user;
            //state.errorpayload = false;
            state.isAuth = true;
        })
        .addCase(userRegister.rejected, (state, { payload }) => {
            for (var key in payload?.errors) {
                toast.error(payload?.errors[key][0]);
            }
        })
        .addCase(userLogin.pending, (state) => {
            // state.errorpayload = true;
        })
        .addCase(userLogin.fulfilled, (state, { payload }) => {
            state.user = JSON.parse(localStorage.getItem("user-info"));
            state.token = localStorage.getItem("user-token");
            state.isAuth = true;
        })
        .addCase(usherLogin.fulfilled, (state, { payload }) => {
            state.user = JSON.parse(localStorage.getItem("user-info"));
            state.token = localStorage.getItem("user-token");
            state.isAuth = true;
        })
        .addCase(userLogin.rejected, (state, { payload }) => {
            for (var key in payload?.errors) {
                toast.error(payload?.errors[key][0]);
            }
        })
        .addCase(bsadminLogin.fulfilled, (state, { payload }) => {
            state.user = JSON.parse(localStorage.getItem("user-info"));
            state.token = localStorage.getItem("user-token");
            state.isAuth = true;
        })
        .addCase(moderatorLogin.fulfilled, (state, { payload }) => {
            state.user = JSON.parse(localStorage.getItem("user-info"));
            state.token = localStorage.getItem("user-token");
            state.isAuth = true;
        })
        .addCase(bsadminLogin.rejected, (state, { payload }) => {
            for (var key in payload?.errors) {
                toast.error(payload?.errors[key][0]);
            }
        })
        .addCase(usherLogin.rejected, (state, { payload }) => {
            for (var key in payload?.errors) {
                toast.error(payload?.errors[key][0]);
            }
        })
        .addCase(moderatorLogin.rejected, (state, { payload }) => {
            for (var key in payload?.errors) {
                toast.error(payload?.errors[key][0]);
            }
        })
        .addCase(forgetPass.fulfilled, (state, { payload }) => {
            state.enteredEmail = payload;
        })
        .addCase(getUserInfo, (state, { payload }) => {
            state.user = JSON.parse(localStorage.getItem("user-info"));
            state.isAuth = true;
        })
        .addCase(doForgetPassword.fulfilled, (state, action) => {})
        .addCase(doForgetPassword.rejected, (state, action) => {});
});

export default userReducer;
