import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getTicketTypes = createAsyncThunk(
    "get/ticketTypes",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: "event/tickets/list",
                params: params,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const getTicketType = createAsyncThunk("get/ticketType", async (id) => {
    try {
        const response = await axios({
            method: "get",
            url: "event/tickets/" + id + "",
        });
        return response.data;
    } catch (err) {
        //return rejectWithValue(err.response.data);
    }
});
export const getTicketTypesConst = createAsyncThunk(
    "get/ticketTypesConst",
    async (params) => {
        try {
            const response = await axios({
                method: "get",
                url: "event/tickets/list/nopagination",
                params: params,
            });
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const CreateTicketType = createAsyncThunk(
    "create/ticketType",
    async ({ body, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "post",
                url: "event/tickets/create",
                data: body,
            });

            if (response.status == 200 || response.status == 204) {
                toast.success(" Ticket Type Created successfully");
                dispatch(
                    getTicketTypes({ event_id: selectedEvent, pagenumber: 0 })
                );
            }

            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const EditTicketType = createAsyncThunk(
    "edit/ticketType",
    async ({ body, selectedEvent }, { dispatch }) => {
        console.log(body, "idd");
        try {
            const response = await axios({
                method: "post",
                url: "event/tickets/update",

                data: body,
            });
            if (response.status == 200 || response.status == 204) {
                toast.success("Ticket Type Edited successfully");
                dispatch(
                    getTicketTypes({ event_id: selectedEvent, pagenumber: 0 })
                );
                dispatch(getTicketType(body.get("id")));
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);

export const DeleteTicketType = createAsyncThunk(
    "delete/ticketType",
    async ({ updateFlag, selectedEvent }, { dispatch }) => {
        try {
            const response = await axios({
                method: "delete",
                url: `event/tickets/delete/${updateFlag}`,
            });
            if (response.status == 200 || response.status == 204) {
                toast.success("Ticket Type Deleted successfully");
                dispatch(
                    getTicketTypes({ event_id: selectedEvent, pagenumber: 0 })
                );
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
