import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getCheckIn = createAsyncThunk("get/CheckIn", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/attendances/tickets/list`,
      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});

export const getUnpaid = createAsyncThunk("get/unpaid", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/attendances/unpaid`,
      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getCheckIn2 = createAsyncThunk("get/CheckIn2", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: `event/attendances/tickets/list`,

      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CheckInUser = createAsyncThunk(
  "get/CheckInUser",
  async ({ id, event_id, pagenumber }, { dispatch }) => {
    try {
      const response = await axios({
        method: "get",
        url: `event/attendances/tickets/checkin`,

        params: { id: id },
      });
      if (response.status == 200) {
        toast.success("User Checked In successfuly");
        let params = {
          event_id: event_id,
          pagenumber: pagenumber,
        };
        dispatch(getCheckIn2(params));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CheckOutUser = createAsyncThunk(
  "get/CheckOutUser",
  async ({ id, event_id, pagenumber, param }, { dispatch }) => {
    try {
      const response = await axios({
        method: "get",
        url: `event/attendances/tickets/uncheckin`,

        params: { id: id },
      });
      if (response.status == 200) {
        toast.success("User Checked out successfuly");
        let para = {
          event_id: event_id,
          pagenumber: pagenumber,
          attended: param.attended,
        };
        dispatch(getCheckIn2(para));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
