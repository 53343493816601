import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSponsors,
  DeleteSponsors,
  EditSponsors,
  getSponsors,
} from "redux/Sponsors/actions";
import {
  CreateSpeakers,
  DeleteSpeaker,
  EditSpeaker,
  getSpeakers,
} from "redux/Speakers/actions";
import { getSessionsConst } from "redux/Sessions/actions";
import { useParams } from "react-router";
const route = process.env.REACT_APP_IMAGE_URI;
const initalSponsor = {
  name: "",
  title: "",
  session_id: "",
  avatar: null,
  fieldofwork: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  twitter: "",
  email: "",
};

function Speakers() {
  const dispatch = useDispatch();
  const { speakers, loader, total } = useSelector((state) => state.speakers);
  const { sessionConst } = useSelector((state) => state.sessions);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();
  const [newSponsor, setNewSponsor] = useState(initalSponsor);
  useEffect(() => {
    if (params.id) {
      dispatch(getSpeakers({ event_id: params.id, pagenumber: pageNumber }));

      dispatch(getSessionsConst({ event_id: params.id }));
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#00D7E1",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newSponsor.name == "" ||
      newSponsor.title == "" ||
      newSponsor.fieldofwork == " " ||
      !newSponsor.session_id ||
      newSponsor.facebook == "" ||
      !newSponsor.avatar ||
      newSponsor.instagram == "" ||
      newSponsor.linkedin == "" ||
      newSponsor.twitter == "" ||
      newSponsor.youtube == "" ||
      newSponsor.email == ""
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let form = { ...newSponsor };
      form.id = parseInt(updateFlag);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(EditSpeaker({ body, selectedEvent: params.id }));
      setEventModal(false);
    } else {
      let form = { ...newSponsor };
      form.event_id = parseInt(params.id);

      const body = new FormData();
      for (var key in form) {
        body.append(key, form[key]);
      }
      dispatch(CreateSpeakers({ body, selectedEvent: params.id }));
      setEventModal(false);
    }
    setNewSponsor(initalSponsor);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };

  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewSponsor(initalSponsor);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create New Speaker
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Speaker
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div
                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{ color: "black" }}
                  className="d-flex align-items-center fs-7 mb-2"
                >
                  Speaker avatar
                </label>

                {newSponsor.avatar ? (
                  <>
                    <img
                      style={{ maxWidth: "35%", height: "7rem" }}
                      src={
                        newSponsor.avatar
                          ? newSponsor.avatar?.name
                            ? URL.createObjectURL(newSponsor.avatar)
                            : `${route}/${newSponsor.avatar}`
                          : ""
                      }
                    ></img>
                    <div className="d-flex" style={{ margin: "1rem 0" }}>
                      <label className="circles">
                        <img src={edit} style={{ width: "1rem" }}></img>
                        <input
                          name="avatar"
                          value={""}
                          onChange={(e) =>
                            setNewSponsor({
                              ...newSponsor,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                          type="file"
                          style={{ display: "none", cursor: "pointer" }}
                          accept=".png, .jpg, .jpeg, .svg"
                        />
                      </label>
                      <div className="separtor"></div>
                      <div className="circles">
                        <img
                          src={del}
                          style={{ width: "0.8rem", cursor: "pointer" }}
                          onClick={() => {
                            setNewSponsor({ ...newSponsor, avatar: null });
                          }}
                        ></img>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="upload-btn">
                    <input
                      style={
                        error && !newSponsor.avatar
                          ? { border: "1px solid red" }
                          : {}
                      }
                      type="file"
                      style={{ display: "none" }}
                      name="avatar"
                      value={""}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(e) => {
                        console.log("file", e.target.files);
                        setNewSponsor((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.files[0],
                        }));
                      }}
                    />
                    Upload image
                  </label>
                )}
                <div style={{ marginTop: "0.5rem" }}>
                  <span>Image Dimension:365x464 px</span>
                </div>
                <div>
                  {!newSponsor.avatar && error && (
                    <span style={{ color: "red" }}>Avatar is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Speaker name
                </span>
                <input
                  style={
                    error && !newSponsor.name ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="name"
                  placeholder=""
                  value={newSponsor?.name}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Speaker title
                </span>
                <input
                  style={
                    error && !newSponsor.title
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="title"
                  placeholder=""
                  value={newSponsor?.title}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Email
                </span>
                <input
                  style={
                    error && !newSponsor.email
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="email"
                  placeholder=""
                  value={newSponsor?.email}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Speaker field of work
                </span>
                <input
                  style={
                    error && !newSponsor.fieldofwork
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="fieldofwork"
                  placeholder=""
                  value={newSponsor?.fieldofwork}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Speaker session
                </span>
                <select
                  style={
                    error && !newSponsor.session_id
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="session_id"
                  placeholder=""
                  value={newSponsor?.session_id}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option value={" "}></option>
                  {sessionConst.map((session, index) => (
                    <option value={session.id}>{session.title}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  twitter
                </span>
                <input
                  style={
                    error && !newSponsor.twitter
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="twitter"
                  placeholder=""
                  value={newSponsor?.twitter}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Linkedin
                </span>
                <input
                  style={
                    error && !newSponsor.linkedin
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="linkedin"
                  placeholder=""
                  value={newSponsor?.linkedin}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Facebook Link
                </span>
                <input
                  style={
                    error && !newSponsor.facebook
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="facebook"
                  placeholder=""
                  value={newSponsor?.facebook}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <span
                  className="d-flex align-items-center py-2 "
                  style={{ color: "black" }}
                >
                  Instagram Link
                </span>
                <input
                  style={
                    error && !newSponsor.instagram
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="instagram"
                  placeholder=""
                  value={newSponsor?.instagram}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
            <button
              type="button"
              id="kt_password_reset_submit"
              className="save-btn fw-bolder"
              onClick={addind}
            >
              <span>Save Changes</span>
            </button>

            {updateFlag != -1 && (
              <>
                <div className="separtor"></div>
                <button
                  className="delete-btn"
                  onClick={() => {
                    dispatch(
                      DeleteSpeaker({ updateFlag, selectedEvent: params.id })
                    );
                    setUpdateFlag(-1);
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Speakers"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Speakers"}
            setSearchText={setSearchText}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div
                  className="row main-row"
                  style={{
                    margin: "0",
                    gridTemplateColumns:
                      " repeat(auto-fill, minmax(200px, 1fr))",
                  }}
                >
                  <div
                    className="col-3 speaker-box-final"
                    onClick={() => {
                      setEventModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "2.25rem", margin: "1rem" }}
                      src={`${create}`}
                    ></img>
                    <div className="create-text">Add Speaker</div>
                  </div>
                  {speakers.map((event, index) => (
                    <>
                      <div
                        className="speaker-box"
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUpdateFlag(event.id);
                          setNewSponsor({
                            name: event.name,
                            title: event.title,
                            session_id: event.session_id,
                            avatar: event.avatar,
                            fieldofwork: event.fieldofwork,
                            linkedin: event.linkedin,
                            facebook: event.facebook,
                            instagram: event.instagram,
                            twitter: event.twitter,
                            email: event.email,
                          });
                          setEventModal(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              opacity: "0.6",
                              width: "15.83rem",
                              borderRadius: "16px",
                              height: "16.438rem",
                              objectFit: "cover",
                            }}
                            src={`${route}/${event.avatar}`}
                          ></img>
                          <div
                            style={{
                              position: "absolute",
                              display: "flex",
                              marginTop: "11rem",
                              flexDirection: "column",
                              left: "1px",
                              width: "100%",
                            }}
                          >
                            <span className="speaker-name">{event.name}</span>
                            <span className="speaker-title">
                              {event.fieldofwork}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Speakers;
