import React from "react";
import { useSelector } from "react-redux";

const ModeratorProtect = ({ children }) => {
    const user = useSelector((state) => state);
    console.log(user);
    return children;
};

export default ModeratorProtect;
