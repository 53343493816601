import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getAttendee } from "redux/Attendee/actions";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import searchLogo from "../../assets/images/search.svg";

const UsherSearch = () => {
    const [searchBy, setSearchBy] = useState("");
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const params = useParams();
    const inputs = [
        {
            type: "name",
            label: "search By name",
        },
        {
            type: "email",
            label: "search By Email",
        },
        {
            type: "phone",
            label: "search By Phone",
        },
        {
            type: "qrcode",
            label: "search By Qr code",
        },
    ];
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                margin: "auto",
                maxWidth: "1620px",
            }}
        >
            <div
                className="row"
                style={{
                    padding: "10px",
                    width: "100%",
                }}
            >
                {inputs.map((item, index) => (
                    <div
                        className="col-lg-3 col-md-6 col-12 "
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                        }}
                    >
                        <span
                            style={{
                                minWidth: "fit-content",
                                fontSize: "16px",
                                display: "block",
                            }}
                        >
                            {item.label}
                        </span>
                        <input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            placeholder={item.label}
                            value={searchBy === item.type ? searchText : ""}
                            disabled={
                                searchBy !== item.type &&
                                searchText.trim() !== ""
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setSearchBy(item.type);
                                setSearchText(e.target.value);
                            }}
                        />
                    </div>
                ))}
            </div>
            <div
                className="page-header-search"
                onClick={() => {
                    dispatch(
                        getAttendee({
                            event_id: params.id,
                            pagenumber: 0,
                            search: searchText,
                            searchby: searchBy,
                        })
                    );
                    dispatch(getTicketTypesConst({ event_id: params.id }));
                }}
                type="submit"
            >
                <img
                    style={{
                        width: "1.5rem",
                        cursor: "pointer",
                    }}
                    src={searchLogo}
                ></img>
            </div>
        </div>
    );
};

export default UsherSearch;
