import { createReducer } from "@reduxjs/toolkit";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSocial, getSocial } from "./actions";
const initialState = {
  socials: [],
  loader: false,
  total: 0,
};

export const socialReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSocial.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getSocial.fulfilled, (state, { payload }) => {
      console.log(payload);

      if (payload == "stoploading") {
        state.loader = false;
        state.socials = [];
        state.total = [];
      } else {
        state.socials = payload.data;
        state.total = payload.data.length;
        state.loader = false;
      }

      //state.errorpayload = false;
    })
    .addCase(createSocial.fulfilled, (state, { payload }) => {
      toast.success("Social Engagment Created successfully");

      //state.errorpayload = false;
    })
    .addCase(getSocial.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default socialReducer;
