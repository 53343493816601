import { createReducer } from "@reduxjs/toolkit";
import {
    getEventsDetails,
    getEventsAgenda,
    getAllEvent,
    createEvent,
    getCompanies,
    getAttendee,
    ExportAttendee,
    uploadAttendee,
    exportFile,
    getApproval,
    getRejectedList,
    ExportPendingList,
    ExportPerType,
    MarkAsPaid,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
    attendee: [],
    approveattendee: [],
    rejectattendee: [],
    loader: false,
    total: 0,
    importAttendee: false,
    exportAttendee: true,
    exportUnpaid: true,
    exportPendingList: true,
    attendees_len: 0,
    approv_len: 0,
    reject_len: 0,
    uploading: false,
    number_of_pages: 0,
};

export const attendeeReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(uploadAttendee.pending, (state) => {
            state.uploading = true;
        })
        .addCase(uploadAttendee.fulfilled, (state) => {
            toast.success("Attendee Uploaded Successfully");
            state.uploading = false;
        })
        .addCase(uploadAttendee.rejected, (state, payload) => {
            toast.error(payload?.error?.message);

            state.uploading = false;
        })

        .addCase(getAttendee.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getAttendee.fulfilled, (state, { payload }) => {
            state.attendee = payload.list;
            console.log(payload.list);
            state.loader = false;
            state.total = payload.total;
            state.number_of_pages = payload.number_of_pages;
            state.attendees_len = payload.attendees_len;
            //state.errorpayload = false;
        })
        .addCase(getApproval.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getApproval.fulfilled, (state, { payload }) => {
            state.approveattendee = payload.list;
            //alert(payload.list);
            state.loader = false;
            state.total = payload.total;
            state.number_of_pages = payload.number_of_pages;
            state.approv_len = payload.attendees_len;
            //state.errorpayload = false;
        })
        .addCase(getRejectedList.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(MarkAsPaid.fulfilled, (state, { payload }) => {
            toast.success("Attendee Marked as Paid Successfully");
        })
        .addCase(MarkAsPaid.rejected, (state, { payload }) => {
            toast.error("Failed to Mark Attendee as Paid");
        })
        .addCase(getRejectedList.fulfilled, (state, { payload }) => {
            state.rejectattendee = payload.list;
            //alert(payload.list);
            state.loader = false;
            state.total = payload.total;
            state.number_of_pages = payload.number_of_pages;
            state.reject_len = payload.attendees_len;
            //state.errorpayload = false;
        })
        .addCase(exportFile.fulfilled, (state, { payload }) => {
            state.importAttendee = true;
            //state.errorpayload = false;
        })
        .addCase(ExportAttendee.fulfilled, (state, { payload }) => {
            state.exportAttendee = true;
            //state.errorpayload = false;
        })
        .addCase(ExportAttendee.rejected, (state, { payload }) => {
            state.exportAttendee = true;
            //state.errorpayload = false;
        })
        .addCase(ExportPerType.fulfilled, (state, { payload }) => {
            // state.exportAttendee = true;
            state.exportUnpaid = true;
        })
        .addCase(ExportPerType.rejected, (state, { payload }) => {
            state.exportUnpaid = true;
        })
        .addCase(ExportPendingList.fulfilled, (state, { payload }) => {
            state.exportPendingList = true;
            //state.errorpayload = false;
        })
        .addCase(ExportPendingList.rejected, (state, { payload }) => {
            state.exportPendingList = true;
            //state.errorpayload = false;
        })
        .addCase(ExportAttendee.pending, (state, { payload }) => {
            state.exportAttendee = false;
            //state.errorpayload = false;
        })
        .addCase(getAttendee.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        });
});

export default attendeeReducer;
