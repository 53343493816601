import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
const route1 = process.env.REACT_APP_API2;

export const getAnnouncement
 = createAsyncThunk("get/announcement", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "website/event/announcmenets",
      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getSessionsConst = createAsyncThunk(
  "get/sessions-const",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "" + route + "sessions/listnopagination",
        params: params,
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateAnnouncement = createAsyncThunk(
  "create/announcement",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "website/event/announcmenets",
        data: body,
      });
      

      if (response.status == 200 || response.status == 204 || response.status==201) {
        toast.success("Announcement Created successfully");
        dispatch(getAnnouncement({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditAnnouncement = createAsyncThunk(
  "edit/announcement",
  async ({ body, updateFlag,selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "website/event/announcmenets/"+updateFlag+"",
        data: body,
      });

      if (response.status == 200 || response.status == 204 || response.status == 201) {
        toast.success("Announcement Edited successfully");
        dispatch(getAnnouncement({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteAnnouncement = createAsyncThunk(
  "delete/Announcement",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `website/event/announcmenets/${updateFlag}`,
      });
      
      if (response.status == 200 || response.status == 204 || response.status == 201) {
        toast.success("Announcement Deleted successfully");
        dispatch(getAnnouncement({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
