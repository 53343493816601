import { createReducer } from "@reduxjs/toolkit";
import {
  getAgeReport,
  getAttendanceLine,
  getEventOverView,
  getGenderReport,
  getOverViewReport,
  getQuestionReport,
  getSections,
  getselectedReport,
  getTypesReport,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  attendanceLine: [],
  questionsreport:[],
  attendancedays: [],
  gender: [],
  genderDetails: [],
  age: [],
  ageDetails: [],
  types: [],
  overView: [],
  eventOverView: [],
  loader: false,
};

export const reportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAttendanceLine.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getAttendanceLine.fulfilled, (state, { payload }) => {
      let att = [];
      let days = [];
      for (let i = 0; i < payload.detail.length; i++) {
        att.push(payload.detail[i].report);
        days.push(payload.detail[i].name);
      }
      state.attendanceLine = att;
      state.attendancedays = days;
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getAttendanceLine.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getGenderReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getGenderReport.fulfilled, (state, { payload }) => {
      state.gender = payload.total;

      state.genderDetails = payload.detail;
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getGenderReport.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getTypesReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getTypesReport.fulfilled, (state, { payload }) => {
      state.types = payload.total;
      state.loader = false;
      //state.errorpayload = false;
    })
    .addCase(getTypesReport.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getOverViewReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getOverViewReport.fulfilled, (state, { payload }) => {
      state.overView = payload;
      state.loader = false;
      //state.errorpayload = false;
    })
    .addCase(getOverViewReport.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getEventOverView.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getEventOverView.fulfilled, (state, { payload }) => {
      state.eventOverView = payload;
      state.loader = false;
      //state.errorpayload = false;
    })
    .addCase(getEventOverView.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getAgeReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getAgeReport.fulfilled, (state, { payload }) => {
      state.age = payload.total;

      state.ageDetails = payload.detail;
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getQuestionReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getQuestionReport.fulfilled, (state, { payload }) => {
      console.log(payload)
      state.questionsreport = payload;
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getselectedReport.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getselectedReport.fulfilled, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getAgeReport.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default reportReducer;
