import React from 'react'
import Events from '../../Components/Events'

function AgendaEvents() {
  return (
    <div>
        <Events route={"/agenda"}></Events>
    </div>
  )
}

export default AgendaEvents