import React, { useEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from "redux/Companies/actions";
import {
    CreateOrganizationalUsher,
    CreateUsher,
    EditUsher,
    getAllUshers,
} from "redux/UserRoles/actions";
import Pagintation from "../../Components/Pagintation";
import Select from "react-select";
import { getAllEvent } from "redux/Events/actions";
const initalUsher = {
    name: "",
    email: "",
    password: "",
    mobileno: "",
    type: 5,
    company_id: null,
};

function Ushers() {
    const dispatch = useDispatch();
    const { companies } = useSelector((state) => state.companies);
    const { events } = useSelector((state) => state.events);
    const { allUshers, loader, total, pagecount } = useSelector(
        (state) => state.userRoles
    );

    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newUsher, setNewUsher] = useState(initalUsher);
    const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
    useEffect(() => {
        dispatch(getCompanies({ name: "" }));

        dispatch(
            getAllUshers({
                pagenumber: pageNumber,
            })
        );
        dispatch(getAllEvent({}));
    }, [pageNumber]);

    const addind = () => {
        if (
            newUsher.name == "" ||
            newUsher.email == "" ||
            newUsher.mobileno == "" ||
            newUsher.type == null ||
            !newUsher.company_id
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newUsher };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                if (key === "managed_events") {
                    form.managed_events.forEach((element, index) => {
                        body.append(`managed_events[${index}]`, element.value);
                    });
                } else if (key === "password") {
                    console.log(newUsher.password);
                    if (
                        newUsher.password &&
                        newUsher.password != undefined &&
                        newUsher.password != "undefined"
                    ) {
                        console.log("password", newUsher.password);
                        body.append(key, newUsher.password);
                    } else {
                    }
                } else {
                    body.append(key, form[key]);
                }
            }
            dispatch(EditUsher({ body, isAll: true, pageNumber: pageNumber }));
            setEventModal(false);
        } else {
            let form = { ...newUsher };
            const body = new FormData();
            for (var key in form) {
                if (key === "managed_events") {
                    newUsher.managed_events.forEach((element, index) => {
                        body.append(`managed_events[${index}]`, element.value);
                    });
                } else {
                    body.append(key, form[key]);
                }
            }
            dispatch(CreateOrganizationalUsher({ body }));
            setEventModal(false);
        }
        setNewUsher(initalUsher);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };

    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewUsher(initalUsher);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create Usher
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Usher
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Usher name
                                    </span>
                                    <input
                                        style={
                                            error && !newUsher.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newUsher?.name}
                                        onChange={(e) =>
                                            setNewUsher({
                                                ...newUsher,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Usher password
                                    </span>
                                    <input
                                        style={
                                            error && !newUsher.password
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="password"
                                        placeholder=""
                                        value={newUsher?.password}
                                        onChange={(e) =>
                                            setNewUsher({
                                                ...newUsher,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Usher email
                                    </span>
                                    <input
                                        style={
                                            error && !newUsher.email
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="email"
                                        placeholder=""
                                        value={newUsher?.email}
                                        onChange={(e) =>
                                            setNewUsher({
                                                ...newUsher,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Usher Mobile Number
                                    </span>
                                    <input
                                        style={
                                            error && !newUsher.mobileno
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="mobileno"
                                        placeholder=""
                                        value={newUsher?.mobileno}
                                        onChange={(e) =>
                                            setNewUsher({
                                                ...newUsher,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Company
                                    </span>
                                    <select
                                        style={
                                            error && !newUsher.company_id
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="company_id"
                                        placeholder=""
                                        value={newUsher?.company_id}
                                        onChange={(e) =>
                                            setNewUsher({
                                                ...newUsher,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={""}></option>
                                        {companies.map((company, index) => (
                                            <option value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Events
                                    </span>

                                    <Select
                                        onChange={(values) =>
                                            setNewUsher({
                                                ...newUsher,
                                                managed_events: values,
                                            })
                                        }
                                        value={newUsher?.managed_events}
                                        name="managed_events"
                                        isMulti={true}
                                        options={events?.map((event) => ({
                                            label: event.name,
                                            value: event.id,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"UserRoles"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Ushers"}
                        setSearchText={setSearchText}
                        EventName={""}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        pageCount={pagecount}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table className="table table-striped gy-2 gs-7">
                                        <thead>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    name
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    email
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    company id
                                                </th>
                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {allUshers?.map((code, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.id}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.name}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.email}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.company_id}
                                                        </th>

                                                        <th>
                                                            <span
                                                                onClick={() => {
                                                                    setUpdateFlag(
                                                                        code.id
                                                                    );

                                                                    setNewUsher(
                                                                        {
                                                                            name: code.name,
                                                                            email: code.email,
                                                                            mobileno:
                                                                                code.mobileno,
                                                                            password:
                                                                                code.password,
                                                                            company_id:
                                                                                code.company_id,
                                                                            type: code.type,
                                                                            managed_events:
                                                                                code.managed_events?.map(
                                                                                    (
                                                                                        ele
                                                                                    ) => {
                                                                                        return {
                                                                                            label: ele.name,
                                                                                            value: ele.id,
                                                                                        };
                                                                                    }
                                                                                ),
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            {/* <span
                                                            // onClick={() => {
                                                            //   dispatch(
                                                            //     DeleteCode({
                                                            //       updateFlag: code.index,
                                                            //       selectedEvent,
                                                            //     })
                                                            //   );
                                                            // }}
                                                            >
                                                                <img
                                                                    src={del}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            </span> */}
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Usher</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ushers;
