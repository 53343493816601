import React, { useEffect, useState } from "react";
import {
    createEvent,
    editEvent,
    getAllEvent,
    getEventsDetails,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/ticket.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Loader from "../../Components/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import send from "../../assets/images/send.svg";

import Pagintation from "../../Components/Pagintation";
import dayjs from "dayjs";
import {
    CreateSponsors,
    DeleteSponsors,
    EditSponsors,
    getSponsors,
} from "redux/Sponsors/actions";
import {
    CreateTicketType,
    DeleteTicketType,
    EditTicketType,
    getTicketTypes,
} from "redux/TicketTypes/actions";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const initalTicket = {
    name: "",
    name_ar: "",
    description: "",
    description_ar: "",
    type: 2,
    quantity: null,
    isprivate: 0,
    min: 1,
    max: 1,
    event_id: null,
    infocollect_type: 1,
    startduration: null,
    endduration: null,
    price: 0,
    needapproval: 0,
    pre_register: 0,
    customconfirmemail: "",
    customconfirmsubject: "",
    customemailsender: "",
    customreminderemail: "",
    customremindersubject: "",
    pending_mail: "",
    pending_subject: "",
    approval_mail: "",
    approval_subject: "",
    rejection_mail: "",
    rejection_subject: "",
};

function EmailTemplates() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { ticketTypes, loader, total } = useSelector(
        (state) => state.ticketTypes
    );
    const { event } = useSelector((state) => state.events);
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const nav = useNavigate();

    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const params = useParams();

    const [newTicket, setNewTicket] = useState(initalTicket);
    useEffect(() => {
        if (params.id) {
            dispatch(
                getTicketTypes({ event_id: params.id, pagenumber: pageNumber })
            );
            dispatch(getEventsDetails(params.id));
        }
    }, [pageNumber, params]);
    console.log(ticketTypes, "newTicket");
    const todayAtNoon = dayjs().set("hour", 12).startOf("hour");
    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        // if(newSponsor.name==""||
        // newSponsor.about==""||
        //  newSponsor.address==" "||
        //  !newSponsor.category||newSponsor.facebook==''
        //  ||!newSponsor.image||
        //  !newSponsor.logo||newSponsor.instagram==''
        //  ||newSponsor.video==''||
        //  newSponsor.website==''||newSponsor.youtube=='')
        // {
        //     setError(true)
        //     return ;
        // }
        for (const key in newTicket) {
            if (
                key == "description_ar" &&
                newTicket[key] == "" &&
                event.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                key == "name_ar" &&
                newTicket[key] == "" &&
                event.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                (typeof newTicket[key] == "string" &&
                    newTicket[key] == "" &&
                    key != "ar_location" &&
                    key !== "name_ar" &&
                    key != "description_ar" &&
                    key != "customconfirmemail" &&
                    key != "customconfirmsubject" &&
                    key != "customemailsender" &&
                    key != "customreminderemail" &&
                    key != "customremindersubject" &&
                    key != "pending_mail" &&
                    key != "pending_subject" &&
                    key != "approval_mail" &&
                    key != "approval_subject" &&
                    key != "rejection_mail" &&
                    key != "rejection_subject") ||
                (typeof newTicket[key] == "number" && isNaN(newTicket[key]))
            ) {
                setError(true);
                return;
            }
        }

        if (updateFlag != -1) {
            let form = { ...newTicket };
            form.id = parseInt(updateFlag);
            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditTicketType({ body, selectedEvent }));
        } else {
            let date = new Date(newTicket.startduration);
            let isCurrentYear = (date) =>
                date.getUTCFullYear() < new Date().getUTCFullYear();
            let date2 = new Date(newTicket.endduration);

            if (isCurrentYear(date) || isCurrentYear(date2)) {
                toast.error("Please pick a date from this year");
                return;
            }

            let form = { ...newTicket };
            form.event_id = parseInt(selectedEvent);
            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateTicketType({ body, selectedEvent }));
        }
        setNewTicket(initalTicket);
        setError(false);
        setUpdateFlag(-1);
        setEventModal(false);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const handleToggle1 = (event) => {
        if (newTicket.type == 1) {
            setNewTicket({ ...newTicket, type: 2, price: 0 });
        } else {
            setNewTicket({ ...newTicket, type: 1 });
        }
    };
    const handleToggle2 = (event) => {
        if (newTicket.isprivate == 1) {
            setNewTicket({ ...newTicket, isprivate: 0 });
        } else {
            setNewTicket({ ...newTicket, isprivate: 1 });
        }
    };
    return (
        <>
            <div className="aside-div">
                <AsideMenu selected={"TicketTypes"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Ticket Types"}
                        showUrl={true}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    {ticketTypes.map((event, index) => (
                                        <>
                                            <Link
                                                to={`/${id}/ticket_types/emails/${event.id}`}
                                                className="event-box-hidden"
                                                style={{ display: "flex" }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div className="ticket-status">
                                                            {event.type == 1
                                                                ? `${event.price} EGP`
                                                                : "Free Ticket"}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="ticket-title">
                                                            {event.name}
                                                        </div>
                                                    </div>
                                                    <div className="ticket-about">
                                                        {event.description}
                                                    </div>
                                                    <div className="ticket-date">
                                                        {new Date(
                                                            event?.startduration
                                                        ).toLocaleString(
                                                            "en-GB",
                                                            {
                                                                day: "numeric",
                                                                month: "long",
                                                                year: "numeric",
                                                            }
                                                        )}
                                                        -
                                                        {new Date(
                                                            event?.endduration
                                                        ).toLocaleString(
                                                            "en-GB",
                                                            {
                                                                day: "numeric",
                                                                month: "long",
                                                                year: "numeric",
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailTemplates;
