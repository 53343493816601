import React, { useEffect, useState } from "react";

import AsideMenu from "../../../Components/Asidemenu2";
import PageHeader from "../../../Components/PageHeader";

import Loader from "../../../Components/Loader";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

function Type() {
  const nav = useNavigate();
const params=useParams();
const { selectedCompany, selectedEvent } = useSelector(
  (state) => state.events
);
  return (
    <>
      <div className="aside-div">
        <AsideMenu selected={"Bs"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
        <PageHeader
            title={"Sales"}
            setSearchText={""}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            <>
              <div className="row main-row" style={{ margin: "0" }}>
                <div
                  className="col-3 event-box-users"
                  onClick={() => {
                    nav(`booths`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Booths
                  </div>
                </div>

                <div
                  className="col-3 event-box-users"
                  onClick={() => {
                    nav(`sponsors`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Sponsors
                  </div>
                </div>

               
{/* 
                <div
                  className="col-3 event-box-users"
                  onClick={() => {
                    nav("/user-roles/ushers");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Ushers
                  </div>
                </div>
                <div
                  className="col-3 event-box-users"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Exhibitor/Sponsor Admin
                  </div>
                  <div style={{ fontSize: "1rem", color: "white" }}>
                    (Coming Soon)
                  </div>
                </div>

                <div
                  className="col-3 event-box-users"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                >
                  <div className="create-text" style={{ color: "white" }}>
                    Exhibitor/Sponsor Sales
                  </div>
                  <div style={{ fontSize: "1rem", color: "white" }}>
                    (Coming Soon)
                  </div>
                </div> */}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Type;
