import { createReducer } from "@reduxjs/toolkit";
import {
  getEventsDetails,
  getEventsAgenda,
  getAllEvent,
  createEvent,
  getCompanies,
  getCompaniesSmtp,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  companies: [],
  smtp: {},
  loader: false,
  total: 0,
  pagecount: 1,
};

export const companyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCompanies.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload.list;
      state.loader = false;
      state.total = payload.total;
      state.pagecount = payload.number_of_pages;
      //state.errorpayload = false;
    })
    .addCase(getCompanies.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getCompaniesSmtp.pending, (state) => {
      // state.errorpayload = true;
      state.loader = true;
    })
    .addCase(getCompaniesSmtp.fulfilled, (state, { payload }) => {
      state.smtp = payload;
      state.loader = false;

      //state.errorpayload = false;
    })
    .addCase(getCompaniesSmtp.rejected, (state, { payload }) => {
      state.loader = false;

      //state.errorpayload = false;
    });
});

export default companyReducer;
