import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;

export const getCodes = createAsyncThunk("get/codes", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "event/codes/list",

      params: params,
    });

    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateCode = createAsyncThunk(
  "create/code",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/codes/create",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Code Created successfully");
        dispatch(getCodes({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditCode = createAsyncThunk(
  "edit/code",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "event/codes/update",
        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Code Edited successfully");
        dispatch(getCodes({ event_id: selectedEvent, pagenumber: 0 }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteCode = createAsyncThunk(
  "delete/code",
  async ({ updateFlag, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `event/codes/delete/${updateFlag}`,
      });
      if (response.status == 200 || response.status == 204) {
        if(response.data.title)
        {
        toast.error(`${response.data.message}`);

        }
        else{
        toast.success("Code Deleted successfully");

        }
        dispatch(getCodes({ event_id: selectedEvent, pagenumber: 0 }));
      }

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
